import isValidCep from "@brazilian-utils/is-valid-cep";
import $ from "jquery";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";

import * as MotoristaActions from '../../../actions/MotoristaActions';
import * as BoxHelper from '../../../helpers/BoxHelper';
import * as formHelp from '../../../helpers/FormHelpMsg';
import * as validations from '../validations';

class Passo4 extends Component {
  constructor(props) {
    super();

    this.cepNaoEncontrado = false;
    this.ultimoCEP = null;

    this.state = {
      meu_endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
      },
    };

    this.campos = ["cep", "logradouro", "numero", "complemento"];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();

    let cadastro_meu_endereco = localStorage.getItem("cadastro_meu_endereco");

    if (
      typeof cadastro_meu_endereco !== "undefined" &&
      cadastro_meu_endereco !== null
    ) {
      this.setState({ meu_endereco: JSON.parse(cadastro_meu_endereco) });
    }
  }

  _gravar = () => {
    let formOk = true;
    this.campos.forEach((item) => {
      $("#" + item)
        .parent()
        .trigger("click");
      $("#" + item).trigger("click");
    });

    if (!validations.validarCampoCEP($("#cep").val(), this.cepNaoEncontrado)) {
      formOk = false;
    }

    if (!validations.validarCampoLogradouro($("#logradouro").val())) {
      formOk = false;
    }

    if (!validations.validarCampoNumero($("#numero").val())) {
      formOk = false;
    }

    if (formOk) {
      localStorage.setItem(
        "cadastro_meu_endereco",
        JSON.stringify(this.state.meu_endereco)
      );

      this.props.history.push("/cadastro/passo-5");
    }
  };

  pesquisarCEP = (cep) => {
    if (isValidCep(cep)) {
      if (this.ultimoCEP !== cep) {
        this.ultimoCEP = cep;

        MotoristaActions.pesquisarCep(cep)
          .then((res) => {
            let dados = this.state;

            dados.meu_endereco.logradouro = res.logradouro ? res.logradouro : "";
            dados.meu_endereco.bairro = res.bairro ? res.bairro : "";
            dados.meu_endereco.cidade = res.localidade ? res.localidade : "";
            dados.meu_endereco.uf = res.uf ? res.uf : "";

            this.setState(dados);

            $("#complemento").trigger("click");
            $("#numero").trigger("click");
            $("#numero").focus();

            this.cepNaoEncontrado = false;
          })
          .catch((erro) => {
            formHelp.showHelp(
              "cep",
              "CEP nao encontrado",
              "box-input-required"
            );

            // this.cepNaoEncontrado = true;
            let dados = this.state;
            dados.meu_endereco.logradouro = "";
            dados.meu_endereco.bairro = "";
            dados.meu_endereco.cidade = "";
            dados.meu_endereco.uf = "";

            this.setState(dados);
          });
      }
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;

    formHelp.hideHelp(campo, "box-input-required");

    if (campo === "cep") {
      validations.validarCampoCEP(valor, this.cepNaoEncontrado);
    } else if (campo === "logradouro") {
      validations.validarCampoLogradouro(valor);
    } else if (campo === "numero") {
      validations.validarCampoNumero(valor);
    }
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;

    if (campo === "cep") {
      this.pesquisarCEP(valor);
    }

    dados.meu_endereco[campo] = valor;

    await this.setState(dados);
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Meu Endereço</div>
        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30">
          Complete seus dados para continuar
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "18%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="cepInputContainer"
        >
          <MaskedInput
            mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            className="box-input-only input2"
            guide={false}
            id="cep"
            value={this.state.meu_endereco.cep}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <label
            className={
              this.state.meu_endereco.cep === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="cep"
          >
            CEP
          </label>
          <span className="help-inline"></span>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="logradouroInputContainer"
        >
          <input
            type="text"
            id="logradouro"
            value={this.state.meu_endereco.logradouro}
            onChange={this._updateField}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
            className="box-input-only input2"
          />
          <label
            className={
              this.state.meu_endereco.logradouro === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="logradouro"
          >
            Logradouro
          </label>
          <span className="help-inline"></span>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="numeroInputContainer"
        >
          <MaskedInput
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            className="box-input-only input2"
            guide={false}
            id="numero"
            value={this.state.meu_endereco.numero}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <label
            className={
              this.state.meu_endereco.numero === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="numero"
          >
            Número
          </label>
          <span className="help-inline"></span>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="complementoInputContainer"
        >
          <input
            type="text"
            id="complemento"
            value={this.state.meu_endereco.complemento}
            onChange={(e) => {
              this._updateField(e);
            }}
            className="box-input-only input2"
          />
          <label
            className={
              this.state.meu_endereco.complemento === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="complemento"
          >
            Complemento
          </label>
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(Passo4);
