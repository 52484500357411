import $ from 'jquery';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as VeiculoActions from '../../../actions/VeiculoActions';
import * as ParametroSistemaActions from '../../../actions/ParametroSistemaActions';
import * as BoxHelper from '../../../helpers/BoxHelper';
import * as formHelp from '../../../helpers/FormHelpMsg';
import * as validations from '../validations';

class Passo6 extends Component {
  constructor(props) {
    super();

    this.state = {
      meu_veiculo: {
        veiculo_fabricante: '',
        veiculo_modelo: '',
        veiculo_ano: '',
        veiculo_placa: '',
      },
      fabricantes: [],
      modelos: [],
      anos: [],
    };

    this.campos = ['veiculo_fabricante', 'veiculo_modelo', 'veiculo_ano', 'veiculo_placa'];
  }

  componentWillMount() {
    BoxHelper.boxFormSetup();
    let lista_veiculo_fabricante = localStorage.getItem(
      'lista_veiculo_fabricante'
    );

    if (
      typeof lista_veiculo_fabricante !== 'undefined' &&
      lista_veiculo_fabricante !== null
    ) {
      let dados = this.state;

      dados.fabricantes = JSON.parse(lista_veiculo_fabricante);

      let cadastro_meu_veiculo = localStorage.getItem('cadastro_meu_veiculo');

      if (
        typeof cadastro_meu_veiculo !== 'undefined' &&
        cadastro_meu_veiculo !== null
      ) {
        dados.meu_veiculo = JSON.parse(cadastro_meu_veiculo);
      }

      let VEICULO_IDADE_MAXIMA = localStorage.getItem('VEICULO_IDADE_MAXIMA');

      if (
        typeof VEICULO_IDADE_MAXIMA !== 'undefined' &&
        VEICULO_IDADE_MAXIMA !== null
      ) {
        let idadeMaxima = parseInt(VEICULO_IDADE_MAXIMA);
        let proximoAno = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

        dados.anos = Array.from(
          Array(idadeMaxima),
          (x, index) => proximoAno.getFullYear() - index
        );

        this.setState(dados);

        $('.loader-main').addClass('loader-inactive');
      } else {
        ParametroSistemaActions.obter('VEICULO_IDADE_MAXIMA')
          .then((param) => {
            let idadeMaxima = 15;
            if (param && param.valor) {
              idadeMaxima = parseInt(param.valor);
            }

            localStorage.setItem('VEICULO_IDADE_MAXIMA', idadeMaxima);

            let proximoAno = new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            );

            dados.anos = Array.from(
              Array(idadeMaxima),
              (x, index) => proximoAno.getFullYear() - index
            );

            this.setState(dados);

            $('.loader-main').addClass('loader-inactive');
          })
          .catch((error) => {
            console.log(error.message);
            $('.loader-main').addClass('loader-inactive');
          });
      }
    } else {
      $('.loader-main').removeClass('loader-inactive');

      VeiculoActions.listarFabricantes()
        .then((res) => {
          localStorage.setItem('lista_veiculo_fabricante', JSON.stringify(res));
          let dados = this.state;
          dados.fabricantes = res;

          let cadastro_meu_veiculo = localStorage.getItem(
            'cadastro_meu_veiculo'
          );

          if (
            typeof cadastro_meu_veiculo !== 'undefined' &&
            cadastro_meu_veiculo !== null
          ) {
            dados.meu_veiculo = JSON.parse(cadastro_meu_veiculo);
          }

          ParametroSistemaActions.obter('VEICULO_IDADE_MAXIMA')
            .then((param) => {
              let idadeMaxima = 15;

              if (param && param.valor) {
                idadeMaxima = parseInt(param.valor);
              }

              localStorage.setItem('VEICULO_IDADE_MAXIMA', idadeMaxima);

              let proximoAno = new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
              );

              dados.anos = Array.from(
                Array(idadeMaxima),
                (x, index) => proximoAno.getFullYear() - index
              );

              this.setState(dados);

              $('.loader-main').addClass('loader-inactive');
            })
            .catch((error) => {
              console.log(error.message);
              $('.loader-main').addClass('loader-inactive');
            });
        })
        .catch((error) => {
          console.log(error.message);
          $('.loader-main').addClass('loader-inactive');
        });
    }
  }

  _gravar = () => {
    let formOk = true;

    this.campos.forEach((item) => {
      $('#' + item)
        .parent()
        .trigger('click');
      $('#' + item).trigger('click');
    });

    if (!validations.validarCampoVeiculoFabricante(this.state.meu_veiculo.veiculo_fabricante)) {
      formOk = false;
    }

    if (!validations.validarCampoVeiculoModelo(this.state.meu_veiculo.veiculo_modelo)) {
      formOk = false;
    }

    if (!validations.validarCampoVeiculoAno(this.state.meu_veiculo.veiculo_ano)) {
      formOk = false;
    }

    if (!validations.validarCampoPreenchido(this.state.meu_veiculo.veiculo_placa, 'veiculo_placa')) {
      formOk = false;
    }

    if (formOk) {
      localStorage.setItem(
        'cadastro_meu_veiculo',
        JSON.stringify(this.state.meu_veiculo)
      );

      this.props.history.push('/cadastro/passo-7');
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;

    formHelp.hideHelp(campo, 'box-input-required');

    if (campo === 'veiculo_fabricante') {
      validations.validarCampoVeiculoFabricante(valor);
    } else if (campo === 'veiculo_modelo') {
      validations.validarCampoVeiculoModelo(valor);
    } else if (campo === 'veiculo_ano') {
      validations.validarCampoVeiculoAno(valor);
    } else if (campo === 'veiculo_placa') {
      validations.validarCampoPreenchido(valor, 'veiculo_placa');
    }
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;

    dados.meu_veiculo[campo] = valor ? valor.toUpperCase() : '';

    await this.setState(dados);
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Veículo</div>
        <div className="cf-texto div-body margin-30 space-26">
          Informações sobre o veículo
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "54%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div className="texto-20 margin-25 space-16">
          Selecione as informações:
        </div>
        <div className="cf-texto div-body margin-30 space-26">
          As informações referentes ao seu veículo serão verificadas no ato da
          instalação conforme o cadastro.
        </div>
        <div
          className="box-input-only2 margin-25 space-16"
          id="veiculo_fabricanteInputContainer"
        >
          <select
            id="veiculo_fabricante"
            value={ this.state.meu_veiculo.veiculo_fabricante }
            onChange={(e) => this._updateField(e)}
            onBlur={(e) => this._validarCampo(e)}
            className="box-input-only input2"
          >
            <option value=""></option>
            {this.state.fabricantes.map((item, k) => {
              return (
                <option key={k} value={item.cd_fabricante}>
                  {item.descricao}
                </option>
              );
            })}
          </select>
          <label
            className={
              this.state.meu_veiculo.veiculo_fabricante === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="veiculo_fabricante"
          >
            Marca
          </label>
          <span className="help-inline"></span>
        </div>

        <div className="box-input-only2 margin-25 space-16" id="veiculo_modeloInputContainer">
          <input
            type="text"
            id="veiculo_modelo"
            value={ this.state.meu_veiculo.veiculo_modelo }
            onChange={(e) => this._updateField(e)}
            onBlur={(e) => this._validarCampo(e)}
            className="box-input-only input2"
          />
          <label
            className={
              this.state.meu_veiculo.veiculo_modelo === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="veiculo_modelo"
          >
            Modelo
          </label>
          <span className="help-inline"></span>
        </div>

        <div className="box-input-only2 margin-25 space-16" id="veiculo_anoInputContainer">
          <select
            id="veiculo_ano"
            value={this.state.meu_veiculo.veiculo_ano}
            onChange={(e) => this._updateField(e)}
            onBlur={(e) => this._validarCampo(e)}
            className="box-input-only input2"
          >
            <option value=""></option>
            {this.state.anos.map((item, k) => {
              return (
                <option key={k} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          <label
            className={
              this.state.meu_veiculo.veiculo_ano === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="veiculo_ano"
          >
            Ano
          </label>
          <span className="help-inline"></span>
        </div>

        <div className="box-input-only2 margin-25 space-16" id="veiculo_placaInputContainer">
          <input
            type="text"
            id="veiculo_placa"
            value={ this.state.meu_veiculo.veiculo_placa }
            onChange={(e) => this._updateField(e)}
            onBlur={(e) => this._validarCampo(e)}
            className="box-input-only input2"
            maxLength={ 7 }
          />
          <label
            className={
              this.state.meu_veiculo.veiculo_placa === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="veiculo_placa"
          >
            Placa
          </label>
          <span className="help-inline"></span>
        </div>

        <div className="div-center space-34">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(Passo6);
