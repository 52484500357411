import React, { Component } from "react";
import TopoSemMenu from "../../components/TopoSemMenu";
import FormLogin from "./components/FormLogin";

export default class Login extends Component {
  render() {
    return (
      <div>
        <TopoSemMenu />
        <FormLogin />
      </div>
    );
  }
}
