import $ from "jquery";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";

import * as AplicativoActions from '../../../actions/AplicativoActions';
import * as BoxHelper from '../../../helpers/BoxHelper';
import * as formHelp from '../../../helpers/FormHelpMsg';
import * as validations from '../validations';

class Passo9 extends Component {
  constructor(props) {
    super();
    this.state = {
      meus_aplicativos: [],
      minhas_viagens: [],
      aplicativos: [],
    };
    this.campos = [];
  }

  componentDidMount() {
    let dados = this.state;
    let zanzar_aplicativos = localStorage.getItem("zanzar_aplicativos");
    if (
      typeof zanzar_aplicativos !== "undefined" &&
      zanzar_aplicativos !== null
    ) {
      dados.aplicativos = JSON.parse(zanzar_aplicativos);
    } else {
      AplicativoActions.listarAplicativos().then((res) => {
        dados.aplicativos = res;
        localStorage.setItem("zanzar_aplicativos", JSON.stringify(res));
        this.setState(dados, this._setState);
      });
    }
    let cadastro_minhas_viagens = localStorage.getItem(
      "cadastro_minhas_viagens"
    );
    if (
      typeof cadastro_minhas_viagens !== "undefined" &&
      cadastro_minhas_viagens !== null
    ) {
      dados.minhas_viagens = JSON.parse(cadastro_minhas_viagens);
      dados.minhas_viagens.forEach((item) => {
        dados["viagens_" + item.cd_aplicativo] = item.numero_viagens;
      });
    }
    let cadastro_meus_aplicativos = localStorage.getItem(
      "cadastro_meus_aplicativos"
    );
    if (
      typeof cadastro_meus_aplicativos !== "undefined" &&
      cadastro_meus_aplicativos !== null
    ) {
      dados.meus_aplicativos = JSON.parse(cadastro_meus_aplicativos);
    }
    this.setState(dados, this._setState);
  }

  _setState = () => {
    BoxHelper.boxFormSetup();
    let appsComNumeroViagens = this.state.aplicativos.filter((item) => {
      return (
        this.state.meus_aplicativos.includes(item.cd_aplicativo) &&
        item.idc_possui_numero_viagens === 1
      );
    });
    if (appsComNumeroViagens === null || appsComNumeroViagens.length === 0) {
      this.props.history.push("/cadastro/passo-10");
    }
  };

  _gravar = () => {
    let formOk = true;
    BoxHelper.boxFormSetup();
    this.campos.forEach((item) => {
      $("#" + item)
        .parent()
        .trigger("click");
      $("#" + item).trigger("click");
      if ($("#" + item).val() === "") {
        formOk = false;
      }
      validations.validarCampoPreenchido($("#" + item).val(), item);
    });
    if (formOk) {
      localStorage.setItem(
        "cadastro_minhas_viagens",
        JSON.stringify(this.state.minhas_viagens)
      );
      this.props.history.push("/cadastro/passo-10");
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    formHelp.hideHelp(campo, "box-input-required");
    validations.validarCampoPreenchido(valor, campo);
  };

  _updateField = (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    let cd_aplicativo = campo.slice(8);
    dados.minhas_viagens = dados.minhas_viagens.filter((item) => {
      return item.cd_aplicativo !== cd_aplicativo;
    });
    if (valor !== "") {
      dados.minhas_viagens.push({
        cd_aplicativo: cd_aplicativo,
        numero_viagens: valor,
      });
    }
    dados[campo] = valor;
    this.setState(dados);
  };

  _maskDelete = (e) => {
    const cursorPos = Number(e.target.selectionStart);
    const keyCode = Number(e.keyCode);
    if (keyCode === 8) {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = e.target.value.substring(0, cursorPos - 1);
      e.target.setSelectionRange(cursorPos, cursorPos);
      this._updateField(e);
    }
  };

  _getLabelClass = (campo) => {
    return this.state[campo] === undefined || this.state[campo] === ""
      ? "input-label2"
      : "input-label-sm2";
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Viagens</div>
        <div className="cf-texto div-body margin-30 space-26">
          Quantas viagens você já fez?
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "45%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div className="texto-20 margin-25 space-16">
          Selecione quantas viagens você já realizou:
        </div>
        {this.state.aplicativos.map((item, k) => {
          if (
            this.state.meus_aplicativos.includes(item.cd_aplicativo) &&
            item.idc_possui_numero_viagens === 1
          ) {
            let id_campo = "viagens_" + item.cd_aplicativo;
            let id_container = id_campo + "InputContainer";
            this.campos.push(id_campo);
            return (
              <div
                className="box-input-only2 margin-25 space-12"
                id={id_container}
                key={k}
              >
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  className="box-input-only input2"
                  guide={false}
                  id={id_campo}
                  value={this.state[id_campo]}
                  onChange={(e) => {
                    this._updateField(e);
                  }}
                  onBlur={(e) => {
                    this._validarCampo(e);
                  }}
                  onKeyDown={(e) => {
                    this._maskDelete(e);
                  }}
                />
                <label
                  className={this._getLabelClass(id_campo)}
                  htmlFor={id_campo}
                >
                  Número de viagens {item.descricao}
                </label>
                <span
                  className="help-inline"
                  style={
                    this.state.error
                      ? { color: "red", padding: "10px" }
                      : { display: "none" }
                  }
                >
                  {this.state.error}
                </span>
              </div>
            );
          } else {
            return "";
          }
        })}
        <div className="div-center div-bottom space-34">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(Passo9);
