import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;

export const listarFabricantes = async () => {
  let response = await axios.get(urlAPI + '/veiculo-fabricante/listar-ativos-simples', headers());

  return response.data;
}

export const listarModelosPorFabricante = async (cd_fabricante) => {
  let response = await axios.get(urlAPI + '/veiculo-modelo/listar-por-fabricante/' + cd_fabricante, headers());

  return response.data;
}
