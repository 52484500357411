import AuthService from "./AuthService";
import config from "../config/config";

// const auth = new AuthService(process.env.REACT_APP_AUTH_CLIENT_ID, process.env.REACT_APP_AUTH_DOMAIN_ADDRESS)
const auth = new AuthService(
  config.AUTH0_API_CLIENT_ID,
  config.AUTH0_API_DOMAIN,
);

export default auth;
