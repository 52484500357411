import $ from 'jquery';

export const showHelp = (elementId, value, classError) => {
	classError = classError == null ? 'has-error' : classError;

	$('#' + elementId).show();

	if ($('#' + elementId + 'InputContainer').hasClass('box-input'))
		$('#' + elementId + 'InputContainer').addClass(classError);
	else
		$('#' + elementId + 'InputContainer .input2').addClass(classError);

	$('#' + elementId + 'InputContainer span.help-inline').html(value);
	$('#' + elementId + 'InputContainer span.help-inline').show();
	$('#' + elementId + 'InputContainer').css("margin-bottom", $('#' + elementId + 'InputContainer span.help-inline').height() + 5);
};

export const hideHelp = (elementId, classError) => {
	classError = classError == null ? 'has-error' : classError;

	if ($('#' + elementId + 'InputContainer').hasClass('box-input'))
		$('#' + elementId + 'InputContainer').removeClass(classError);
	else
		$('#' + elementId + 'InputContainer .input2').removeClass(classError);

	$('#' + elementId + 'InputContainer span.help-inline').html('');
	$('#' + elementId + 'InputContainer span.help-inline').hide();
	$('#' + elementId + 'InputContainer').css("margin-bottom", 0);
};
