import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class StatusAprovado extends Component {
  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-8"></div>
        <img
          src={require("../../../resources/imgs/svg/aprovado-icon.svg")}
          className="center"
          style={{ width: "156px" }}
          alt=""
        />
        <div className="space-50"></div>
        <div className="title title-32 text-center text-destaque">
          A sua solicitação foi aprovada!
        </div>
        <div className="space-26"></div>
        <div className="div-separator-dt">
          <div
            className="div-progress div-progress-dt"
            style={{ width: "100%" }}
          ></div>
          <div className="div-progress-bullet div-progress-bullet-100"></div>
        </div>
        <div className="cf-texto div-body margin-30 text-center">
          Prossiga e realize o agendamento para a instalação.
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <Link to={"/termos-servico"}>
            <button className="cf-bt-cadastre margin-30">
              VER TERMOS E AGENDAR{" "}
              <i className="fas fa-chevron-right icon-14 float-right"></i>
            </button>
          </Link>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
