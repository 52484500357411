import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

class Menu extends Component {
  render() {
    return (
      <div id="menu-sidebar" className="menu menu-load menu-box-left" data-menu-width="312" data-menu-effect="menu-parallax">
        <img src={require('../resources/imgs/svg/ic-close.svg')} className="ico-menu-close close-menu" alt="" />
        <img src={require('../resources/imgs/logo-zanzar.png')} className="logo-menu" alt="" />

        <div className="menu-title">Menu</div>

        <img src={require('../resources/imgs/svg/noun-wave.svg')} className="noun-wave" alt="" />

        <div className="sidebar-menu">
          <Link to={'/como-funciona'}>
            <i className="fa fa-circle"></i><span>Como funciona</span>
          </Link>
          <div className="menu-line"></div>

          <Link to={'/como-funciona/vantagens'}>
            <i className="fa fa-circle"></i><span>Vantagens</span>
          </Link>
          <div className="menu-line"></div>

          <Link to={'/login'}>
            <i className="fa fa-circle"></i><span>Entrar na minha conta</span>
          </Link>
          <div className="menu-line"></div>
        </div>

        <Link to={'/cadastro/passo-2'} className="cf-bt-menu" style={{ marginLeft: 0 }}>
           <button className="cf-bt-cadastre cf-bt-menu">CADASTRE-SE!</button>
        </Link>
      </div>
    );
  }
}

export default withRouter(Menu);
