import React, { Component } from 'react';
import Menu from '../../components/Menu';
import TopoComMenu from '../../components/TopoComMenu';

export default class ErrorPage extends Component {
    constructor(props){
        super();

        this.error_code = 404;

        if (typeof props.match.params.error_code !== 'undefined')
            this.error_code = props.match.params.error_code;
    }

    render() {
        return (
            <div>
                <Menu/>
                <TopoComMenu/>

                <div className="content-wrapper">
                    <section className="content">
                        <div className="error-page">
                            <h2 className="headline text-yellow"> 404</h2>

                            <div className="error-content">
                                <h3><i className="fas fa-exclamation-triangle text-yellow"></i> Ops! Não encontramos a página.</h3>

                                <p>Parece que o link que você tentou acessar não existe mais ou mudou de endereço.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}