import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Passo2 extends Component {
    render() {
        return (
            <div>
                <div className="cf-texto cf-sub-title">Aguarde a análise</div>
                <div className="cf-texto cf-body">Vamos analisar o seu perfil e em breve você será notificado quanto a aprovação.</div>
            </div>
        );
    }
}

export default withRouter(Passo2);
