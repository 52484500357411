import React, { Component } from "react";
import QrReader from "react-qr-reader";
import { withRouter } from "react-router-dom";
import * as AtivarEquipamentoActions from "../../../actions/AtivarEquipamentoActions";

class AtivarComQRCode extends Component {
  constructor(props) {
    super();
    this.state = {
      cd_motorista: "",
      isLoading: false,
      chamadasExecutadas: 0,
    };
  }

  componentWillMount = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");
    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);
      if (motorista_logado.idc_equipamento !== 1) {
        this.setState({
          cd_motorista: motorista_logado.cd_motorista,
          isLoading: false,
          chamadasExecutadas: 0,
        });
      } else {
        this.props.history.push("/home");
      }
    } else {
      this.props.history.push("/login");
    }
  };

  setLoading = (status) => {
    let dados = this.state;
    dados.isLoading = status;
    this.setState(dados);
  };

  setChamadasExecutadas = () => {
    let dados = this.state;
    dados.chamadasExecutadas++;
    this.setState(dados);
  };

  _handleScan = (codigo) => {
    if (!this.state.isLoading) {
      // codigo = '76358;00003';
      if (codigo) {
        let dadosPlayer = /(\d{5});(\d{5})/g.exec(codigo);
        if (dadosPlayer !== null) {
          if (this.state.chamadasExecutadas < 5) {
            this.setChamadasExecutadas();
            AtivarEquipamentoActions.ativarComQrCode({
              cd_motorista: this.state.cd_motorista,
              cod_player: dadosPlayer[1],
              nome_player: dadosPlayer[2],
            })
              .then((response) => {
                if (response.data && response.data.result) {
                  let motorista_logado =
                    localStorage.getItem("motorista_logado");
                  if (
                    typeof motorista_logado !== "undefined" &&
                    motorista_logado !== null
                  ) {
                    motorista_logado = JSON.parse(motorista_logado);
                    motorista_logado.idc_equipamento = 1;
                    localStorage.setItem(
                      "motorista_logado",
                      JSON.stringify(motorista_logado)
                    );
                  }
                  this.props.history.push("/");
                } else {
                  this._handleError(
                    "Ocorreu um erro ao ativar seu equipamento, tente novamente."
                  );
                }
              })
              .catch((error) => {
                this._handleError(error.message);
                this.setLoading(false);
              });

            this.setLoading(true);
          } else {
            this.props.history.push("/");
          }
        }
      }
    }
  };

  _handleError = (error) => {
    console.error(error);
    let dados = this.state;
    dados.error = error;
    this.setState(dados);
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="texto-20 text-center">Ative a TV Zanzar</div>
        <div className="cf-texto div-body text-center  texto-16 space-26">
          Enquadre o QR Code na área indicada
        </div>
        <div
          className="div-div-center"
          style={
            this.state.error
              ? { color: "red", padding: "6px" }
              : { display: "none" }
          }
        >
          {this.state.error}
        </div>
        <div className="space-26"></div>
        <QrReader
          delay={2000}
          onError={this._handleError}
          onScan={this._handleScan}
          style={this.state.isLoading ? { display: "none" } : { width: "100%" }}
        />
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(AtivarComQRCode);
