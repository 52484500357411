import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import TopoSemMenu from '../../components/TopoSemMenu';
import Passo1 from './components/Passo1';
import Passo2 from './components/Passo2';
import Passo3 from './components/Passo3';
import Passo4 from './components/Passo4';
import Passo5 from './components/Passo5';
import Passo6 from './components/Passo6';
import Sucesso from './components/Sucesso';

export default class MinhaSenha extends Component {
    componentWillMount = () => {
        let motorista_logado = localStorage.getItem('motorista_logado');

        if (typeof motorista_logado === 'undefined' || motorista_logado === null) {
            window.location = '/como-funciona';
        }
    }

    render() {
        return (
            <div>
                <TopoSemMenu/>

                <Switch>
                    <Route path="/meus-aplicativos/passo-1" component={Passo1} />
                    <Route path="/meus-aplicativos/passo-2" component={Passo2} />
                    <Route path="/meus-aplicativos/passo-3" component={Passo3} />
                    <Route path="/meus-aplicativos/passo-4" component={Passo4} />
                    <Route path="/meus-aplicativos/passo-5" component={Passo5} />
                    <Route path="/meus-aplicativos/passo-6" component={Passo6} />
                    <Route path="/meus-aplicativos/sucesso" component={Sucesso} />
                </Switch>
            </div>
        );
    }
}