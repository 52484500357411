import isValidCep from "@brazilian-utils/is-valid-cep";

const formHelp = require("../../../helpers/FormHelpMsg");

export const validarCampoCEP = (cep, cepNaoEncontrado) => {
  if (cep === "") {
    formHelp.showHelp(
      "cep",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (!isValidCep(cep)) {
    formHelp.showHelp("cep", "CEP inválido.", "box-input-required");
    return false;
  }
  if (cepNaoEncontrado) {
    formHelp.showHelp("cep", "CEP nao encontrado", "box-input-required");
    return false;
  }
  return true;
};

export const validarCampoLogradouro = (logradouro) => {
  if (logradouro === "") {
    formHelp.showHelp(
      "logradouro",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoNumero = (numero) => {
  if (numero === "") {
    formHelp.showHelp(
      "numero",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};
