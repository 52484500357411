import React, { Component } from 'react';
import $ from 'jquery';
import { validate as isEmail } from "email-validator";

import * as MotoristaActions from '../../../actions/MotoristaActions';

export default class FormEsqueciSenha extends Component {
  constructor(props){
    super();

    this.state = {
      email: '',
      show: false,
      error: null
    };

    this.camposObrigatorios = ['email', 'senha'];
  }

  componentDidMount() {
    $('.box-input').click(function() {
      var label = $(this).find('.input-label');
      var input = $('#'+ label.attr('for'));

      label.removeClass('input-label');
      label.addClass('input-label-sm');
      input.removeClass('input-hide');

      $('.box-input-sel').removeClass('box-input-sel');
      $(this).addClass('box-input-sel');

      input.trigger('click');
    });
  }

  _recuperarSenha = () => {
    let formOk = true;

    this.setState({show: false, error: null});

    this.camposObrigatorios.forEach(function(item) {
      if ($('#'+ item).val() === ''){
        $('#'+ item).parent().addClass('box-input-required');

        formOk = false;
      }
    });

    if ($('#email').val() !== '' && !isEmail($('#email').val())){
      $('#email').parent().addClass('box-input-required');
      formOk = false;
    }

    if (formOk) {
      let email = this.state.email;
      let currentUrl = window.location.origin

      MotoristaActions.recuperarSenha(email, currentUrl)
        .then(response => {
          if (response.data.isOk) {
            this.setState({ show: true });
          } else {
            this.setState({ error: response.data.message });
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.setState({ error: error.message });
        });
    }
  }

  _updateField = async function(event) {
    if (/^[^ ,;:<>()[\]\\|/]*$/.test(event.target.value)) {
      let dados = this.state;
      dados[event.target.id] = event.target.value;
      await this.setState(dados);
    }
  }

  render() {
    return (
      <div className="page-content header-clear">
        <div className="title title-36">ESQUECI SENHA</div>
        <div className="space-32"></div>

        <div className="cf-texto div-body margin-30">Informe seu email</div>

        <div className="space-71" style={ (this.state.show || this.state.error) ? { display: 'none' } : {} }></div>
        <div className="space-20" style={ (this.state.show || this.state.error) ? { } : { display: 'none' } }></div>

        <div className="alert alert-success margin-25" style={ this.state.show ? { padding: 6, marginLeft: 10, marginRight: 10 } : { display: 'none' } }>
          Uma e-mail foi enviado p/ o endereço informado com instruções p/ trocar sua senha.
        </div>

        <div className="alert alert-danger div-div-center margin-25" style={ this.state.error ? { padding: 6 } : { display: 'none' } }>
          { this.state.error }
        </div>
        <div className="space-16"></div>

        <div className="box-input-only2 margin-25">
          <input type="text" id="email" className="box-input-only input2" value={ this.state.email } onChange={ e => { this._updateField(e) } } />
          <label className={ this.state.email === '' ? 'input-label2' : 'input-label-sm2' } htmlFor="email">E-mail</label>
        </div>

        <div className="space-26"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._recuperarSenha()}>ENVIAR</button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
