import $ from "jquery";
import React, { Component } from "react";
import { Redirect } from "react-router";

const Actions = require("../../../actions/MotoristaActions");
const BoxHelper = require("../../../helpers/BoxHelper");
const formHelp = require("../../../helpers/FormHelpMsg");
const validations = require("../validations");

export default class Formulario extends Component {
  constructor(props) {
    super();

    this.state = {
      minha_senha: {
        senha: "",
        senha_confirma: "",
        error: false,
      },
    };

    this.redirect = true;
    this.campos = ["senha", "senha_confirma"];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();

    let motorista_logado = localStorage.getItem("motorista_logado");

    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);

      this.setState({
        minha_senha: this.state.minha_senha,
        cd_motorista: motorista_logado.cd_motorista,
        email: motorista_logado.email,
        redirect: false,
      });
    } else {
      window.location = "/login";
    }
  }

  _gravar = async () => {
    let formOk = true;

    this.campos.forEach(function (item) {
      $("#" + item)
        .parent()
        .trigger("click");
    });

    if (!validations.validarCampoSenha($("#senha").val())) {
      formOk = false;
    }

    if (
      !validations.validarCampoConfirmaSenha(
        $("#senha").val(),
        $("#senha_confirma").val()
      )
    ) {
      formOk = false;
    }

    if (formOk) {
      let cd_motorista = this.state.cd_motorista;
      let email = this.state.email;
      let senha = this.state.minha_senha.senha;

      try {
        await Actions.alterarSenha(cd_motorista, email, senha);
        this.setState({ redirect: true });
      } catch (error) {
        let errorMsg =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        this.setState({
          error: errorMsg,
        });
      }
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    formHelp.hideHelp(campo, "box-input-required");
    if (campo === "senha") {
      validations.validarCampoSenha(valor);
    } else if (campo === "senha_confirma") {
      validations.validarCampoConfirmaSenha($("#senha").val(), valor);
    }
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    dados.minha_senha[campo] = valor;
    await this.setState(dados);
  };

  render() {
    if (this.state.redirect && this.redirect) {
      this.redirect = false;
      return <Redirect to="/minha-senha/sucesso" />;
    }
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Senha Zanzar</div>
        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30">
          Atualizar a senha para sua conta Zanzar
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "50%" }}></div>
          <div className="div-progress-bullet"></div>
        </div>
        <div
          className="alert alert-danger margin-25"
          style={
            this.state.error
              ? { padding: 6, marginLeft: 10, marginRight: 10 }
              : { display: "none" }
          }
        >
          { this.state.error }
        </div>
        <div className="space-16"></div>
        <div className="box-input margin-25" id="senhaInputContainer">
          <label className="input-label" htmlFor="senha">
            Senha
          </label>
          <input
            type="password"
            id="senha"
            className="input input-hide"
            placeholder="Mínimo de 8 dígitos"
            value={this.state.minha_senha.senha}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="space-16"></div>
        <div className="box-input margin-25" id="senha_confirmaInputContainer">
          <label className="input-label" htmlFor="senha_confirma">
            Confirme a senha
          </label>
          <input
            type="password"
            id="senha_confirma"
            className="input input-hide"
            placeholder="Mínimo de 8 dígitos"
            value={this.state.minha_senha.senha_confirma}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            SALVAR DADOS
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
