import uuid from 'uuid/v1';
import mobile from 'is-mobile';
import * as DeviceDetect from 'react-device-detect';

const KEY_NAME = 'client_key';

export const getClientKey = (key_name = KEY_NAME) => {
  let client_key = localStorage.getItem(key_name);

  if (typeof client_key === 'undefined' || client_key === null || client_key === '') {
    client_key = uuid();
    localStorage.setItem(key_name, client_key);
  }

  return client_key;
}

export const verifyClientKey = (key_name = KEY_NAME) => {
  let client_key = localStorage.getItem(key_name);

  if (typeof client_key === 'undefined' || client_key === null || client_key === '') {
    localStorage.setItem(key_name, uuid());
  }
}

export const getDeviceInfo = async () => {
  if (mobile()) {
    let deviceinfo = '{';

    try { let deviceDetect = await DeviceDetect.deviceDetect(); deviceinfo += 'deviceDetect: ' + JSON.stringify(deviceDetect); } catch(e) {}

    deviceinfo += '}';

    return deviceinfo;
  } else {
    return 'IS NOT MOBILE';
  }
}
