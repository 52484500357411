import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import TopoSemMenu from "../../components/TopoSemMenu";
import TopoSemMenuComLogout from "../../components/TopoSemMenuComLogout";
import Passo10 from "./components/Passo10";
import Passo11 from "./components/Passo11";
import Passo12 from "./components/Passo12";
import Passo13 from "./components/Passo13";
import Passo14 from "./components/Passo14";
import Passo14x2 from "./components/Passo14-2";
import Passo2 from "./components/Passo2";
import Passo3 from "./components/Passo3";
import Passo4 from "./components/Passo4";
import Passo5 from "./components/Passo5";
import Passo6 from "./components/Passo6";
import Passo7 from "./components/Passo7";
import Passo8 from "./components/Passo8";
import Passo9 from "./components/Passo9";
import Sucesso from "./components/Sucesso";

export default class Cadastro extends Component {
  constructor(props) {
    super();
    this.state = {
      ComponentMenu: TopoSemMenu,
      Menu: 1,
    };
  }

  componentDidMount() {
    if (["/cadastro/passo-14"].includes(window.location.pathname)) {
      this.setState({ ComponentMenu: TopoSemMenuComLogout, Menu: 2 });
    } else {
      this.setState({ ComponentMenu: TopoSemMenu, Menu: 1 });
    }
  }

  setItemState = (val) => {
    if (val === 1) {
      this.setState({ ComponentMenu: TopoSemMenu, Menu: val });
    } else {
      this.setState({ ComponentMenu: TopoSemMenuComLogout, Menu: val });
    }
  };

  render() {
    return (
      <div>
        <this.state.ComponentMenu />
        <Switch>
          <Route path="/cadastro/passo-2" component={Passo2} />
          <Route path="/cadastro/passo-3" component={Passo3} />
          <Route path="/cadastro/passo-4" component={Passo4} />
          <Route path="/cadastro/passo-5" component={Passo5} />
          <Route path="/cadastro/passo-6" component={Passo6} />
          <Route path="/cadastro/passo-7" component={Passo7} />
          <Route path="/cadastro/passo-8" component={Passo8} />
          <Route path="/cadastro/passo-9" component={Passo9} />
          <Route path="/cadastro/passo-10" component={Passo10} />
          <Route path="/cadastro/passo-11" component={Passo11} />
          <Route path="/cadastro/passo-12" component={Passo12} />
          <Route path="/cadastro/passo-13" component={Passo13} />
          <Route
            path="/cadastro/passo-14"
            component={() => (
              <Passo14
                setItemState={this.setItemState}
                Menu={this.state.Menu}
              />
            )}
          />
          <Route
            path="/cadastro/passo-14-2"
            component={() => (
              <Passo14x2
                setItemState={this.setItemState}
                Menu={this.state.Menu}
              />
            )}
          />
          <Route path="/cadastro/sucesso" component={Sucesso} />
        </Switch>
      </div>
    );
  }
}
