import React, { Component } from "react";

export default class Sucesso extends Component {
  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-26"></div>
        <img
          src={require("../../../resources/imgs/svg/aprovado-icon.svg")}
          className="center"
          style={{ width: "156px" }}
          alt=""
        />
        <div className="space-50"></div>
        <div className="title title-32 text-center text-destaque">
          Dados gravados com sucesso!
        </div>
      </div>
    );
  }
}
