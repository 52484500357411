import $ from "jquery";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as BoxHelper from '../../../helpers/BoxHelper';
import * as formHelp from '../../../helpers/FormHelpMsg';
import * as validations from '../validations';

class Passo5 extends Component {
  constructor(props) {
    super();

    this.state = {
      minha_senha: {
        senha: "",
        senha_confirma: "",
      },
    };

    this.campos = ["senha", "senha_confirma"];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();

    let cadastro_minha_senha = localStorage.getItem("cadastro_minha_senha");

    if (
      typeof cadastro_minha_senha !== "undefined" &&
      cadastro_minha_senha !== null
    ) {
      this.setState({ minha_senha: JSON.parse(cadastro_minha_senha) });
    }
  }

  _gravar = () => {
    let formOk = true;

    this.campos.forEach((item) => {
      $("#" + item)
        .parent()
        .trigger("click");
      $("#" + item).trigger("click");
    });

    if (!validations.validarCampoSenha($("#senha").val())) {
      formOk = false;
    }

    if (
      !validations.validarCampoConfirmaSenha(
        $("#senha").val(),
        $("#senha_confirma").val()
      )
    ) {
      formOk = false;
    }

    if (formOk) {
      localStorage.setItem(
        "cadastro_minha_senha",
        JSON.stringify(this.state.minha_senha)
      );
      this.props.history.push("/cadastro/passo-6");
      window.location = "/cadastro/passo-6";
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;

    formHelp.hideHelp(campo, "box-input-required");

    if (campo === "senha") {
      validations.validarCampoSenha(valor);
    } else if (campo === "senha_confirma") {
      validations.validarCampoConfirmaSenha($("#senha").val(), valor);
    }
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;

    dados.minha_senha[campo] = valor;

    await this.setState(dados);
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Senha Zanzar</div>
        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30">
          Crie uma senha para sua conta Zanzar
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "27%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="senhaInputContainer"
        >
          <input
            type="password"
            id="senha"
            placeholder="Mínimo de 8 dígitos"
            value={this.state.minha_senha.senha}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
            className="box-input-only input2"
          />
          <label className="input-label-sm2" htmlFor="senha">
            Senha
          </label>
          <span className="help-inline"></span>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="senha_confirmaInputContainer"
        >
          <input
            type="password"
            id="senha_confirma"
            placeholder="Mínimo de 8 dígitos"
            value={this.state.minha_senha.senha_confirma}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
            className="box-input-only input2"
          />
          <label className="input-label-sm2" htmlFor="senha_confirma">
            Confirme a senha
          </label>
          <span className="help-inline"></span>
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(Passo5);
