import $ from 'jquery';

const boxFormPrepare = (e) => {
	let id = e.target.id;
	let label = $('#' + id + 'InputContainer').find('.input-label');
	let input = $('#' + id + 'InputContainer').find(':input');

	label.removeClass('input-label');
	label.addClass('input-label-sm');
	input.removeClass('input-hide');

	$('.box-input-sel').removeClass('box-input-sel');
	$('#' + id + 'InputContainer').addClass('box-input-sel');

	input.focus();
};

export const boxFormSetup = () => {
	$('.box-input').click(boxFormPrepare);

	sessionStorage.setItem('last_field', '');

	$('.box-input input').focus((e) => {
		let item = e.target.id;

		if (sessionStorage.getItem('last_field') !== '#' + item) {
			if ($('#' + item).offset().top > 400) {
				$([document.documentElement, document.body]).animate({
					scrollTop: parseInt($('#' + item).offset().top - 100)
				}, 500);
			}

			sessionStorage.setItem('last_field', '#' + item)
		}
	});

	if ($('.div-bottom').length > 0 && $('.box-input :input').length) {
    if (($('#'+ $('.box-input :input')[$('.box-input :input').length - 1].id).offset().top + 68) > $('.div-bottom').offset().top)
      $('.div-bottom').removeClass('div-bottom');
  }
};
