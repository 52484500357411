import axios from 'axios';
import $ from 'jquery';

import auth from '../auth/initAuth';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;
const servico = '/motorista/';

export const cadastrarMotorista = ({
  nome,
  email,
  cpf,
  telefone,
  cep,
  logradouro,
  numero,
  complemento,
  bairro,
  cidade,
  uf,
  pais,
  senha,
  aceita_receber_whatsapp,
  cd_indicacao,
}) => {
  return axios.post(
    urlAPI + servico,
    {
      nome,
      email,
      cpf,
      tel_celular: telefone,
      end_cep: cep,
      end_logradouro: logradouro,
      end_numero: numero,
      end_complemento: complemento,
      end_bairro: bairro,
      end_cidade: cidade,
      end_uf: uf,
      end_pais: pais,
      senha: null,
      aceita_receber_whatsapp,
      cd_indicacao,
    },
    headers()
  );
};

export const atualizarDadosMotorista = ({
  cd_motorista,
  nome,
  email,
  cpf,
  tel_celular,
}) => {
  return axios.patch(
    urlAPI + servico + cd_motorista,
    {
      nome,
      email,
      cpf,
      tel_celular,
    },
    headers()
  );
};

export const atualizarEndereco = ({
  cd_motorista,
  cep,
  logradouro,
  numero,
  complemento,
  bairro,
  cidade,
  uf,
}) => {
  return axios.patch(
    urlAPI + servico + cd_motorista,
    {
      end_cep: cep,
      end_logradouro: logradouro,
      end_numero: numero,
      end_complemento: complemento,
      end_bairro: bairro,
      end_cidade: cidade,
      end_uf: uf,
    },
    headers()
  );
};

export const atualizarSenha = ({ cd_motorista, senha }) => {
  return axios.patch(urlAPI + servico + cd_motorista, { senha: senha }, headers());
};

export const atualizarAceiteWhatsapp = (cd_motorista, aceita_receber_whatsapp) => {
  return axios.patch(urlAPI + servico + cd_motorista, { aceita_receber_whatsapp }, headers());
};

export const loginMotorista = async ({
  email,
  senha,
  captcha,
  valorCaptcha,
  tipoSistema,
}) => {
  return await axios
    .post(
      urlAPI + servico + 'login',
      { email, password: senha, captcha, valorCaptcha, tipoSistema },
      headers()
    )
    .then((response) => {
      if (response.data && response.data.isOk) {
        localStorage.setItem('motorista_logado', JSON.stringify(response.data));

        if (response.data.primeiro_acesso) {
          window.location = '/primeiro-acesso/' + email;
        } else {
          auth.login(email, senha, (err, authResult) => {
            if (err) {
              $('.login-invalido').show();
              $('.loader-main').addClass('loader-inactive');

              localStorage.removeItem('motorista_logado');

              return true;
            }

            if (authResult && authResult.idToken && authResult.accessToken) {
              this.setToken(authResult.accessToken, authResult.idToken);
              window.location = window.location.origin;
            }
          });
        }
      } else {
        $('.login-invalido').show();
        $('.loader-main').addClass('loader-inactive');
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        $('.login-invalido').show();
        $('.loader-main').addClass('loader-inactive');
      }
    });
};

export const recadastrarSenha = (email, senha, token) => {
  return axios.post(
    urlAPI + '/sistema-usuario/atualizar-senha',
    {
      email,
      senha,
      token,
      connection: config.AUTH0_MOTORISTA_DB,
    },
    headers()
  );
};

export const alterarSenha = (cd_motorista, email, senha) => {
  return axios.post(
    urlAPI + '/sistema-usuario/alterar-senha',
    {
      cd_motorista,
      email,
      senha,
    },
    headers()
  );
};

export const recuperarSenha = (email, currentUrl) => {
  return axios.post(urlAPI + servico + 'recuperar-senha', {
    email,
    url: currentUrl,
  });
};

export const aceitarTermos = (id) => {
  return axios.patch(urlAPI + servico + 'aceitar-termos/' + id, {}, headers());
};

export const obterStatusCadastro = async (email) => {
  let response = await axios.get(
    urlAPI + servico + 'verificar-cadastro/' + email,
    headers()
  );
  return response.data;
};

export const verificarEmailEmUso = async (email, cd_motorista = null) => {
  let response = await axios.get(
    urlAPI + servico + 'verificar-email-utilizado/' + email,
    headers()
  );
  if (cd_motorista !== null) {
    if (response.data.existe) {
      return !response.data.cd_motorista.includes(cd_motorista);
    }
  }
  return response.data.existe;
};

export const verificarCpfEmUso = async (cpf, cd_motorista = null) => {
  let response = await axios.get(
    urlAPI + servico + 'verificar-cpf-utilizado/' + cpf,
    headers()
  );
  if (cd_motorista !== null) {
    if (response.data.existe) {
      return !response.data.cd_motorista.includes(cd_motorista);
    }
  }
  return response.data.existe;
};

export const obterMotorista = async (id) => {
  let response = await axios.get(urlAPI + servico + id, headers());
  return response.data;
};

export const pesquisarCep = async (cep) => {
  let response = await axios.get(urlAPI + servico + 'cep/' + cep, headers());
  return response.data;
};

export const alterarSenhaPrimeiroAcesso = async (email, receberWhatsApp) => {
  let response = await axios.put(
    urlAPI + servico + 'alterar-senha-primeiro-acesso',
    {
      email,
      receberWhatsApp,
    },
    headers()
  );
  return response.data;
};

export const listarIndicacoes = () => {
  return axios
    .get(urlAPI + servico + 'obter-indicacoes')
    .then(res => res.data);
};
