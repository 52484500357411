import React, { Component } from "react";
import { Link } from "react-router-dom";

const ApuracaoMensalActions = require("../../../actions/ApuracaoMensalActions");

export default class Termo extends Component {
  constructor() {
    super();
    this.state = {
      nome_motorista: "",
      verificacaoPendente: true,
    };
  }

  componentWillMount = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");
    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);
      let pendente = await ApuracaoMensalActions.verificarSePendente(
        motorista_logado.cd_motorista
      );
      this.setState({
        nome_motorista: motorista_logado.nome,
        verificacaoPendente: pendente.pendente,
      });
    } else window.location = "/login";
  };

  _logout = () => {
    localStorage.removeItem("motorista_logado");
    window.location = "/login";
  };

  render() {
    return (
      <div className="page-content header-clear">
        <div className="space-16"></div>
        <div className="title title-32 margin-22">Meu Perfil</div>
        <div className="space-32"></div>
        <img
          src={require("../../../resources/imgs/svg/noun-wave.svg")}
          className="noun-wave"
          alt=""
        />
        <div className="cf-texto cf-title margin-22">
          Bem vindo, {this.state.nome_motorista}!
        </div>
        <div className="sidebar-menu">
          <Link to={"/dados-pessoais/formulario"}>
            <i className="fa fa-circle color-wisteria"></i>
            <span>Dados Pessoais</span>
          </Link>
          <div className="home-line"></div>
          <Link to={"/endereco/formulario"}>
            <i className="fa fa-circle color-wisteria"></i>
            <span>Endereço</span>
          </Link>
          <div className="home-line"></div>
          <Link to={"/minha-senha/formulario"}>
            <i className="fa fa-circle color-wisteria"></i>
            <span>Senha Zanzar</span>
          </Link>
          <div className="home-line"></div>
          {/* <Link to={'/meus-aplicativos/formulario'}><i className="fa fa-circle color-wisteria"></i><span>Aplicativos</span></Link>
                    <div className="home-line"></div> */}
          <Link
            to={"/verificacao/historico"}
            className={this.state.verificacaoPendente ? "menu-destaque" : ""}
          >
            <i
              className={
                "fa fa-circle" +
                (this.state.verificacaoPendente
                  ? " color-white"
                  : " color-wisteria")
              }
            ></i>
            <span>Verificação mensal</span>
          </Link>
          <div className="home-line"></div>
          <a href="#/" onClick={() => this._logout()}>
            <i className="fa fa-circle color-wisteria"></i>
            <span>Sair</span>
          </a>
          <div className="home-line"></div>
          &nbsp;
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}
