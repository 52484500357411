import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import TopoSomenteLogo from '../../components/TopoSomenteLogo';
import MenuInferior from '../../components/MenuInferior';
import Formulario from './components/Formulario';
import Sucesso from './components/Sucesso';

class FaleConosco extends Component {
    constructor(props){
        super();

        this.iconeCenter = 3;
        this.iconeSelected = 3;
        this.motorista_logado = null;

        if (typeof props.match.params.iconeCenter !== 'undefined')
            this.passo = props.match.params.iconeCenter;

        if (typeof props.match.params.iconeSelected !== 'undefined')
            this.passo = props.match.params.iconeSelected;
    }

    componentWillMount = async () => {
        let motorista_logado = localStorage.getItem('motorista_logado');

        if (typeof motorista_logado !== 'undefined' && motorista_logado !== null)
            this.motorista_logado = JSON.parse(motorista_logado);
        else
            this.props.history.push('/login');
    }

    render() {
        return (
            <div>
                <TopoSomenteLogo/>

                <Switch>
                    <Route path="/fale-conosco/formulario" component={Formulario} />
                    <Route path="/fale-conosco/sucesso" component={Sucesso} />
                </Switch>

                <MenuInferior iconeCenter={ this.iconeCenter } iconeSelected={ this.iconeSelected }/>
            </div>
        );
    }
}

export default withRouter(FaleConosco);
