import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Menu from '../../components/Menu';
import Topo from './components/Topo';
import Icones from './components/Icones';

import Passo1 from './components/Passo1';
import Passo2 from './components/Passo2';
import Passo3 from './components/Passo3';
import Passo4 from './components/Passo4';

class ComoFunciona extends Component {
  constructor(props){
    super();

    this.passo = 1;

    if (typeof props.match.params.passo !== 'undefined')
      this.passo = props.match.params.passo;
  }

  render() {
    return (
      <div>
        <Menu/>
        <Topo/>

        <div>
          <img src={require('../../resources/imgs/rectangle@3x.png')} alt="" style={{ width: '100%' }} />
          <div className="cf-texto cf-div-destaque">Com a Zanzar você ganha mais!</div>
        </div>

        <div className="caption">
          <div className="caption-center bottom-0">
            <div className="content bottom-0">
              <div className="cf-texto cf-title">Como funciona</div>

              <Switch>
                <Route exact path="/como-funciona" component={ () => <Icones passo= { 1 } /> } />
                <Route path="/como-funciona/vantagens" component={ () => <Icones passo= { 1 } /> } />
                <Route path="/como-funciona/1" component={ () => <Icones passo= { 1 } /> } />
                <Route path="/como-funciona/2" component={ () => <Icones passo= { 2 } /> } />
                <Route path="/como-funciona/3" component={ () => <Icones passo= { 3 } /> } />
                <Route path="/como-funciona/4" component={ () => <Icones passo= { 4 } /> } />
              </Switch>

              <Switch>
                <Route exact path="/como-funciona" component={Passo1} />
                <Route path="/como-funciona/vantagens" component={Passo1} />
                <Route path="/como-funciona/1" component={Passo1} />
                <Route path="/como-funciona/2" component={Passo2} />
                <Route path="/como-funciona/3" component={Passo3} />
                <Route path="/como-funciona/4" component={Passo4} />
              </Switch>

              <div className="space-34"></div>
              <div className="div-center">
                <div className="form-group col-6 col-md-6 col-sm-12">
                  <Link to={'/cadastro/passo-2'}>
                    <button className="cf-bt-cadastre margin-10">CADASTRE-SE!</button>
                  </Link>
                </div>
                <div className="form-group col-6 col-md-6 col-sm-12">
                  <Link to={'/login'}>
                    <button className="cf-bt-cadastre margin-10">FAÇA O LOGIN</button>
                  </Link>
                </div>
              </div>
              <div className="space-50"></div>

              <div className="cf-texto cf-title" style={{ width: '100%', display: 'contents' }}>Vantagens de ser Zanzar</div>
              <div className="space-26"></div>

              <img src={require('../../resources/imgs/estrelas.png')} alt="" style={{ width: '300px' }} />
              <div className="cf-texto cf-title title-24 text-purple" style={{ width: '100%' }}>MAIS QUALIDADE NO <br/> SERVIÇO PRESTADO</div>

              <div className="space-71"></div>
              <img src={require('../../resources/imgs/svg/ico-seguranca.svg')} alt="" />
              <div className="cf-texto cf-title title-24 text-purple" style={{ width: '100%' }}>SEGURANÇA E <br/> RASTREAMENTO</div>

              <div className="space-71"></div>
              <img src={require('../../resources/imgs/svg/ico-ok.svg')} alt="" />
              <div className="cf-texto cf-title title-24 text-purple" style={{ width: '100%' }}>ENTRETENIMENTO PARA <br/> O CLIENTE</div>

              <div className="space-71"></div>
              <img src={require('../../resources/imgs/ico-vantagens.png')} alt="" />
              <div className="cf-texto cf-title title-24 text-purple" style={{ width: '100%' }}>MAIS VANTAGENS PARA VOCÊ</div>

              <div className="space-34">&nbsp;</div>
              <div className="div-center">
                <div className="form-group col-6 col-md-6 col-sm-12">
                  <Link to={'/cadastro/passo-2'}>
                    <button className="cf-bt-cadastre margin-10">CADASTRE-SE!</button>
                  </Link>
                </div>
                <div className="form-group col-6 col-md-6 col-sm-12">
                  <Link to={'/login'}>
                    <button className="cf-bt-cadastre margin-10">FAÇA O LOGIN</button>
                  </Link>
                </div>
              </div>
              <div className="space-8"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ComoFunciona);
