import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import auth from "../../auth/initAuth";

class Callback extends Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    auth.parseHash(this.props.location.hash, this);
  }

  render() {
    return <h1>&nbsp;</h1>;
  }

  goToHome() {
    this.props.history.push("/home");
  }
}

export default withRouter(Callback);
