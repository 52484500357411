import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';

const Actions = require('../../../actions/OcorrenciaActions');

class Formulario extends Component {
    constructor(){
        super();

        this.state = {
            motivo: '',
            descricao: '',
            motivos: [],
            redirect: false
        };

        this.redirect = true;
        this.gerarAgendamento = 2;
    }

    componentWillMount = async () => {
        let motorista_logado = localStorage.getItem('motorista_logado');

        if (typeof motorista_logado !== 'undefined' && motorista_logado !== null) {
            motorista_logado = JSON.parse(motorista_logado);
            let dados = {
                cd_motorista: motorista_logado.cd_motorista,
                motivos: [],
                redirect: false
            };

            this.setState(dados);

            Actions.listarMotivos()
                .then(res => {
                    dados.motivos = res;

                    this.setState(dados);
                })
        } else {
            this.props.history.push('/login');
        }
    }

    _updateField = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;
        let dados = this.state;

        dados[campo] = valor;
        await this.setState(dados);
    }

    _enviar = () => {
        if (this.state.motivo === null || this.state.motivo === '') {
            let dados = this.state;
            dados.error = 'Selecione um motivo.';

            this.setState(dados);
        } else {
            let cd_motorista = this.state.cd_motorista;
            let cd_motivo = this.state.motivo;
            let descricao = this.state.descricao;

            Actions.registrarOcorrencia({ cd_motorista, cd_motivo, descricao })
                .then(res => {
                    // this.state.motivos.forEach((item) =>{
                    //     if (item.cd_motivo === parseInt(cd_motivo))
                    //         this.gerarAgendamento = item.idc_agendamento_automatico;
                    // });

                    this.setState({ redirect: true });
                });
        }
    }

    render() {
        if (this.state.redirect && this.redirect) {
            this.redirect = false;

            return <Redirect to={{ pathname: '/fale-conosco/sucesso', state: { gerarAgendamento: this.gerarAgendamento } }} />
        }

        return (
            <div className="page-content header-clear">
                <div className="space-16"></div>
                <div className="title title-32 margin-22">Fale Conosco</div>
                <div className="space-32"></div>

                <img src={require('../../../resources/imgs/svg/noun-wave.svg')}  className="noun-wave" alt="" />
                <div className="space-26"></div>
                <div className="cf-texto texto-16 margin-22">Está com algum problema, alguma dúvida ou sugestão? Nos envie uma mensagem:</div>
                <div className="space-30"></div>
                <div className="cf-texto texto-18 texto-bold margin-22">Assunto</div>
                <div className="space-8"></div>

                <div className="box-input margin-25 box-input-sel" id="motivoInputContainer" style={ this.state.error ? { marginBottom: '20px' } : {} }>
                    <label className="input-label-sm" htmlFor="motivo">Selecione o motivo da mensagem</label>
                    <select type="text" id="motivo" className="input" value={ this.state.motivo } onChange={ e => { this._updateField(e) } }>
                        <option value=""></option>
                    {
                        this.state.motivos.map((item, k) => {
                            return <option key={ k } value={ item.cd_motivo }>{ item.descricao }</option>
                        })
                    }
                    </select>
                    <span className="help-inline" style={ this.state.error ? { color: 'red', padding: '10px' } : { display: 'none' } }>{ this.state.error }</span>
                </div>

                <div className="box-input margin-25 box-input-sel" id="descricaoInputContainer" style={{ height: '105px' }}>
                    <label className="input-label-sm" htmlFor="descricao">Sua mensagem</label>
                    <textarea id="descricao" className="input" rows="3" style={{ height: '60px' }} value={ this.state.descricao } onChange={ e => { this._updateField(e) } }></textarea>
                    <span className="help-inline"></span>
                </div>
                <div className="space-34"></div>

                <div className="div-center">
                    <button className="bt-login margin-30" onClick={() => this._enviar()}>ENVIAR MENSAGEM</button>
                </div>
                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Formulario);
