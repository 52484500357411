import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class TopoSemMenuComLogoutEVoltar extends Component {
  _logout = () => {
    localStorage.removeItem('motorista_logado');
    localStorage.removeItem('cadastro_cd_motorista');

    window.location = '/login';
  }

  _goBack = () => {
    if (['/cadastro/passo-14-2'].includes(window.location.pathname))
      this.props.history.push('/cadastro/passo-14');
    else
      this.props.history.goBack();
  }

  _goHome = () => {
    this.props.history.push('/home');
  }

  render() {
    return (
      <div className="header header2 header-fixed header-logo-center">
        <div className="header-title">
          <img src={require('../resources/imgs/logo-zanzar.png')} className="logo-topo" alt="" onClick={ () => this._goHome() } />
        </div>
        <button className="header-icon header-icon-1 back-button" onClick={ () => this._goBack() }>
          <i className="fas fa-chevron-left"></i>
        </button>
        <button
          className="header-icon header-icon-1 back-button"
          style={{ float: 'right', position: 'unset' }}
          onClick={ () => this._logout() }
        >
          <img src={require('../resources/imgs/svg/ic-logout.svg')} alt="" style={{ marginLeft: '25%' }} />
        </button>
      </div>
    );
  }
}

export default withRouter(TopoSemMenuComLogoutEVoltar);
