import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import $ from 'jquery';

const ApuracaoMensalActions = require('../../../actions/ApuracaoMensalActions');
const MotoristaAplicativoActions = require('../../../actions/MotoristaAplicativoActions');
const formHelp = require('../../../helpers/FormHelpMsg');
const validations = require('../validations');

class ExecutarPasso1 extends Component {
    constructor(props){
        super();

        this.state = {
            minhas_viagens: {
                numero_viagens: '',
                cd_aplicativo: ''
            },
            meus_aplicativos: []
        };

        this.campos = ['meus_aplicativos','numero_viagens'];
    }

    componentDidMount() {
        $('.loader-main').removeClass('loader-inactive');

        let motorista_logado = localStorage.getItem('motorista_logado');

        if (typeof motorista_logado !== 'undefined' && motorista_logado !== null) {
            motorista_logado = JSON.parse(motorista_logado);
            let verificacao_minhas_viagens = localStorage.getItem('verificacao_minhas_viagens');

            if (typeof verificacao_minhas_viagens !== 'undefined' && verificacao_minhas_viagens !== null)
                this.setState({ minhas_viagens: JSON.parse(verificacao_minhas_viagens) });

            MotoristaAplicativoActions.listarPorMotorista(motorista_logado.cd_motorista)
                .then(aplicativos => {
                    ApuracaoMensalActions.verificarSePendente(motorista_logado.cd_motorista)
                        .then(pendente => {
                            if (pendente.pendente) {
                                let meus_aplicativos = [];

                                aplicativos.forEach((item) => {
                                    if (pendente.apps_pendentes.includes(item.cd_aplicativo))
                                        meus_aplicativos.push(item);
                                });

                                this.setState({ meus_aplicativos: meus_aplicativos });
                            } else {
                                this.props.history.push('/verificacao/historico');
                            }
                        })
                        .catch(error => {
                            $('.loader-main').addClass('loader-inactive');
                        });

                    $('.loader-main').addClass('loader-inactive');
                })
                .catch(error => {
                    $('.loader-main').addClass('loader-inactive');
                });
        } else {
            this.props.history.push('/login');
        }
    }

    _gravar = () => {
        let formOk = true;

        this.campos.forEach(function(item) {
            $('#'+ item).parent().trigger('click');
        });

        if (!validations.validarCampoPreenchido(this.state.minhas_viagens.numero_viagens, 'numero_viagens'))
            formOk = false;

        if (!validations.validarCampoPreenchido(this.state.minhas_viagens.cd_aplicativo, 'cd_aplicativo'))
            formOk = false;

        if (formOk) {
            localStorage.setItem('verificacao_minhas_viagens', JSON.stringify(this.state.minhas_viagens));

            this.props.history.push('/verificacao/executar-passo-2');
        }
    }

    _validarCampo = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;

        formHelp.hideHelp(campo, 'box-input-required');

        validations.validarCampoPreenchido(valor, campo);
    }

    _updateField = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;
        let dados = this.state;

        dados.minhas_viagens[campo] = valor;
        await this.setState(dados);
    }

    render() {
        return (
            <div className="page-content header-clear cadastro">
                <div className="title title-36">Viagens</div>

                <div className="cf-texto div-body margin-30 space-26">Quantas viagens você já fez?</div>

                <div className="div-separator">
                    <div className="div-progress" style={{ width: '45%' }}>
                        <div className="div-progress-bullet bg-purple"></div>
                    </div>
                    <div className="div-progress-bullet"></div>
                </div>

                <div className="texto-20 margin-25 space-16">Selecione quantas viagens você já realizou:</div>

                <div className="box-input-only2 margin-25 space-16" id="cd_aplicativoInputContainer">
                    <select id="cd_aplicativo" value={ this.state.minhas_viagens.cd_aplicativo } onChange={ e => { this._updateField(e) } } onBlur={ e => { this._validarCampo(e) } } className="box-input-only input2">
                        <option value=""></option>
                    {
                        this.state.meus_aplicativos.map((item, k) => {
                            return <option key={ k } value={ item.aplicativo.cd_aplicativo }>{ item.aplicativo.descricao }</option>
                        })
                    }
                    </select>
                    <label className={ this.state.minhas_viagens.cd_aplicativo === undefined || this.state.minhas_viagens.cd_aplicativo === '' ? 'input-label2' : 'input-label-sm2' } htmlFor="cd_aplicativo">Aplicativo</label>
                    <span className="help-inline"></span>
                </div>

                <div className="box-input-only2 margin-25 space-12" id="numero_viagensInputContainer">
                    <MaskedInput
                        mask={ [/\d/,/\d/,/\d/,/\d/,/\d/,/\d/] }
                        className="box-input-only input2"
                        guide={ false }
                        id="numero_viagens"
                        value={ this.state.minhas_viagens.numero_viagens }
                        onChange={ e => { this._updateField(e) } }
                        onBlur={ e => { this._validarCampo(e) } } />
                    <label className={ this.state.minhas_viagens.numero_viagens === undefined || this.state.minhas_viagens.numero_viagens === '' ? 'input-label2' : 'input-label-sm2' } htmlFor="numero_viagens">Número de viagens</label>
                    <span className="help-inline"></span>
                </div>

                <div className="space-71"></div>
                <div className="div-center div-bottom">
                    <button className="bt-login margin-30" onClick={() => this._gravar()}>CONTINUAR <i className="fas fa-chevron-right icon-14 float-right"></i></button>
                </div>
                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(ExecutarPasso1);
