import React, { Component } from "react";
import MenuInferior from "../../components/MenuInferior";
import TopoSomenteLogo from "../../components/TopoSomenteLogo";
import MeuPerfilComponente from "./components/MeuPerfil";

export default class MeuPerfil extends Component {
  constructor(props) {
    super();
    this.iconeCenter = 3;
    this.iconeSelected = 1;
    this.motorista_logado = null;
    if (typeof props.match.params.iconeCenter !== "undefined") {
      this.passo = props.match.params.iconeCenter;
    }
    if (typeof props.match.params.iconeSelected !== "undefined") {
      this.passo = props.match.params.iconeSelected;
    }
  }

  componentWillMount = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");
    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      this.motorista_logado = JSON.parse(motorista_logado);
    } else {
      window.location = "/login";
    }
  };

  render() {
    return (
      <div>
        <TopoSomenteLogo />
        <MeuPerfilComponente />
        <MenuInferior
          iconeCenter={this.iconeCenter}
          iconeSelected={this.iconeSelected}
        />
      </div>
    );
  }
}
