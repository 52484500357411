import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import TopoSemMenu from '../../components/TopoSemMenu';
import EscolherData from './components/EscolherData';
import EscolherPonto from './components/EscolherPonto';
import Confirmar from './components/Confirmar';

class Agendamento extends Component {
  render() {
    return (
      <div>
        <TopoSemMenu/>

        <Switch>
          <Route exact path="/agendamento/escolher-dia" component={ () => <EscolherData reagendar={ false } /> } />
          <Route exact path="/agendamento/escolher-dia/reagendar" component={ () => <EscolherData reagendar={ true } /> } />
          <Route exact path="/agendamento/escolher-ponto" component={ () => <EscolherPonto reagendar={ false } /> } />
          <Route exact path="/agendamento/escolher-ponto/reagendar" component={ () => <EscolherPonto reagendar={ true } /> } />
          <Route path="/agendamento/confirmar" component={Confirmar} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Agendamento);
