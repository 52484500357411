import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as MotoristaActions from "../../actions/MotoristaActions";
import * as ParametroSistemaActions from "../../actions/ParametroSistemaActions";
import TopoSomenteLogo from "../../components/TopoSomenteLogo";
import MenuInferior from "../../components/MenuInferior";
import AtivarEquipamentoQrCode from "./components/AtivarEquipamentoQrCode";
import FiqueLigado from "./components/FiqueLigado";

class Home extends Component {
  constructor(props) {
    super();

    this.state = {
      Component0: null,
    };

    this.iconeCenter = 3;
    this.iconeSelected = 2;
    this.motorista_logado = null;

    if (typeof props.match.params.iconeCenter !== "undefined") {
      this.passo = props.match.params.iconeCenter;
    }

    if (typeof props.match.params.iconeSelected !== "undefined") {
      this.passo = props.match.params.iconeSelected;
    }
  }

  _validarStatus = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");

    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      this.motorista_logado = JSON.parse(motorista_logado);

      if (this.motorista_logado.primeiro_acesso) {
        this.props.history.push("/primeiro-acesso");
      } else {
        if (
          [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(
            this.motorista_logado.idc_status
          ) ||
          this.motorista_logado.idc_equipamento === 2 ||
          [2, 3].includes(this.motorista_logado.idc_termo_aceito)
        ) {
          let statusCadastro = await MotoristaActions.obterStatusCadastro(
            this.motorista_logado.email
          );
          this.motorista_logado.idc_status = statusCadastro.idc_status;
          this.motorista_logado.idc_termo_aceito =
            statusCadastro.idc_termo_aceito;
          this.motorista_logado.idc_equipamento =
            statusCadastro.idc_equipamento;
          localStorage.setItem(
            "motorista_logado",
            JSON.stringify(this.motorista_logado)
          );
        }

        if (
          this.motorista_logado.idc_status === 14 &&
          this.motorista_logado.idc_cadastro_completo &&
          this.motorista_logado.idc_cadastro_completo.length > 0
        ) {
          localStorage.setItem(
            "cadastro_cd_motorista",
            this.motorista_logado.cd_motorista
          );
          localStorage.setItem(
            "cadastro_meus_aplicativos",
            JSON.stringify(this.motorista_logado.idc_cadastro_completo)
          );

          window.location = "/cadastro/passo-14";
        } else if (
          [3, 4, 5, 6, 7, 2, 9, 10, 11, 12, 13].includes(
            this.motorista_logado.idc_status
          ) ||
          [2, 3].includes(this.motorista_logado.idc_termo_aceito)
        ) {
          window.location = "/cadastro-acompanhamento";
        } else if (this.motorista_logado.idc_status === 8) {
          this.setState({ Component0: FiqueLigado });
        } else if (this.motorista_logado.idc_status === 1) {
          if (this.motorista_logado.idc_equipamento === 2) {
            ParametroSistemaActions.obter(
              "ATIVAR_EQUIPAMENTO_COM_QRCODE_ATIVO"
            ).then((usarQrCode) => {
              this.iconeCenter = 4;
              if (
                usarQrCode &&
                usarQrCode.valor &&
                usarQrCode.valor === "true"
              ) {
                this.setState({ Component0: AtivarEquipamentoQrCode });
              } else {
                this.props.history.push("/ativar-equipamento/ativar-codigo");
              }
            });
          } else {
            this.iconeCenter = 5;
            // this.setState({ Component0: MinhaCarteira });
            this.props.history.push("/verificacao/historico");
          }
        }
      }
    } else {
      window.location = "/como-funciona";
    }
  };

  componentDidMount = () => {
    this._validarStatus();
  };

  render() {
    var DivRender;

    if (
      this.motorista_logado !== null &&
      [1, 8].includes(this.motorista_logado.idc_status) &&
      this.motorista_logado.idc_termo_aceito === 1
    ) {
      DivRender = (
        <div>
          <TopoSomenteLogo />
          { this.state.Component0 !== null ? <this.state.Component0 /> : "" }
          <MenuInferior
            iconeCenter={this.iconeCenter}
            iconeSelected={this.iconeSelected}
          />
        </div>
      );
    }

    return <div>{DivRender}</div>;
  }
}

export default withRouter(Home);
