import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as AplicativoActions from '../../../actions/AplicativoActions';
import * as BoxHelper from '../../../helpers/BoxHelper';

class Passo7 extends Component {
  constructor(props) {
    super();

    this.state = {
      meus_aplicativos: [],
      aplicativos: [],
    };
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();
    let zanzar_aplicativos = localStorage.getItem("zanzar_aplicativos");
    if (
      typeof zanzar_aplicativos !== "undefined" &&
      zanzar_aplicativos !== null
    ) {
      this.setState({ aplicativos: JSON.parse(zanzar_aplicativos) });
    } else {
      AplicativoActions.listarAplicativos().then((res) => {
        this.setState({ aplicativos: res });
        localStorage.setItem("zanzar_aplicativos", JSON.stringify(res));
      });
    }
    let cadastro_meus_aplicativos = localStorage.getItem(
      "cadastro_meus_aplicativos"
    );
    if (
      typeof cadastro_meus_aplicativos !== "undefined" &&
      cadastro_meus_aplicativos !== null
    ) {
      this.setState({
        meus_aplicativos: JSON.parse(cadastro_meus_aplicativos),
      });
    }
  }

  _gravar = () => {
    if (this.state.meus_aplicativos.length === 0) {
      let dados = this.state;
      dados.error = "Selecione um ou mais aplicativos p/ continuar.";
      this.setState(dados);
    } else {
      localStorage.setItem(
        "cadastro_meus_aplicativos",
        JSON.stringify(this.state.meus_aplicativos)
      );
      this.props.history.push("/cadastro/passo-8");
    }
  };

  _updateField = (event) => {
    let dados = this.state;
    if (event.target.type === "checkbox") {
      let valor = parseInt(event.target.value);
      if (event.target.checked) {
        if (!dados.meus_aplicativos.includes(valor)) {
          dados.meus_aplicativos.push(valor);
        }
      } else {
        dados.meus_aplicativos = dados.meus_aplicativos.filter((value) => {
          return parseInt(valor) !== parseInt(value);
        });
      }
    } else {
      let campo = event.target.id;
      let valor = event.target.value;
      dados.meus_aplicativos[campo] = valor;
    }
    this.setState(dados);
  };

  _isSelected = (cod) => {
    return (
      this.state.meus_aplicativos.filter((value) => {
        return parseInt(value) === cod;
      }).length > 0
    );
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Aplicativos</div>
        <div className="cf-texto div-body margin-30 space-26">
          Em quais apps você trabalha?
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "36%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div className="texto-20 margin-25 space-16">
          Selecione todos os apps que você trabalha:
        </div>
        {this.state.aplicativos.map((item, k) => {
          let id = "app_" + item.cd_aplicativo;
          return (
            <div className="checkbox-nice margin-25 space-8" key={k}>
              <input
                className="checkbox-nice"
                type="checkbox"
                name={id}
                id={id}
                value={item.cd_aplicativo}
                onChange={(e) => {
                  this._updateField(e);
                }}
                checked={this._isSelected(item.cd_aplicativo)}
              />
              <label className="texto-20" htmlFor={id}>
                {item.descricao}
              </label>
            </div>
          );
        })}
        <div
          className="div-center help-inline space-16"
          style={
            this.state.error
              ? { color: "red", padding: "10px" }
              : { display: "none" }
          }
        >
          {this.state.error}
        </div>
        <div className="space-71"></div>
        <div className="div-center div-bottom">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(Passo7);
