import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;
const servico = '/motorista-aplicativo/';

export const cadastrarMotoristaAplicativo = ({
  cd_motorista,
  cd_aplicativo,
  cd_aplicativo_categoria,
  cd_aplicativo_segmento,
  viagens_realizadas,
  tempo_aplicativo,
  nota_motorista,
}) => {
  return axios.post(
    urlAPI + servico,
    {
      cd_motorista: cd_motorista,
      cd_aplicativo: cd_aplicativo,
      cd_aplicativo_categoria: cd_aplicativo_categoria,
      cd_aplicativo_segmento: cd_aplicativo_segmento,
      viagens_realizadas: viagens_realizadas,
      tempo_aplicativo: tempo_aplicativo,
      nota_motorista: nota_motorista,
    },
    headers()
  );
};

export const alterarMotoristaAplicativo = ({ cd_motorista, aplicativos }) => {
  return axios.patch(
    urlAPI + servico + cd_motorista,
    { aplicativos: aplicativos },
    headers()
  );
};

export const uploadImagemPerfil = ({
  cd_motorista,
  cd_aplicativo,
  arquivo,
}) => {
  const formData = new FormData();

  formData.append('cd_motorista', cd_motorista);
  formData.append('cd_aplicativo', cd_aplicativo);
  formData.append('file', arquivo, arquivo.name);

  return axios.post(urlAPI + servico + 'upload', formData, {
    timeout: 600000,
    maxContentLength: 52428890,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const obterStatusCadastro = async (cd_motorista, cd_aplicativo) => {
  let response = await axios.get(
    `${urlAPI}${servico}verificar-cadastro/${cd_motorista}/${cd_aplicativo}`,
    headers()
  );

  return response.data;
};

export const listarPorMotorista = async (cd_motorista) => {
  let response = await axios.get(
    urlAPI + servico + 'obter-por-motorista/' + cd_motorista,
    headers()
  );

  return response.data;
};

export const listarPorMotoristaParaEdicao = async (cd_motorista) => {
  let response = await axios.get(
    urlAPI + servico + 'obter-em-aberto-por-motorista/' + cd_motorista,
    headers()
  );

  return response.data;
};
