import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class TopoComMenu extends Component {
  _goHome = () => {
    this.props.history.push('/home');
  }

  render() {
    return (
      <div className="header header2 header-fixed header-logo-center">
        <div className="header-title">
          <img src={require('../resources/imgs/logo-zanzar.png')} className="logo-topo" alt="" onClick={ () => this._goHome() } />
        </div>
        <a href="#/" className="header-icon header-icon-1" data-menu="menu-sidebar">
          <i className="fas fa-bars"></i>
        </a>
      </div>
    );
  }
}

export default withRouter(TopoComMenu);
