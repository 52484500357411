import $ from "jquery";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import auth from "../../../auth/initAuth";

const validations = require("../validations");
const formHelp = require("../../../helpers/FormHelpMsg");
const MotoristaActions = require("../../../actions/MotoristaActions");

class FormPrimeiroAcesso extends Component {
  constructor(props) {
    super();
    this.state = {
      novaSenha: "",
      confNovaSenha: "",
      receberWhatsApp: false,
      showForm: true,
      show: false,
      error: "",
    };
    this.camposObrigatorios = ["novaSenha", "confNovaSenha"];
  }

  componentDidMount = async () => {
    $(".loader-main").addClass("loader-inactive");
    if (this.props.location.pathname.split("/").length !== 3) {
      window.location = "/login";
    }
  };

  _updateField = async (event) => {
    let dados = this.state;
    dados[event.target.id] = event.target.value;
    await this.setState(dados);
  };

  _gravar = () => {
    let formOk = true;
    this.camposObrigatorios.forEach(function (item) {
      if ($("#" + item).val() === "") {
        formOk = false;
      }
    });
    if (!formOk) {
      toast.error("Todos os campos são obrigatórios.");
    }
    if (formOk && this.state.novaSenha !== this.state.confNovaSenha) {
      toast.error("Senha e confirmação devem ser iguais.");
      formOk = false;
    }
    if (formOk && this.state.novaSenha !== "") {
      let strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (!strongRegex.test(this.state.novaSenha)) {
        toast.error(
          'A senha deve combinar letras maiúsculas, minúsculas, números e um dos seguintes caracteres "!@#$%^&*".'
        );
        formOk = false;
      }
    }
    if (formOk) {
      $(".loader-main").removeClass("loader-inactive");
      let { novaSenha, receberWhatsApp } = this.state;
      const email = this.props.location.pathname.split("/")[2];
      auth.signup(email, novaSenha, function (err) {
        if (err) {
          toast.error("Erro ao alterar sua senha, tente novamente mais tarde.");
          formOk = false;
        } else {
          MotoristaActions.alterarSenhaPrimeiroAcesso(email, receberWhatsApp)
            .then((response) => {
              if (response.isOk) {
                toast.success("Senha alterada com sucesso !");
                /*auth.login(email, novaSenha, (err, authResult) => {
                  if (err) {
                    $(".alert").show();
                    $(".loader-main").addClass("loader-inactive");
                    localStorage.removeItem("motorista_logado");
                    return true;
                  }
                  if (
                    authResult &&
                    authResult.idToken &&
                    authResult.accessToken
                  ) {
                    this.setToken(authResult.accessToken, authResult.idToken);
                    window.location = "/cadastro-acompanhamento";
                  }
                });*/
                window.location = "/login";
              } else {
                if (response.data.message) {
                  toast.error(
                    "Erro ao alterar sua senha, tente novamente mais tarde."
                  );
                } else {
                  toast.error(
                    "Erro ao alterar sua senha, tente novamente mais tarde."
                  );
                }
              }
              $(".loader-main").addClass("loader-inactive");
            })
            .catch((error) => {
              toast.error(
                "Erro ao alterar sua senha, tente novamente mais tarde."
              );
              $(".loader-main").addClass("loader-inactive");
            });
        }
      });
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    formHelp.hideHelp(campo, "box-input-required");
    if (campo === "novaSenha") {
      validations.validarCampoSenha(valor);
    } else if (campo === "senha_confirma") {
      validations.validarCampoConfirmaSenha($("#senha").val(), valor);
    }
  };

  toggleChange = () => {
    this.setState({
      receberWhatsApp: !this.state.receberWhatsApp,
    });
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <div className="page-content header-clear cadastro">
          <div className="title title-32 text-center text-destaque">
            Alterar sua senha primeiro neste primeiro acesso !
          </div>
          <div className="div-separator">
            <div className="div-progress" style={{ width: "50%" }}></div>
            <div className="div-progress-bullet"></div>
          </div>
          <div className="space-16"></div>
          <div
            className="alertCamposObrigatorios alert-danger margin-25"
            style={{ padding: "6px", display: "none" }}
          >
            {this.state.error}
          </div>
          <div className="box-input margin-25" id="senhaInputContainer">
            <label className="input-label" htmlFor="novaSenha">
              Nova senha
            </label>
            <input
              type="password"
              id="novaSenha"
              className="input input-hide"
              placeholder="Mínimo de 6 dígitos"
              value={this.state.novaSenha}
              onChange={(e) => {
                this._updateField(e);
              }}
              onBlur={(e) => {
                this._validarCampo(e);
              }}
            />
            <span className="help-inline"></span>
          </div>
          <div
            className="box-input margin-25"
            style={this.state.showForm ? {} : { display: "none" }}
          >
            <label className="input-label" htmlFor="confNovaSenha">
              Confirme a nova senha
            </label>
            <input
              type="password"
              id="confNovaSenha"
              className="input input-hide"
              defaultChecked={this.state.confNovaSenha}
              placeholder="Mínimo de 6 dígitos"
              onChange={(e) => {
                this._updateField(e);
              }}
              onBlur={(e) => {
                this._validarCampo(e);
              }}
            />
          </div>
          <div
            className="margin-25"
            style={this.state.showForm ? {} : { display: "none" }}
          >
            <input
              className="checkbox-nice"
              type="checkbox"
              name="receberWhatsApp"
              id="receberWhatsApp"
              checked={this.state.receberWhatsApp}
              onChange={this.toggleChange}
            />
            <label className="texto-20" htmlFor="receberWhatsApp">
              Deseja receber mensagens por WhatsApp ?
            </label>
          </div>
          <div className="space-26"></div>
          <div className="div-center">
            <button
              className="bt-login margin-30"
              onClick={() => this._gravar()}
              style={this.state.showForm ? {} : { display: "none" }}
            >
              ALTERAR A SENHA
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FormPrimeiroAcesso);
