import React, { Component } from "react";
import { Link } from "react-router-dom";

import { obterAgendamento } from "../../../actions/AgendamentoActions";
import Formatters from "../../../helpers/Formatters";

export default class FiqueLigado extends Component {
  constructor() {
    super();

    this.state = {
      error: "",
      agendamento: {},
    };
  }

  componentDidMount = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");

    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);

      let agendamento = await obterAgendamento(motorista_logado.cd_motorista);

      if (agendamento && agendamento.dt_agendada) {
        this.setState({ agendamento: agendamento });
      } else {
        this.setState({ error: "Não existe agendamentos disponíveis." });
      }
    } else {
      window.location = "/login";
    }
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-8"></div>
        <img
          src={require("../../../resources/imgs/svg/fique-ligado-icon.svg")}
          className="center"
          style={{ width: "215px" }}
          alt=""
        />
        <div className="title title-22 text-center text-destaque">
          Fique ligado!
        </div>
        <div className="space-8"></div>
        <div
          className="cf-texto div-body margin-30 text-center"
          style={this.state.error ? { display: "none" } : {}}
        >
          Você possui uma instalação da
          <br />
          TV Zanzar agendada:
        </div>
        <div
          className="cf-texto div-body div-gray text-center"
          style={
            this.state.error
              ? { color: "red", padding: "6px" }
              : { display: "none" }
          }
        >
          <div className="vcenter">{this.state.error}</div>
        </div>
        <div className="space-26"></div>
        <div
          className="row margin-22"
          style={
            !this.state.agendamento.dt_agendada || this.state.error
              ? { display: "none" }
              : {}
          }
        >
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-ponto-inst box-ponto-confirm box-destaque">
            <div className="titulo">
              { this.state.agendamento.ponto_apoio }
              <i className="fas fa-map-marker-alt icon-12 float-right" aria-hidden="true">
                &nbsp;
                <span className="km">{ (this.state.agendamento.distancia / 1000).toFixed(1) }km</span>
              </i>
            </div>
            <div className="endereco">{this.state.agendamento.endereco}</div>
            <div className="data">
              Dia: { Formatters.formatarData('DD/MM', this.state.agendamento.dt_agendada) }
              &nbsp;&nbsp;&nbsp;&nbsp;
              Horário: {
                this.state.agendamento.idc_agenda_hora
                  ? Formatters.formatarData('HH:mm', this.state.agendamento.dt_agendada)
                  : `${this.state.agendamento.horario_atend_ini} às ${this.state.agendamento.horario_atend_fim}`
              }
            </div>
          </div>
        </div>
        <div
          className="div-body link-text-12 text-center"
          style={
            !this.state.agendamento.dt_agendada || this.state.error
              ? { display: "none" }
              : {}
          }
        >
          <Link to={"/agendamento/escolher-ponto/reagendar"}>
            REAGENDAR INSTALAÇÃO &nbsp;
            <i className="fas fa-chevron-right icon-14"></i>
          </Link>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}
