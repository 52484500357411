import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Passo3 extends Component {
    render() {
        return (
            <div>
                <div className="cf-texto cf-sub-title">Agendamento</div>
                <div className="cf-texto cf-body">Após aprovação será realizado o agendamento para a instalação da TV Zanzar.</div>
            </div>
        );
    }
}

export default withRouter(Passo3);
