import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import TopoSemMenu from '../../components/TopoSemMenu';
import MenuInferior from '../../components/MenuInferior';
import Historico from './components/Historico';
import ExecutarPasso1 from './components/ExecutarPasso1';
import ExecutarPasso2 from './components/ExecutarPasso2';

class Verificacao extends Component {
    constructor(props){
        super();

        this.iconeCenter = 5;
        this.iconeSelected = 2;
    }

    render() {
        return (
            <div>
                <TopoSemMenu/>

                <Switch>
                    <Route path="/verificacao/historico">
                        <Historico />
                        <MenuInferior iconeCenter={ this.iconeCenter } iconeSelected={ this.iconeSelected } />
                    </Route>
                    <Route path="/verificacao/executar-passo-1" component={ExecutarPasso1} />
                    <Route path="/verificacao/executar-passo-2" component={ExecutarPasso2} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(Verificacao);
