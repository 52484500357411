import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const ApuracaoMensalActions = require('../../../actions/ApuracaoMensalActions');
const formatDate = require('date-format');

class Historico extends Component {
    constructor(){
        super();

        this.state = {
            proximaVerificacao: '',
            historico: []
        };
    }

    componentWillMount = async () => {
        localStorage.removeItem('verificacao_minhas_viagens');

        let motorista_logado = localStorage.getItem('motorista_logado');

        if (typeof motorista_logado !== 'undefined' && motorista_logado !== null){
            motorista_logado = JSON.parse(motorista_logado);

            let historico = await ApuracaoMensalActions.obterHistorico(motorista_logado.cd_motorista);

            let dados = {
                cd_motorista: motorista_logado.cd_motorista
            };

            let hoje = new Date();
            hoje.setDate(1);
            hoje.setMonth(hoje.getMonth() + 1);

            dados.proximaVerificacao = formatDate('dd/MM/yyyy', hoje)

            if (historico)
                dados.historico = historico;

            this.setState(dados);
        }
        else
            window.location = '/login';
    }

    _executarVerificacao = () => {
        ApuracaoMensalActions.verificarSePendente(this.state.cd_motorista)
            .then(res => {
                if (res.pendente) {
                    this.props.history.push('/verificacao/executar-passo-1');
                } else {
                    alert('Não existem verificaçãoes pendentes para realizar.');
                }
            });
    }

    render() {
        return (
            <div className="page-content header-clear">
                <div className="title title-32 margin-22 space-16">Verificação Mensal</div>
                <div className="space-32"></div>

                <img src={require('../../../resources/imgs/svg/noun-wave.svg')}  className="noun-wave" alt="" />

                <div className="row margin-22 space-32">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-ponto-inst box-ponto-confirm box-destaque box-verificacao">
                        <div className="titulo">Próxima verificação</div>
                        <div className="texto-28 space-16">{ this.state.proximaVerificacao }</div>
                        <div className="box-bt-verificacao">
                            <div className="bt-verificacao" onClick={() => this._executarVerificacao()}>Realizar verificação &nbsp;&nbsp;<i className="fas fa-chevron-right icon-14"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row margin-10 space-30">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 texto-18 texto-bold">Histórico de verificação</div>

                    <div className="row margin-10 box-historico-title space-32">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 texto-16 color-deep-lilac">Enviado em</div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 texto-16 color-deep-lilac">App</div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 texto-16 color-deep-lilac">Viagens</div>
                    </div>
                    {
                        this.state.historico.map((item, k) => {
                            return <div className="row margin-10 box-historico space-8" key={ k }>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 texto-16">{ formatDate('dd/MM - hh:mm', new Date(item.created_at)) }</div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 texto-16">{ item.aplicativo.descricao }</div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 texto-16">{ item.viagens_realizadas }</div>
                                </div>
                        })
                    }
                </div>

                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Historico);
