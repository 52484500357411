import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Passo4 extends Component {
    render() {
        return (
            <div>
                <div className="cf-texto cf-sub-title">Mais vantagens para você</div>
                <div className="cf-texto cf-body">Entretenimento, segurança, mais qualidade no serviço e muitos outros benefícios com a TV Zanzar.</div>
            </div>
        );
    }
}

export default withRouter(Passo4);
