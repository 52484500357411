import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;

export const obter = async (param) => {
  let response = await axios.get(urlAPI + '/sistema-parametro/obter-por-id/' + param, headers());

  return response.data;
}
