import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import $ from 'jquery';
import * as Logger from '../../../helpers/Logger';
import * as ClientUtil from '../../../helpers/ClientUtil';

const MotoristaAplicativoActions = require('../../../actions/MotoristaAplicativoActions');
const AplicativoActions = require('../../../actions/AplicativoActions');
const BoxHelper = require('../../../helpers/BoxHelper');
const formHelp = require('../../../helpers/FormHelpMsg');
const validations = require('../validations');

class Passo12 extends Component {
    constructor(props){
        super();

        this.state = {
            cd_motorista: null,
            meus_aplicativos: [],
            meus_aplicativos_editar: [],
            aplicativos: []
        };

        this.campos = [];
        this.motorista_logado = null;
    }

    componentDidMount() {
        BoxHelper.boxFormSetup();

        let motorista_logado = localStorage.getItem('motorista_logado');

        if (typeof motorista_logado !== 'undefined' && motorista_logado !== null) {
            this.motorista_logado = JSON.parse(motorista_logado);

            let dados = this.state;
            let zanzar_aplicativos = localStorage.getItem('zanzar_aplicativos');

            dados.cd_motorista = this.motorista_logado.cd_motorista;

            if (typeof zanzar_aplicativos !== 'undefined' && zanzar_aplicativos !== null)
                dados.aplicativos = JSON.parse(zanzar_aplicativos);
            else {
                AplicativoActions.listarAplicativos()
                    .then(res => {
                        dados.aplicativos = res;

                        localStorage.setItem('zanzar_aplicativos', JSON.stringify(res));

                        this.setState(dados, this._setState);
                    });
            }

            let editar_meus_aplicativos = localStorage.getItem('editar_meus_aplicativos');

            if (typeof editar_meus_aplicativos !== 'undefined' && editar_meus_aplicativos !== null) {
                dados.meus_aplicativos_editar = JSON.parse(editar_meus_aplicativos);

                dados.meus_aplicativos_editar.forEach(item => {
                    dados.meus_aplicativos.push(item.cd_aplicativo);
                    dados['nota_' + item.cd_aplicativo] = item.nota_motorista;
                });
            }

            this.setState(dados, this._setState);
        } else {
            window.location = '/login';
        }
    }

    _setState = () => {
        BoxHelper.boxFormSetup();

        if (this.state.aplicativos.length > 0 && this.state.meus_aplicativos_editar.length > 0) {
            let appEdicaoIndiponivel = [];

            this.state.meus_aplicativos_editar.forEach(item => {
                if (!item.edicao_disponivel) {
                    appEdicaoIndiponivel.push(item.cd_aplicativo);
                }
            });

            let aplicativos = this.state.aplicativos.filter((item) => { return !appEdicaoIndiponivel.includes(item.cd_aplicativo) });
            this.setState({ aplicativos: aplicativos });
        }
    }

    _gravar = async () => {
        $('.loader-main').removeClass('loader-inactive');

        let formOk = true;

        BoxHelper.boxFormSetup();

        this.campos.forEach((item) => {
            $('#'+ item).parent().trigger('click');
            $('#'+ item).trigger('click');

            if ($('#'+ item).val() === '')
                formOk = false;
            
            validations.validarCampoPreenchido($('#'+ item).val(), item);
        });

        if (formOk){
            let deviceinfo = await ClientUtil.getDeviceInfo();
            Logger.info('ALTERAR MEUS APPS : INICIO');
            Logger.info('ALTERAR MEUS APPS : APARELHO : ' + deviceinfo);

            let cd_motorista = this.state.cd_motorista;
            let aplicativos = this.state.meus_aplicativos_editar;

            MotoristaAplicativoActions.alterarMotoristaAplicativo({
                    cd_motorista,
                    aplicativos
                })
                .then(res => {
                    localStorage.removeItem('editar_meus_aplicativos');
                    localStorage.removeItem('meus_aplicativos');

                    $('.loader-main').addClass('loader-inactive');

                    Logger.info('ALTERAR MEUS APPS : FIM');

                    let cadastro_meus_aplicativos = [];

                    this.state.meus_aplicativos_editar.forEach(item => {
                        if (item.edicao_disponivel) {
                            cadastro_meus_aplicativos.push(item.cd_aplicativo);
                        }
                    });

                    localStorage.setItem('cadastro_meus_aplicativos', JSON.stringify(cadastro_meus_aplicativos));

                    this.props.history.push('/cadastro/passo-14');
                })
                .catch(error => {
                    console.log(error.message);

                    let errorMsg = (error.response && error.response.data) ? error.response.data.error : error.message;

                    this.setState({ error: errorMsg });
                    Logger.error('ALTERAR MEUS APPS : ' + errorMsg);

                    $('.loader-main').addClass('loader-inactive');
                });
        } else {
            $('.loader-main').addClass('loader-inactive');
        }
    }

    _validarCampo = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;

        formHelp.hideHelp(campo, 'box-input-required');

        validations.validarCampoPreenchido(valor, campo);
    }

    _updateField = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;
        let dados = this.state;

        let cd_aplicativo = campo.slice(5);

        dados.meus_aplicativos_editar.forEach((item) => {
            if (parseInt(item.cd_aplicativo) === parseInt(cd_aplicativo)) {
                item.nota_motorista = valor;
            }
        });

        dados[campo] = valor;

        await this.setState(dados);
    }

    _maskDelete = (e) => {
        const cursorPos = Number(e.target.selectionStart);
        const keyCode = Number(e.keyCode);

        if (keyCode === 8) {
            e.preventDefault();
            e.stopPropagation();

            e.target.value = e.target.value.substring(0, cursorPos - 1);
            e.target.setSelectionRange(cursorPos, cursorPos);

            this._updateField(e);
        }
    }

    _getLabelClass = (campo) => {
        return this.state[campo] === undefined || this.state[campo] === '' ? 'input-label2' : 'input-label-sm2';
    }

    render() {
        return (
            <div className="page-content header-clear cadastro">
                <div className="title title-36">Notas</div>

                <div className="cf-texto div-body margin-30 space-26">Qual a sua nota atual?</div>

                <div className="div-separator">
                    <div className="div-progress" style={{ width: '72%' }}>
                        <div className="div-progress-bullet bg-purple"></div>
                    </div>
                    <div className="div-progress-bullet"></div>
                </div>

                <div className="texto-20 margin-25 space-16">Digite sua nota:</div>

                {
                    this.state.aplicativos.map((item, k) => {
                        if (this.state.meus_aplicativos.includes(item.cd_aplicativo)) {
                            let id_campo = 'nota_' + item.cd_aplicativo;
                            let id_container = id_campo + 'InputContainer';

                            this.campos.push(id_campo);

                            return <div className="box-input-only2 margin-25 space-12" id={ id_container } key={ k }>
                                <MaskedInput
                                    mask={ [/\d/,'.',/\d/,/\d/] }
                                    className="box-input-only input2"
                                    guide={ false }
                                    keepCharPositions={ false }
                                    id={ id_campo }
                                    value={ this.state[id_campo] }
                                    onChange={ e => { this._updateField(e) } }
                                    onBlur={ e => { this._validarCampo(e) } }
                                    onKeyDown= { e => { this._maskDelete(e) } } />
                                <label className={ this._getLabelClass(id_campo) } htmlFor={ id_campo }>Nota { item.descricao }</label>
                                <span className="help-inline"></span>
                            </div>
                        } else {
                            return ''
                        }
                    })
                }

                <div className="space-16"></div>
                <div className="alert alert-danger text-center margin-25" style={ this.state.error ? { padding: 6 } : { display: 'none' } }>
                    { this.state.error }
                </div>

                <div className="space-34"></div>
                <div className="div-center div-bottom">
                    <button className="bt-login margin-30" onClick={() => this._gravar()}>CONTINUAR <i className="fas fa-chevron-right icon-14 float-right"></i></button>
                </div>
                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Passo12);
