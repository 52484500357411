import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;

export const registrarOcorrencia = ({ cd_motorista, cd_motivo, descricao }) => {
  return axios.post(
    urlAPI + '/ocorrencia',
    {
      cd_motorista,
      cd_motivo,
      descricao,
      idc_status: 1
     },
    headers()
  );
}

export const listarMotivos = async () => {
  let response = await axios.get(urlAPI + '/ocorrencia-motivo/listar-ativos/1', headers());

  return response.data;
}
