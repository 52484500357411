import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

const AplicativoActions = require('../../../actions/AplicativoActions');
const BoxHelper = require('../../../helpers/BoxHelper');
const formHelp = require('../../../helpers/FormHelpMsg');
const validations = require('../validations');

class Passo10 extends Component {
    constructor(props){
        super();
        
        this.state = {
            meus_aplicativos: [],
            meus_aplicativos_editar: [],
            aplicativos: []
        };

        this.campos = [];
        this.urlProximoPasso = '/meus-aplicativos/passo-5';
    }

    componentDidMount() {
        BoxHelper.boxFormSetup();

        let dados = this.state;
        let zanzar_aplicativos = localStorage.getItem('zanzar_aplicativos');

        if (typeof zanzar_aplicativos !== 'undefined' && zanzar_aplicativos !== null) {
            dados.aplicativos = JSON.parse(zanzar_aplicativos);
        } else {
            AplicativoActions.listarAplicativos()
                .then(res => {
                    dados.aplicativos = res;

                    localStorage.setItem('zanzar_aplicativos', JSON.stringify(res));

                    this.setState(dados, this._setState);
                });
        }

        let editar_meus_aplicativos = localStorage.getItem('editar_meus_aplicativos');

        if (typeof editar_meus_aplicativos !== 'undefined' && editar_meus_aplicativos !== null) {
            dados.meus_aplicativos_editar = JSON.parse(editar_meus_aplicativos);

            dados.meus_aplicativos_editar.forEach(item => {
                dados.meus_aplicativos.push(item.cd_aplicativo);
                dados['categoria_' + item.cd_aplicativo] = item.cd_aplicativo_categoria;
            });
        }

        this.setState(dados, this._setState);
    }

    _setState = () => {
        BoxHelper.boxFormSetup();

        if (this.state.aplicativos.length > 0 && this.state.meus_aplicativos_editar.length > 0) {
            let appEdicaoIndiponivel = [];

            this.state.meus_aplicativos_editar.forEach(item => {
                if (!item.edicao_disponivel) {
                    appEdicaoIndiponivel.push(item.cd_aplicativo);
                }
            });

            let aplicativos = this.state.aplicativos.filter((item) => { return !appEdicaoIndiponivel.includes(item.cd_aplicativo) });
            this.setState({ aplicativos: aplicativos });
        }

        let appsComSegmento = this.state.aplicativos.filter((item) => {
            return this.state.meus_aplicativos.includes(item.cd_aplicativo) && item.idc_possui_segmentos === 1;
        });

        if (appsComSegmento === null || appsComSegmento.length === 0) {
            this.urlProximoPasso = '/meus-aplicativos/passo-6';
        }
    }

    _gravar = () => {
        let formOk = true;

        BoxHelper.boxFormSetup();

        this.campos.forEach((item) => {
            $('#'+ item).parent().trigger('click');
            $('#'+ item).trigger('click');

            if ($('#'+ item).val() === '')
                formOk = false;
            
            validations.validarCampoPreenchido($('#'+ item).val(), item);
        });

        if (formOk){
            localStorage.setItem('editar_meus_aplicativos', JSON.stringify(this.state.meus_aplicativos_editar));

            this.props.history.push(this.urlProximoPasso);
        }
    }

    _validarCampo = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;

        formHelp.hideHelp(campo, 'box-input-required');

        validations.validarCampoPreenchido(valor, campo);
    }

    _updateField = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;
        let dados = this.state;

        let cd_aplicativo = campo.slice(10);

        dados.meus_aplicativos_editar.forEach((item) => {
            if (parseInt(item.cd_aplicativo) === parseInt(cd_aplicativo)) {
                item.cd_aplicativo_categoria = valor;
            }
        });

        dados[campo] = valor;

        await this.setState(dados);
    }

    render() {
        return (
            <div className="page-content header-clear cadastro">
                <div className="title title-36">Categoria</div>

                <div className="cf-texto div-body margin-30 space-26">Qual sua categoria?</div>

                <div className="div-separator">
                    <div className="div-progress" style={{ width: '63%' }}>
                        <div className="div-progress-bullet bg-purple"></div>
                    </div>
                    <div className="div-progress-bullet"></div>
                </div>

                <div className="texto-20 margin-25 space-16">Selecione a categoria em que você se encaixa:</div>

                {
                    this.state.aplicativos.map((item, k) => {
                        if (this.state.meus_aplicativos.includes(item.cd_aplicativo)) {
                            let id_campo = 'categoria_' + item.cd_aplicativo;
                            let id_container = id_campo + 'InputContainer';

                            this.campos.push(id_campo);

                            return <div className="box-input-only2 margin-25 space-16" id={ id_container } key={ k }>
                                <select type="text" id={ id_campo } onChange={ e => { this._updateField(e) } } onBlur={ e => { this._validarCampo(e) } } value={ this.state[id_campo] } className="box-input-only input2">
                                    <option value=""></option>
                                {
                                    item.categorias.map((categoria, k1) => {
                                        if (categoria.idc_status === 1)
                                            return <option key={ k1 } value={ categoria.cd_aplicativo_categoria }>{ categoria.descricao }</option>
                                        else
                                            return ''
                                    })
                                }
                                </select>
                                <label className={ this.state[id_campo] === undefined || this.state[id_campo] === '' ? 'input-label2' : 'input-label-sm2' } htmlFor={ id_campo }>Categorias { item.descricao }</label>
                                <span className="help-inline" style={ this.state.error ? { color: 'red', padding: '10px' } : { display: 'none' } }>{ this.state.error }</span>
                            </div>
                        } else {
                            return ''
                        }
                    })
                }

                <div className="space-34"></div>
                <div className="div-center div-bottom">
                    <button className="bt-login margin-30" onClick={() => this._gravar()}>CONTINUAR <i className="fas fa-chevron-right icon-14 float-right"></i></button>
                </div>
                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Passo10);
