import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const AplicativoActions = require('../../../actions/AplicativoActions');
const MotoristaAplicativoActions = require('../../../actions/MotoristaAplicativoActions');
const BoxHelper = require('../../../helpers/BoxHelper');

class Passo7 extends Component {
    constructor(props){
        super();

        this.state = {
            cd_motorista: null,
            meus_aplicativos: [],
            meus_aplicativos_editar: [],
            aplicativos: []
        };
    }

    componentDidMount() {
        BoxHelper.boxFormSetup();

        let motorista_logado = localStorage.getItem('motorista_logado');

        if (typeof motorista_logado !== 'undefined' && motorista_logado !== null) {
            motorista_logado = JSON.parse(motorista_logado);

            let zanzar_aplicativos = localStorage.getItem('zanzar_aplicativos');

            if (typeof zanzar_aplicativos !== 'undefined' && zanzar_aplicativos !== null) {
                this.setState({ cd_motorista: motorista_logado.cd_motorista, aplicativos: JSON.parse(zanzar_aplicativos) })
            } else {
                AplicativoActions.listarAplicativos()
                    .then(res => {
                        this.setState({ cd_motorista: motorista_logado.cd_motorista, aplicativos: res }, this._setState);
                        localStorage.setItem('zanzar_aplicativos', JSON.stringify(res));
                    });
            }

            let editar_meus_aplicativos = localStorage.getItem('editar_meus_aplicativos');
    
            if (typeof editar_meus_aplicativos !== 'undefined' && editar_meus_aplicativos !== null) {
                let meus_aplicativos = localStorage.getItem('meus_aplicativos');
                if (typeof meus_aplicativos === 'undefined' || meus_aplicativos === null) {
                    meus_aplicativos = editar_meus_aplicativos;
                }

                this.setState({ meus_aplicativos: JSON.parse(meus_aplicativos), meus_aplicativos_editar: JSON.parse(editar_meus_aplicativos) }, this._setState);
            } else {
                MotoristaAplicativoActions.listarPorMotoristaParaEdicao(motorista_logado.cd_motorista)
                    .then(res => {
                        this.setState({ meus_aplicativos: res, meus_aplicativos_editar: res }, this._setState);
                    });
            }
        } else {
            window.location = '/login';
        }
    }

    _setState = () => {
        if (this.state.aplicativos.length > 0 && this.state.meus_aplicativos_editar.length > 0) {
            let appEdicaoIndiponivel = [];

            this.state.meus_aplicativos_editar.forEach(item => {
                if (!item.edicao_disponivel) {
                    appEdicaoIndiponivel.push(item.cd_aplicativo);
                }
            });

            let aplicativos = this.state.aplicativos.filter((item) => { return !appEdicaoIndiponivel.includes(item.cd_aplicativo) });
            this.setState({ aplicativos: aplicativos });
        }
    }

    _gravar = () => {
        if (this.state.meus_aplicativos_editar.length === 0) {
            let dados = this.state;
            dados.error = 'Selecione um ou mais aplicativos p/ continuar.';

            this.setState(dados);
        } else {
            localStorage.setItem('editar_meus_aplicativos', JSON.stringify(this.state.meus_aplicativos_editar));
            localStorage.setItem('meus_aplicativos', JSON.stringify(this.state.meus_aplicativos));

            this.props.history.push('/meus-aplicativos/passo-2');
        }
    }

    _updateField = (event) => {
        let dados = this.state;

        if (event.target.type === 'checkbox') {
            let valor = parseInt(event.target.value);

            if (event.target.checked) {
                let appSelected = dados.meus_aplicativos.filter((item) => {
                    return parseInt(valor) === item.cd_aplicativo;
                });

                if (appSelected !== null && appSelected.length > 0 && !dados.meus_aplicativos_editar.includes(appSelected[0])) {
                    dados.meus_aplicativos_editar.push(appSelected[0]);
                } else {
                    dados.meus_aplicativos_editar.push({
                        cd_motorista: this.state.cd_motorista,
                        cd_aplicativo: valor,
                        cd_aplicativo_categoria: null,
                        cd_aplicativo_segmento: null,
                        viagens_realizadas: null,
                        nota_motorista: null,
                        tempo_aplicativo: null,
                        idc_imgem_perfil: 1,
                        idc_status: 1
                    });
                }
            } else {
                dados.meus_aplicativos_editar = dados.meus_aplicativos_editar.filter((item) => {
                    return parseInt(valor) !== item.cd_aplicativo;
                });
            }
        } else {
            let campo = event.target.id;
            let valor = event.target.value;

            dados.meus_aplicativos_editar[campo] = valor;
        }

        this.setState(dados);
    }

    _isSelected = (cod) => {
        return this.state.meus_aplicativos_editar.filter((item) => { return item.cd_aplicativo === cod; }).length > 0;
    }

    render() {
        return (
            <div className="page-content header-clear cadastro">
                <div className="title title-36">Aplicativos</div>

                <div className="cf-texto div-body margin-30 space-26">Em quais apps você trabalha?</div>

                <div className="div-separator">
                    <div className="div-progress" style={{ width: '36%' }}>
                        <div className="div-progress-bullet bg-purple"></div>
                    </div>
                    <div className="div-progress-bullet"></div>
                </div>

                <div className="texto-20 margin-25 space-16">Selecione todos os apps que você trabalha:</div>

                {
                    this.state.aplicativos.map((item, k) => {
                        let id = 'app_' + item.cd_aplicativo;
                        return <div className="checkbox-nice margin-25 space-8" key={ k }>          
                                <input className="checkbox-nice" type="checkbox" name={ id } id={ id } value={ item.cd_aplicativo } onChange={ e => { this._updateField(e) } } checked={ this._isSelected(item.cd_aplicativo) } />
                                <label className="texto-20" htmlFor={ id }>{ item.descricao }</label>
                            </div>
                    })
                }

                <div className="div-center help-inline space-16" style={ this.state.error ? { color: 'red', padding: '10px' } : { display: 'none' } }>{ this.state.error }</div>

                <div className="space-71"></div>
                <div className="div-center div-bottom">
                    <button className="bt-login margin-30" onClick={() => this._gravar()}>CONTINUAR <i className="fas fa-chevron-right icon-14 float-right"></i></button>
                </div>
                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Passo7);
