import { validate as isEmail } from "email-validator";
import $ from "jquery";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from "react-router-dom";

import config from '../../../config/config';
import { loginMotorista } from "../../../actions/MotoristaActions";

class FormLogin extends Component {
  constructor(props) {
    super();

    this.state = {
      email: "",
      senha: "",
      captcha: false,
      valorCaptcha: "",
      codigoCapctha: config.RECAPTCHA,
      tipoSistema: config.TIPO_SISTEMA,
      errorMessage: null,
    };

    this.camposObrigatorios = ["email", "senha"];
  }

  recaptchaRef = React.createRef();

  onChange = async () => {
    const recaptchaValue = await this.recaptchaRef.current.getValue();

    this.setState({
      captcha: true,
      valorCaptcha: recaptchaValue,
    });
  };

  componentDidMount() {
    $(".captchaMostrar").hide();

    if (this.state.tipoSistema === "production") {
      $(".captchaMostrar").show();
    }

    $(".box-input").on('click', () => {
      var label = $(this).find(".input-label");
      var input = $("#" + label.attr("for"));

      label.removeClass("input-label");
      label.addClass("input-label-sm");
      input.removeClass("input-hide");

      $(".box-input-sel").removeClass("box-input-sel");
      $(this).addClass("box-input-sel");

      input.trigger("click");
    });

    localStorage.removeItem("editar_meus_aplicativos");
    localStorage.removeItem("meus_aplicativos");
    localStorage.removeItem('zanzar_ponto_escolhido');
    localStorage.removeItem('agendamento_datas_disponiveis');
    localStorage.removeItem('agendamento_pontos_disponiveis');
  }

  _logar = async () => {
    this.setState({ errorMessage: null });

    let formOk = true;

    this.camposObrigatorios.forEach(function (item) {
      if ($("#" + item).val() === "") {
        this.setState({ errorMessage: 'Todos os campos são obrigatórios.' });
        formOk = false;
      }
    });

    if ($("#email").val() !== "" && !isEmail($("#email").val())) {
      this.setState({ errorMessage: 'Informe um e-mail válido.' });
      formOk = false;
    }

    if (this.state.tipoSistema === "production" && !this.state.captcha) {
      this.setState({ errorMessage: 'Todos os campos são obrigatórios.' });
      formOk = false;
    }

    if (formOk) {
      let { email, senha, captcha, valorCaptcha, tipoSistema } = this.state;
      $(".loader-main").removeClass("loader-inactive");

      loginMotorista({
        email,
        senha,
        captcha,
        valorCaptcha,
        tipoSistema,
      });
    }
  };

  _updateField = async (event) => {
    let dados = this.state;
    dados[event.target.id] = event.target.value.trim();
    await this.setState(dados);
  };

  _updateFieldEmail = async (event) => {
    if (/^[^ ,;:<>()[\]\\|/]*$/.test(event.target.value.trim())) {
      this._updateField(event);
    }
  };

  _esqueciMinhaSenha = async () => {
    this.props.history.push("/esqueci-senha");
  };

  render() {
    return (
      <div className="page-content header-clear">
        <div className="title title-36">LOGIN</div>
        <div className="space-32"></div>
        <div className="cf-texto div-body margin-30">
          Insira seus dados para entrar
        </div>
        <div className="space-71"></div>
        <div
          className="alert alert-danger margin-25"
          style={ this.state.errorMessage ? { padding: "6px" } : { display: "none" } }
        >
          { this.state.errorMessage }
        </div>
        <div
          className="alert alert-danger margin-25 login-invalido"
          style={{ padding: "6px", display: "none" }}
        >
          Login ou senha inválidos.
        </div>
        <div className="box-input-only2 margin-25">
          <input
            type="text"
            id="email"
            className="box-input-only input2"
            value={this.state.email}
            onChange={(e) => {
              this._updateFieldEmail(e);
            }}
          />
          <label
            className={
              this.state.email === "" ? "input-label2" : "input-label-sm2"
            }
            htmlFor="email"
          >
            E-mail
          </label>
        </div>
        <div className="box-input-only2 margin-25 space-12">
          <input
            type="password"
            id="senha"
            className="box-input-only input2"
            value={this.state.senha}
            onChange={(e) => {
              this._updateField(e);
            }}
          />
          <label
            className={
              this.state.email === "" ? "input-label2" : "input-label-sm2"
            }
            htmlFor="senha"
          >
            Senha
          </label>
        </div>
        <div className="captchaMostrar">
          <div className="box-input-only2 margin-25 space-12">
            <ReCAPTCHA
              id="captcha"
              ref={this.recaptchaRef}
              value={this.state.captcha}
              sitekey={this.state.codigoCapctha}
              onChange={(e) => {
                this.onChange(e);
              }}
            />
          </div>
        </div>
        <div className="space-12"></div>
        <div className="cf-texto div-body margin-27">
          <a href="#/" onClick={() => this._esqueciMinhaSenha()}>
            ESQUECI MINHA SENHA &nbsp;
            <i className="fas fa-chevron-right icon-14"></i>
          </a>
        </div>
        <div className="space-26"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._logar()}>
            ENTRAR <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(FormLogin);
