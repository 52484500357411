import isValidCpf from "@brazilian-utils/is-valid-cpf";
import isValidPhone from "@brazilian-utils/is-valid-phone";
import { validate as isEmail } from "email-validator";

const Actions = require("../../../actions/MotoristaActions");
const formHelp = require("../../../helpers/FormHelpMsg");

export const validarCampoNome = (nome) => {
  if (nome.trim() === "") {
    formHelp.showHelp(
      "nome",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  let regex = /[a-z]\s[a-z]/gim;
  if (!regex.test(nome)) {
    formHelp.showHelp(
      "nome",
      "Informe nome e sobrenome.",
      "box-input-required"
    );
    return false;
  } else {
    let separado = nome.split(" ");
    if (separado[0].length < 2) {
      formHelp.showHelp(
        "nome",
        "Primeiro Nome deve conter ao menos 2 caracteres.",
        "box-input-required"
      );
      return false;
    }
    if (separado[1].length < 2) {
      formHelp.showHelp(
        "nome",
        "Sobrenome deve conter ao menos 2 caracteres.",
        "box-input-required"
      );
      return false;
    }
  }

  return true;
};

export const validarCampoEmail = async (email, cd_motorista) => {
  if (email === "") {
    formHelp.showHelp(
      "email",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (!isEmail(email)) {
    formHelp.showHelp("email", "E-mail inválido.", "box-input-required");
    return false;
  }
  let emUso = await Actions.verificarEmailEmUso(email, cd_motorista);
  if (emUso) {
    formHelp.showHelp(
      "email",
      "E-mail já em uso por outro usuário.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoCPF = async (cpf, cd_motorista) => {
  if (cpf === "") {
    formHelp.showHelp(
      "cpf",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (!isValidCpf(cpf)) {
    formHelp.showHelp("cpf", "CPF inválido.", "box-input-required");
    return false;
  }
  let emUso = await Actions.verificarCpfEmUso(cpf, cd_motorista);
  if (emUso) {
    formHelp.showHelp(
      "cpf",
      "CPF já em uso por outro usuário.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoTelefone = (telefone) => {
  if (telefone === "") {
    formHelp.showHelp(
      "telefone",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (!isValidPhone(telefone)) {
    formHelp.showHelp("telefone", "Telefone inválido.", "box-input-required");
    return false;
  }
  return true;
};
