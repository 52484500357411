import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';

import * as AgendamentoActions from '../../../actions/AgendamentoActions';

class EscolherPonto extends Component {
  constructor(props){
    super(props);

    this.reagendar = props.reagendar;

    this.state = {
      cd_motorista: null,
      pontoEscolhido: null,
      pontoEscolhidoCodigo: null,
      pontos: [],
      error: '',
      consultaExecutada: false
    };
  }

  componentDidMount = () => {
    $('.loader-main').removeClass('loader-inactive');

    let motorista_logado = localStorage.getItem('motorista_logado');

    if (typeof motorista_logado !== 'undefined' && motorista_logado !== null){
      motorista_logado = JSON.parse(motorista_logado);

      this.setState({ cd_motorista: motorista_logado.cd_motorista });

      let agendamento_pontos_disponiveis = localStorage.getItem('agendamento_pontos_disponiveis');

      if (typeof agendamento_pontos_disponiveis !== 'undefined' && agendamento_pontos_disponiveis !== null) {
        agendamento_pontos_disponiveis = JSON.parse(agendamento_pontos_disponiveis);

        if (agendamento_pontos_disponiveis.dt_validade && moment.utc(agendamento_pontos_disponiveis.dt_validade) > moment.utc()) {
          this.storeState(agendamento_pontos_disponiveis.pontos);

          $('.loader-main').addClass('loader-inactive');
        } else {
          this.obterPontosDisponiveis(motorista_logado.cd_motorista);
        }
      } else {
        this.obterPontosDisponiveis(motorista_logado.cd_motorista);
      }
    } else {
      this.props.history.push('/login');
    }
  }

  obterPontosDisponiveis = (cd_motorista) => {
    AgendamentoActions.obterPontosDisponiveis(cd_motorista)
      .then(pontos => {
        let agendamento_pontos_disponiveis = {
          pontos,
          dt_validade: moment.utc().add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        };

        localStorage.setItem('agendamento_pontos_disponiveis', JSON.stringify(agendamento_pontos_disponiveis));

        this.storeState(pontos);

        $('.loader-main').addClass('loader-inactive');
      })
      .catch((error) => {
        this.storeState(null);

        $('.loader-main').addClass('loader-inactive');
      });
  }

  storeState = (pontos) => {
    if (pontos) {
      this.setState({
        pontos,
        consultaExecutada: true,
      });
    } else {
      this.setState({
        pontos: [],
        pontoEscolhido: null,
        pontoEscolhidoCodigo: null,
        consultaExecutada: true,
        error: 'Nenhum ponto de instalação disponível.',
      });
    }
  }

  _selecionar = (ponto) => {
    this.setState({
      pontoEscolhido: ponto,
      pontoEscolhidoCodigo: ponto.cd_ponto_apoio,
    });
  }

  _confirmar = () => {
    if (this.state.pontoEscolhido === null) {
      this.setState({ error: 'Selecione uma ponto de instalação.' });
    } else {
      localStorage.setItem('zanzar_ponto_escolhido', JSON.stringify(this.state.pontoEscolhido));

      this.props.history.push('/agendamento/escolher-dia' + (this.reagendar ? '/reagendar' : ''));
    }
  }

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-32 text-center text-destaque">Ponto de instalação</div>

        <div className="space-16"></div>
        <div className="cf-texto div-body margin-30 text-center">Selecione a oficina que melhor te atende. Listamos para você as mais próximas:</div>

        <div className="space-26"></div>
        <div className="row margin-22">
          {
            this.state.consultaExecutada ?
              this.state.pontos ? this.state.pontos.map((item, k) => {
                return <div key={ k }
                      onClick={() => this._selecionar(item)}
                      style={{ height: '100%' }}
                      className={ 'col-lg-12 col-md-12 col-sm-12 col-xs-12 box-ponto-inst' + (this.state.pontoEscolhidoCodigo === item.cd_ponto_apoio ? ' box-date-selected' : '') }>
                      <div className="titulo">
                        { item.descricao }
                        <i className="fas fa-map-marker-alt icon-12 float-right" aria-hidden="true"> <span className="km">{ (item.distancia / 1000).toFixed(1) }km</span></i>
                      </div>
                      <div className="endereco">{ item.endereco }</div>
                    </div>
              }) : <div style={{ color: 'red' }}>Nenhum ponto de instalação disponível.</div>
            : ''
          }
        </div>

        <div className="div-center" style={ this.state.pontos.length > 0 ? {} : { display: 'none' } }>
          <button className="bt-white margin-22">Veja todas as oficinas credenciadas <i className="fas fa-chevron-right icon-14 float-right"></i></button>
        </div>

        <div className="space-8"></div>
        <div className="div-div-center" style={ this.state.error ? { color: 'red', padding: '6px' } : { display: 'none' } }>
          { this.state.error }
        </div>
        <div className="space-7" style={ this.state.error ? {} : { display: 'none' } }></div>
        <div className="space-50" style={ !this.state.error ? {} : { display: 'none' } }></div>

        <div className="div-center" style={ this.state.pontos.length > 0 ? {} : { display: 'none' } }>
          <button className="cf-bt-cadastre margin-40" onClick={() => this._confirmar()} style={{ width: '278px' }}>CONFIRMAR <i className="fas fa-chevron-right icon-14 float-right"></i></button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(EscolherPonto);
