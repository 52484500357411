import { validate as isEmail } from "email-validator";
import isValidCpf from "@brazilian-utils/is-valid-cpf";
import isValidPhone from "@brazilian-utils/is-valid-phone";
import isValidCep from "@brazilian-utils/is-valid-cep";

const Actions = require("../../../actions/MotoristaActions");
const formHelp = require("../../../helpers/FormHelpMsg");

export const validarCampoNome = (nome) => {
  if (nome.trim() === "") {
    formHelp.showHelp(
      "nome",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  let regex = /[a-z]\s[a-z]/gim;
  if (!regex.test(nome)) {
    formHelp.showHelp(
      "nome",
      "Informe nome e sobrenome.",
      "box-input-required"
    );
    return false;
  } else {
    let separado = nome.split(" ");
    if (separado[0].length < 2) {
      formHelp.showHelp(
        "nome",
        "Primeiro Nome deve conter ao menos 2 caracteres.",
        "box-input-required"
      );
      return false;
    }
    if (separado[1].length < 2) {
      formHelp.showHelp(
        "nome",
        "Sobrenome deve conter ao menos 2 caracteres.",
        "box-input-required"
      );
      return false;
    }
  }
  return true;
};

export const validarCampoEmail = async (email) => {
  if (email === "") {
    formHelp.showHelp(
      "email",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (!isEmail(email)) {
    formHelp.showHelp("email", "E-mail inválido.", "box-input-required");
    return false;
  }
  let emUso = await Actions.verificarEmailEmUso(email);
  if (emUso) {
    formHelp.showHelp(
      "email",
      "E-mail já em uso por outro usuário.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoCPF = async (cpf) => {
  if (cpf === "") {
    formHelp.showHelp(
      "cpf",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (!isValidCpf(cpf)) {
    formHelp.showHelp("cpf", "CPF inválido.", "box-input-required");
    return false;
  }
  let emUso = await Actions.verificarCpfEmUso(cpf);
  if (emUso) {
    formHelp.showHelp(
      "cpf",
      "CPF já em uso por outro usuário.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoTelefone = (telefone) => {
  if (telefone === "") {
    formHelp.showHelp(
      "telefone",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (!isValidPhone(telefone)) {
    formHelp.showHelp("telefone", "Telefone inválido.", "box-input-required");
    return false;
  }
  return true;
};

export const validarCampoCEP = (cep, cepNaoEncontrado) => {
  if (cep === "") {
    formHelp.showHelp(
      "cep",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  Actions.pesquisarCep(cep).then(res =>{
    if(res.uf !== "SP" && res.uf !== "MG"){
      formHelp.showHelp("cep", "Nesse momento só estamos fazendo novas instalações para os estados de SP e MG.", "box-input-required");
    }
  })
  if (!isValidCep(cep)) {
    formHelp.showHelp("cep", "CEP inválido.", "box-input-required");
    return false;
  }
  if (cepNaoEncontrado) {
    formHelp.showHelp("cep", "CEP nao encontrado", "box-input-required");
    return false;
  }
  return true;
};

export const validarCampoLogradouro = (logradouro) => {
  if (logradouro === "") {
    formHelp.showHelp(
      "logradouro",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoNumero = (numero) => {
  if (numero === "") {
    formHelp.showHelp(
      "numero",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoSenha = (senha) => {
  if (senha === "") {
    formHelp.showHelp(
      "senha",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }

  if (senha.length < 8) {
    formHelp.showHelp(
      "senha",
      "Nome deve conter ao menos 8 caracteres.",
      "box-input-required"
    );
    return false;
  }

  let strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"
  );

  if (!strongRegex.test(senha)) {
    formHelp.showHelp('senha', 'A sua senha deve ter no mínimo 8 caracteres, sendo que deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número.', 'box-input-required');
    return false;
  }

  return true;
};

export const validarCampoConfirmaSenha = (senha, confirmaSenha) => {
  if (confirmaSenha === "") {
    formHelp.showHelp(
      "senha_confirma",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (senha !== confirmaSenha) {
    formHelp.showHelp(
      "senha_confirma",
      "Senha e confirmação de senha devem ser iguais.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoVeiculoFabricante = (valor) => {
  if (valor === "") {
    formHelp.showHelp(
      "veiculo_fabricante",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoVeiculoModelo = (valor) => {
  if (valor === "") {
    formHelp.showHelp(
      "veiculo_modelo",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoVeiculoAno = (valor) => {
  if (valor === "") {
    formHelp.showHelp(
      "veiculo_ano",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};

export const validarCampoPreenchido = (valor, campo) => {
  if (valor === "") {
    formHelp.showHelp(
      campo,
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  return true;
};
