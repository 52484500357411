import winston from 'winston';
import { Loggly } from 'winston-loggly-bulk';

import * as ClientUtil from './ClientUtil';
import config from '../config/config';

winston.add(
  new Loggly({
    token: config.LOGGLY_TOKEN,
    subdomain: config.LOGGLY_SUB_DOMAIN,
    tags: config.LOGGLY_TAGS,
    json: true,
  })
);

export const info = (msg) => {
  winston.info(msg, { tags: ClientUtil.getClientKey() });
};

export const error = (msg) => {
  winston.error(msg, { tags: ClientUtil.getClientKey() });
};

export const warn = (msg) => {
  winston.warn(msg, { tags: ClientUtil.getClientKey() });
};

export const debug = (msg) => {
  winston.debug(msg, { tags: ClientUtil.getClientKey() });
};
