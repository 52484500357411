import React, { Component } from "react";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import $ from "jquery";

import * as AplicativoActions from '../../../actions/AplicativoActions';
import * as BoxHelper from '../../../helpers/BoxHelper';

class Passo14 extends Component {
  constructor(props) {
    super();

    if (props.Menu !== 2) {
      props.setItemState(2);
    }

    this.state = {
      falhas: [],
      meus_uploads: [],
      meus_aplicativos: [],
      aplicativos: [],
      cd_motorista: null,
      redirect: false,
    };

    this.cd_aplicativo = null;
    this.descricao_aplicativo = null;
  }

  componentWillMount() {
    BoxHelper.boxFormSetup();

    let cadastro_cd_motorista = localStorage.getItem("cadastro_cd_motorista");

    if (
      typeof cadastro_cd_motorista !== "undefined" &&
      cadastro_cd_motorista !== null
    ) {
      let dados = this.state;

      dados.cd_motorista = cadastro_cd_motorista;

      let zanzar_aplicativos = localStorage.getItem("zanzar_aplicativos");

      if (
        typeof zanzar_aplicativos !== "undefined" &&
        zanzar_aplicativos !== null
      ) {
        dados.aplicativos = JSON.parse(zanzar_aplicativos);
        let cadastro_meus_aplicativos = localStorage.getItem(
          "cadastro_meus_aplicativos"
        );

        if (
          typeof cadastro_meus_aplicativos !== "undefined" &&
          cadastro_meus_aplicativos !== null
        ) {
          dados.meus_aplicativos = JSON.parse(cadastro_meus_aplicativos).map(
            Number
          );
        }

        let cadastro_meus_uploads = localStorage.getItem(
          "cadastro_meus_uploads"
        );

        if (
          typeof cadastro_meus_uploads !== "undefined" &&
          cadastro_meus_uploads !== null
        ) {
          dados.meus_uploads = JSON.parse(cadastro_meus_uploads);
        }

        let cadastro_meus_uploads_falhas = localStorage.getItem(
          "cadastro_meus_uploads_falhas"
        );

        if (
          typeof cadastro_meus_uploads_falhas !== "undefined" &&
          cadastro_meus_uploads_falhas !== null
        ) {
          dados.falhas = JSON.parse(cadastro_meus_uploads_falhas);
        }

        this.setState(dados);

      } else {
        AplicativoActions.listarAplicativos().then((res) => {
          dados.aplicativos = res;
          localStorage.setItem("zanzar_aplicativos", JSON.stringify(res));
          let cadastro_meus_aplicativos = localStorage.getItem(
            "cadastro_meus_aplicativos"
          );

          if (
            typeof cadastro_meus_aplicativos !== "undefined" &&
            cadastro_meus_aplicativos !== null
          ) {
            dados.meus_aplicativos = JSON.parse(cadastro_meus_aplicativos).map(
              Number
            );
          }

          let cadastro_meus_uploads = localStorage.getItem(
            "cadastro_meus_uploads"
          );

          if (
            typeof cadastro_meus_uploads !== "undefined" &&
            cadastro_meus_uploads !== null
          ) {
            dados.meus_uploads = JSON.parse(cadastro_meus_uploads);
          }

          this.setState(dados);
        });
      }
    } else {
      window.location = "/";
    }
  }

  _gravar = () => {
    let formOk = true;

    this.state.meus_aplicativos.forEach((cd_aplicativo) => {
      if (!this.state.meus_uploads.includes(parseInt(cd_aplicativo))) {
        formOk = false;

        $("#aplicativo" + cd_aplicativo).addClass("has-error");
        $("#aplicativo" + cd_aplicativo)
          .parent()
          .find("span")
          .addClass("help-inline");

        $("#aplicativo" + cd_aplicativo)
          .parent()
          .find("span")
          .html("Está imagem deve ser enviada.");
      }
    });

    if (formOk) {
      this.urlDestino = "/cadastro/sucesso";

      localStorage.removeItem("cadastro_meus_aplicativos");
      localStorage.removeItem("cadastro_meus_uploads");
      localStorage.removeItem("cadastro_meus_uploads_falhas");

      this.setState({ redirect: true });
    }
  };

  _upload = (cd_aplicativo, descricao_aplicativo) => {
    this.urlDestino = "/cadastro/passo-14-2";
    this.cd_aplicativo = cd_aplicativo;
    this.descricao_aplicativo = descricao_aplicativo;
    
    this.setState({ redirect: true });
  };

  _atualizar = () => {
    this.props.history.push("/meus-aplicativos/passo-1");
  };

  render() {
    if (this.state.redirect)
      return (
        <Redirect
          to={{
            pathname: this.urlDestino,
            state: {
              cd_motorista: this.state.cd_motorista,
              cd_aplicativo: this.cd_aplicativo,
              descricao_aplicativo: this.descricao_aplicativo,
            },
          }}
        />
      );
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Perfil de Motorista</div>
        <div className="cf-texto div-body margin-30 space-26">
          Faça uma captura de tela do seu Perfil:
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "81%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        {this.state.aplicativos.map((item, k) => {
          if (this.state.meus_aplicativos.includes(item.cd_aplicativo)) {
            return (
              <div key={k}>
                <button
                  id={"aplicativo" + item.cd_aplicativo}
                  className={
                    "box-input input-label margin-25 space-16 bt-upload-perfil" +
                    (this.state.meus_uploads.includes(item.cd_aplicativo)
                      ? " box-validated"
                      : "") +
                    (this.state.falhas.includes(item.cd_aplicativo)
                      ? " box-falha"
                      : "")
                  }
                  onClick={() =>
                    this._upload(item.cd_aplicativo, item.descricao)
                  }
                >
                  Enviar captura {item.descricao}{" "}
                  <i
                    className={
                      "icon-26 float-right" +
                      (this.state.meus_uploads.includes(item.cd_aplicativo)
                        ? " fas fa-check"
                        : "")
                    }
                  >
                    {this.state.meus_uploads.includes(item.cd_aplicativo)
                      ? ""
                      : "..."}
                  </i>
                </button>
                <br />
                <span className="div-center"></span>
              </div>
            );
          } else {
            return "";
          }
        })}
        <div className="space-40"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            FINALIZAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
          <div className="space-16"></div>
          <button
            className="bt-login margin-30"
            onClick={() => this._atualizar()}
          >
            ATUALIZAR DADOS
          </button>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(Passo14);
