import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;
const servico = '/motorista-complemento/';

export const cadastrarMotoristaComplemento = ({
  cd_motorista,
  cd_veiculo_fabricante,
  veiculo_modelo,
  veiculo_ano,
  veiculo_placa,
}) => {
  return axios.post(
    urlAPI + servico,
    {
      cd_motorista,
      cd_veiculo_fabricante,
      veiculo_modelo,
      veiculo_ano,
      veiculo_placa,
    },
    headers()
  );
};
