import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.css";
import Agendamento from "./scenes/agendamento";
import AtivarEquipamento from "./scenes/ativar-equipamento";
import Cadastro from "./scenes/cadastro";
import CadastroAcompanhamento from "./scenes/cadastro-acompanhamento";
import Callback from "./scenes/callback";
import ComoFunciona from "./scenes/como-funciona";
import ErrorPage from "./scenes/error-page";
import EsqueciSenha from "./scenes/esqueci-senha";
import FaleConosco from "./scenes/fale-conosco";
import Home from "./scenes/home";
import Login from "./scenes/login";
import MeuEndereco from "./scenes/meu-endereco";
import MeuPerfil from "./scenes/meu-perfil";
import MeusAplicativos from "./scenes/meus-aplicativos";
import MeusDados from "./scenes/meus-dados";
import MinhaSenha from "./scenes/minha-senha";
import PrimeiroAcesso from "./scenes/primeiro-acesso";
import RecadastrarSenha from "./scenes/recadastrar-senha";
import TermosServico from "./scenes/termos-servico";
import Verificacao from "./scenes/verificacao";

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route path="/como-funciona" component={ComoFunciona} />
          <Route path="/login" component={Login} />
          <Route path="/esqueci-senha" component={EsqueciSenha} />
          <Route path="/recadastrar-senha" component={RecadastrarSenha} />
          <Route path="/meu-perfil" component={MeuPerfil} />
          <Route path="/fale-conosco" component={FaleConosco} />
          <Route path="/cadastro" component={Cadastro} />
          <Route
            path="/cadastro-acompanhamento"
            component={CadastroAcompanhamento}
          />
          <Route path="/termos-servico" component={TermosServico} />
          <Route exact path="/agendamento" component={Agendamento} />
          <Route exact path="/agendamento/:passo" component={Agendamento} />
          <Route
            path="/agendamento/:passo/:reagendar"
            component={Agendamento}
          />
          <Route path="/dados-pessoais" component={MeusDados} />
          <Route path="/endereco" component={MeuEndereco} />
          <Route path="/minha-senha" component={MinhaSenha} />
          <Route path="/meus-aplicativos" component={MeusAplicativos} />
          <Route path="/verificacao" component={Verificacao} />
          <Route path="/ativar-equipamento" component={AtivarEquipamento} />
          <Route path="/callback" component={Callback} />
          <Route exact path="/primeiro-acesso/:email" component={PrimeiroAcesso} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}
