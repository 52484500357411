import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;

export const obterDatasDisponiveis = async (cd_motorista, ponto_escolhido) => {
  let response = await axios.get(urlAPI + '/agendamento/obter-datas-disponiveis/' + cd_motorista + '/' + ponto_escolhido, headers());

  return response.data.datas;
}

export const obterPontosDisponiveis = async (cd_motorista) => {
  let response = await axios.get(urlAPI + '/agendamento/obter-pontos-disponiveis/' + cd_motorista, headers());

  return response.data.pontos;
}

export const obterAgendamento = async (cd_motorista) => {
  let response = await axios.get(urlAPI + '/agendamento/listar-agendado/' + cd_motorista, headers());

  return response.data;
}

export const gravarAgendamento = (cd_motorista, cd_ponto_apoio, dt_agendada, hr_agendada) => {
  return axios.patch(urlAPI + '/agendamento/gravar-agendamento/' + cd_motorista, {
    cd_motorista,
    cd_ponto_apoio,
    dt_agendada,
    hr_agendada,
  }, headers());
}
