import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image } from "react-native";
import $ from "jquery";

import config from '../../../config/config';
import * as ApuracaoMensalActions from "../../../actions/ApuracaoMensalActions";

class ExecutarPasso2 extends Component {
  constructor(props) {
    super();

    this.state = {
      arquivo: null,
      cd_motorista: null,
      numero_viagens: null,
      cd_aplicativo: null,
    };
  }

  componentWillMount = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");

    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      let verificacao_minhas_viagens = localStorage.getItem(
        "verificacao_minhas_viagens"
      );

      if (
        typeof verificacao_minhas_viagens !== "undefined" &&
        verificacao_minhas_viagens !== null
      ) {
        motorista_logado = JSON.parse(motorista_logado);
        verificacao_minhas_viagens = JSON.parse(verificacao_minhas_viagens);

        this.setState({
          cd_motorista: motorista_logado.cd_motorista,
          numero_viagens: verificacao_minhas_viagens.numero_viagens,
          cd_aplicativo: verificacao_minhas_viagens.cd_aplicativo,
        });
      } else {
        this.props.history.push("/verificacao/executar-passo-1");
      }
    } else {
      this.props.history.push("/login");
    }
  };

  _upload = () => {
    if (this.state.arquivo) {
      let cd_motorista = this.state.cd_motorista;
      let cd_aplicativo = this.state.cd_aplicativo;
      let viagens_realizadas = this.state.numero_viagens;
      let arquivo = this.state.arquivo;

      $(".loader-main").removeClass("loader-inactive");
      localStorage.removeItem("verificacao_minhas_viagens");

      ApuracaoMensalActions.gravarApuracao({
        cd_motorista,
        cd_aplicativo,
        viagens_realizadas,
        arquivo,
      })
        .then((res) => {
          $(".loader-main").addClass("loader-inactive");

          this.props.history.push("/verificacao/historico");
        })
        .catch((error) => {
          $("#formUpload").submit();
        });
    } else {
      $(".chooseFileButton").addClass("has-error");

      let dados = this.state;
      dados.error = "Está imagem deve ser enviada.";
      this.setState(dados);
    }
  };

  _loadUpload = (e) => {
    let cd_motorista = this.state.cd_motorista;
    let cd_aplicativo = this.state.cd_aplicativo;

    ApuracaoMensalActions.verificarSePendentePorAplicativo(
      cd_motorista,
      cd_aplicativo
    )
      .then((res) => {
        if (res.pendente) {
          $(".loader-main").addClass("loader-inactive");

          this.props.history.push("/verificacao/historico");
        } else {
          this.setState({
            error: "Ocorreu um erro ao enviar o arquivo, tente novamente.",
            arquivo: null,
            binArquivo: null,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          error: "Ocorreu um erro ao enviar o arquivo, tente novamente.",
          arquivo: null,
          binArquivo: null,
        });
      });
  };

  _selectImages = (event) => {
    if (event.target.files.length > 0) {
      let arquivo = event.target.files.item(0);

      if (arquivo.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        if (arquivo.size <= 1024 * 1024 * 5) {
          let binArquivo = URL.createObjectURL(arquivo);

          this.setState({
            error: null,
            arquivo: arquivo,
            binArquivo: binArquivo,
          });

          $("html,body").animate(
            { scrollTop: document.body.scrollHeight },
            "fast"
          );
        } else {
          this.setState({
            error: "Tamanho máximo da imagem é de 5 MB.",
            arquivo: null,
            binArquivo: null,
          });
        }
      } else {
        this.setState({
          error: "Somente imagens JPG, PNG e GIF são permitidas.",
          arquivo: null,
          binArquivo: null,
        });
      }
    }
  };

  _removerImagem = () => {
    this.setState({ arquivo: null, binArquivo: null });
  };

  _imageLoad = () => {
    $(".uploadPictureContainer img")
      .parent()
      .find("div")
      .css("background-size", "contain");
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Perfil de Motorista</div>

        <div className="cf-texto div-body margin-30 space-26">
          Para finalizar, capture a tela de Perfil{" "}
          {this.state.descricao_aplicativo}:
        </div>

        <div className="div-separator">
          <div className="div-progress" style={{ width: "90%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>

        <img
          src={require("../../../resources/imgs/perfil-motorista.png")}
          className="center"
          style={{ width: "200px" }}
          alt=""
        />

        <div
          id="div-help"
          className="help-inline"
          style={{
            display: this.state.error ? "block" : "none",
            textAlign: "center",
          }}
        >
          {this.state.error}
        </div>
        <br style={{ display: this.state.error ? "block" : "none" }} />

        <form
          id="formUpload"
          target="frameUpload"
          method="post"
          action={
            config.URL_API_BASE + "/motorista-aplicativo/upload"
          }
          encType="multipart/form-data"
        >
          <input
            type="hidden"
            name="cd_motorista"
            value={this.state.cd_motorista}
          />
          <input
            type="hidden"
            name="cd_aplicativo"
            value={this.state.cd_aplicativo}
          />
          <input
            type="hidden"
            name="viagens_realizadas"
            value={this.state.numero_viagens}
          />

          <div className="upload-btn-wrapper">
            <button className="box-input-file input-label margin-25 space-16">
              Carregar imagem
            </button>
            <input
              type="file"
              name="file"
              onChange={this._selectImages}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>

          {this.state.binArquivo && (
            <div className="uploadPicturesWrapper">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <div className="uploadPictureContainer">
                  <div className="deleteImage" onClick={this._removerImagem}>
                    X
                  </div>
                  <Image
                    source={{ uri: this.state.binArquivo }}
                    style={{ height: 450, flex: 1 }}
                    onLoadEnd={this._imageLoad}
                  />
                </div>
              </div>
            </div>
          )}
        </form>

        <div className="space-16"></div>
        <div className="div-center">
          <button
            type="button"
            className="bt-login margin-30"
            onClick={() => this._upload()}
          >
            FINALIZAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-50"></div>

        <iframe
          id="frameUpload"
          name="frameUpload"
          title="frameUpload"
          height="0"
          width="0"
          onLoad={this._loadUpload}
        />
      </div>
    );
  }
}

export default withRouter(ExecutarPasso2);
