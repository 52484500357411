import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class Sucesso extends Component {
  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-26"></div>
        <img
          src={require("../../../resources/imgs/svg/confirmacao-icon.svg")}
          className="center"
          style={{ width: "200px" }}
          alt=""
        />
        <div className="space-50"></div>
        <div className="title title-32 text-center text-destaque">
          Sua solicitação foi enviada para análise!
        </div>
        <div className="space-26"></div>
        <div className="div-separator-dt">
          <div
            className="div-progress div-progress-dt"
            style={{ width: "90%" }}
          ></div>
          <div className="div-progress-bullet div-progress-bullet-dt"></div>
        </div>
        <div className="cf-texto div-body margin-30 text-center">
          Você será notificado quando sua solicitação for avaliada! Fique
          ligado.
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <Link to={"/login"}>
            <button className="bt-login margin-30">
              ENTRAR NA ZANZAR{" "}
              <i className="fas fa-chevron-right icon-14 float-right"></i>
            </button>
          </Link>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(Sucesso);
