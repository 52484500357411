import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Formatters from '../../../helpers/Formatters';

import * as AgendamentoActions from '../../../actions/AgendamentoActions';

class Confirmar extends Component {
  constructor(props){
    super();

    this.state = {
      error: '',
      agendamento: null,
    };
  }

  componentDidMount = async () => {
    let motorista_logado = localStorage.getItem('motorista_logado');

    if (typeof motorista_logado !== 'undefined' && motorista_logado !== null){
      motorista_logado = JSON.parse(motorista_logado);

      let agendamento = await AgendamentoActions.obterAgendamento(motorista_logado.cd_motorista);

      if (agendamento && agendamento.dt_agendada)
        this.setState({ agendamento });
      else
        this.setState({ error: 'Não existe agendamentos disponíveis.' });
    } else {
      window.location = '/login';
    }
  }

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-32 text-center text-destaque">Agendamento<br/>realizado!</div>

        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30 text-center">Confira abaixo sua data e horário para instalação da TV Zanzar</div>

        <div className="space-26"></div>
        {
          !this.state.agendamento ? '' :
          <div className="row margin-22" style={ this.state.error ? { display: 'none' } : {} }>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-ponto-inst box-ponto-confirm">
              <div className="titulo">
                { this.state.agendamento.ponto_apoio }
                <i className="fas fa-map-marker-alt icon-12 float-right" aria-hidden="true">
                  &nbsp;
                  <span className="km">{ (this.state.agendamento.distancia / 1000).toFixed(1) }km</span>
                </i>
              </div>
              <div className="endereco">{ this.state.agendamento.endereco }</div>
              <div className="data">
                Dia: { Formatters.formatarData('DD/MM', this.state.agendamento.dt_agendada) }
                &nbsp;&nbsp;&nbsp;&nbsp;
                Horário: {
                  this.state.agendamento.idc_agenda_hora
                    ? Formatters.formatarData('HH:mm', this.state.agendamento.dt_agendada)
                    : `${this.state.agendamento.horario_atend_ini} às ${this.state.agendamento.horario_atend_fim}`
                }
              </div>
            </div>
          </div>
        }

        <div className="space-32"></div>
        <div className="cf-texto div-body div-gray text-center">
          <div className="vcenter" style={ this.state.error ? { display: 'none' } : {} }>
            Em caso de ausência no dia marcado, você será impossibilitado de instalar futuramente o equipamento Zanzar
          </div>
          <div className="vcenter" style={ this.state.error ? { color: 'red', padding: '6px' } : { display: 'none' } }>
            { this.state.error }
          </div>
        </div>

        <div className="space-26"></div>
        <div className="div-center">
          <Link to={'/'}>
            <button className="cf-bt-cadastre margin-40" style={{ width: '278px' }}>Ir para tela incial</button>
          </Link>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(Confirmar);
