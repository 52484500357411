import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Topo extends Component {
    render() {
        return (
            <div className="header header-cf header-logo-center">
                <div className="header-title">
                    <img src={require('../../../resources/imgs/logo-zanzar-white.png')} className="logo-topo" alt="" />
                </div>
                <a href="#/" className="header-icon header-icon-1" data-menu="menu-sidebar">
                    <img src={require('../../../resources/imgs/svg/arrow-menu.svg')} className="arrow-menu" alt="" />
                </a>
            </div>
        );
    }
}

export default withRouter(Topo);
