import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import * as MotoristaActions from '../../../actions/MotoristaActions';

class Termo extends Component {
  _aceitar = () => {
    let motorista_logado = localStorage.getItem('motorista_logado');

    if (typeof motorista_logado !== 'undefined' && motorista_logado !== null){
      this.motorista_logado = JSON.parse(motorista_logado);

      $('.loader-main').removeClass('loader-inactive');

      MotoristaActions.aceitarTermos(this.motorista_logado.cd_motorista)
        .then(response => {
          $('.loader-main').addClass('loader-inactive');

          if (response.data && response.data.cd_motorista){
            let motorista_logado = localStorage.getItem('motorista_logado');

            if (typeof motorista_logado !== 'undefined' && motorista_logado !== null){
              motorista_logado = JSON.parse(motorista_logado);

              motorista_logado.idc_termo_aceito = 1;

              localStorage.setItem('motorista_logado', JSON.stringify(motorista_logado));

              this.props.history.push('/agendamento/escolher-ponto');
            } else {
              this.props.history.push('/login');
            }
          } else {
            $('.alert').show();
          }
        })
        .catch((error) => {
          console.log(error.message);
          if (error.response && error.response.data) {
            console.log(error.response.data);
            $('.alert').show();
          }

          $('.loader-main').addClass('loader-inactive');
        });
    } else {
      this.props.history.push('/login');
    }
  }

    render() {
        return (
            <div className="page-content header-clear">
                <div className="title title-24 margin-22">CONTRATO DE COMODATO DE ESPAÇO PUBLICITÁRIO EM VEÍCULOS</div>

        <div className="space-32"></div>

                <div className="box-texto margin-22">
                Pelo presente instrumento, Zanzar Mídia Ltda, pessoa jurídica de direito privado, situada à Rua Antonio Aleixo, 756, pilotis, Bairro Lourdes, Belo Horizonte, Minas Gerais, CEP: 30.180-153, inscrita no CNPJ nº 30.258.165/0001-29, doravante denominada CESSIONÁRIA e o CEDENTE, resolvem firmar a presente Contrato de Cessão Onerosa de Espaço Publicitário em Veículos, o que fazem nos seguintes termos:
                    <br/><br/>
                    CLÁUSULA PRIMEIRA – OBJETO
                    <br/><br/>
                    1.1	 Constitui objeto deste instrumento, a cessão de espaço no encosto de cabeça de veículos automotores de propriedade ou em posse do CEDENTE, para a instalação de suporte fixo para tablet, referente ao projeto de divulgação de conteúdos digitais (entretenimento, notícias e propagandas).
                    <br/><br/>
                    1.2	 É também objeto deste contrato, o fornecimento, pela CESSIONÁRIA fornecerá ao CEDENTE, a título de comodato, um tablet Multilaser M10A, um suporte e conectores internos desenvolvidos pela própria Cessionária com a finalidade específica de veicular conteúdos pre estabelecidos pela Cessionária;
                    <br/><br/>
                    CLÁUSULA SEGUNDA- DAS ESPECIFICAÇÕES E EXECUÇÃO DO OBJETO
                    <br/><br/>
                    2.1	 A CESSIONÁRIA será responsável pela indicação do local, dia e horário para instalação, manutenção e retirada do suporte fixo para tablet, sendo de obrigação do CEDENTE, apresentar o veículo no local, dia e horário comercial aprazados;
                    <br/><br/>
                    2.2	 A CEDENTE declara estar recebendo o bem descrito na Cláusula 1.2, gratuitamente, em perfeitas condições de uso e funcionamento.
                    <br/><br/>
                    2.3	 Caberá ao CEDENTE manter o tablet sob sua guarda, instalado no suporte fixo do encosto de cabeça e sendo responsável por sua conservação/integridade;
                    <br/><br/>
                    2.4	 Em caso de perda, roubo ou furto, deverá o CEDENTE apresentar à CESSIONÁRIA o Boletim de Ocorrência (B.O) no prazo de 2 dias uteis comprovando tal situação, e caso não seja comprovada a veracidade dos fatos narrados no B.O, o CEDENTE deverá ressarcir a CESSIONARIA os equipamentos;
                    <br/><br/>
                    2.5	 É expressamente vedada a utilização do tablet para finalidade diversa que a de veicular automaticamente os vídeos de conteúdos fornecidos pela CESSIONÁRIA;
                    <br/><br/>
                    2.6	 Caberá ao CEDENTE manter o tablet em perfeito funcionamento. Em caso de qualquer intercorrência, caberá ao CEDENTE entrar em contato imediatamente com a CESSIONÁRIA, sob pena de sua inércia ser entendida como negligência e ensejar a reparação por perdas e danos;
                    <br/><br/>
                    2.7	 Fica reservado o direito da COMODANTE de exigir ressarcimento de danos causados pela COMODATÁRIA no equipamento e a terceiros e a coisas, que sejam provocados por atos ou omissões da COMODATÁRIA, de seus empregados, prepostos e/ou subcontratados.
                    <br/><br/>
                    CLÁUSULA TERCEIRA – DA CESSÃO GRATUITA
                    <br/><br/>
                    3.1  O presente Contrato trata-se de uma cessão gratuita (comodato) de espaço publicitário interno de veículos, envolvendo toda a relação entre a CESSIONÁRIA e o CEDENTE. Eventuais benefícios indiretos não importaão em prejuízos a relação envolvendo as PARTES, benefícios estes tais como brindes, pontos em programas de benefícios, dentre outros prêmios, a critério único da CESSIONÁRIA.
                    <br/><br/>
                    3.2  Os prêmios e benefícios serão realizados de acordo com os relatórios de viagens que comprovem o número de viagens realizadas pelo CEDENTE.
                    <br/><br/>
                    CLÁUSULA QUARTA – PRAZO E RESCISÃO
                    <br/><br/>
                    4.1	 Caso a CEDENTE descumpra qualquer obrigação prevista neste contrato, este incorrerá em multa no valor de R$ 1.500,00, que deverá ser pago a CESSIONÁRIA em 5 dias úteis do recebimento de Notificação Extrajudicial para tanto.
                    <br/><br/>
                    CLÁUSULA QUINTA – DISPOSIÇÕES GERAIS
                    <br/><br/>
                    5.1	 Este contrato é firmado por prazo indeterminado, passando a viger a partir da data de sua assinatura.
                    <br/><br/>
                    5.2	 A retirada do equipamento e rescisão do Contrato poderá ser solicitada pela CESSIONARIA, em qualquer período através de Notificação Extrajudicial, e o CEDENTE terá um prazo de 5 dias úteis a partir do recebimento da Notificação para comparecer ao local indicado e realizar a retirada do equipamento.
                    <br/><br/>
                    5.3	 Caso o CEDENTE manifeste expressamente o interesse em rescindir o Contrato, o mesmo deverá entrar em contato com a CESSIONÁRIA com uma antecedência de 2 dias úteis para agendamento da retirada do equipamento;
                    <br/><br/>
                    5.4	 Dar-se-á também por rescindido por inadimplemento ou infração contratual, caso a parte inadimplente e/ou infratora, após cientificada, não tome no prazo de 07 (sete) dias contados da data da ciência, as medidas necessárias para o retorno a adimplência e ao cumprimento das cláusulas contratuais;
                    <br/><br/>
                    CLÁUSULA SEXTA – DA DEVOLUÇÃO DO EQUIPAMENTO E INADIMPLÊNCIA
                    <br/><br/>
                    6.1	 Caso rescindido presente Contrato, a CEDENTE não restitua os bens dados em 5 (cinco) dias após Notificada para tanto, ela reconhece, desde logo, que cometerá infração e responderá por todas as despesas suportadas pela CESSIONARIA na retomada do bem ou até que seja ressarcida pelo valor integral do equipamento, incluídas as eventuais custas processuais, honorários advocatícios, desde já convencionados em 20%, e afins.
                    <br/><br/>
                    6.2	 No caso elencado na Cláusula 5.2, o CEDENTE deverá ressarcir a CESSIONARIA no valor de mercado do equipamento, incluídas multa diária de 0,1% ao dia sobre o valor do equipamento até que este seja sando o inadimplemento. 
                    <br/><br/>
                    6.3	 O CEDENTE declara ter ciência que a recusa ou inercia em devolver o equipamento ou o ressarcir a CESSIÓNARIA possibilitará o registro de seu nome no SERVIÇO DE PROTEÇÃO AO CRÉDITO – SPC e na Centralização de Serviços dos Bancos S/A – SERASA, até que seja efetuada a quitação da inadimplência.
                    <br/><br/>
                    6.4	 O CEDENTE declara, ainda, estar ciente de que ao não realizar a devolução do bem objeto deste contrato, nem ressarcir a CESSIONÁRIA pela apropriação indevida do bem, incorrerá no crime previsto no Artigo 168 do Código Penal, que tipifica criminalmente a prática de apropriação indébita, declarando se ciente da possibilidade de a CESSIONÁRIA realizar Boletim de Ocorrência a fim de registrar o ocorrido.
                    <br/><br/>
                    CLÁUSULA SÉTIMA – DISPOSIÇÕES GERAIS
                    <br/><br/>
                    7.1	 A tolerância por parte da CESSIONÁRIA de qualquer violação a dispositivos deste contrato será entendida como mera liberalidade, não constituindo novação, nem gerando qualquer direito oponível, nem a perda da prerrogativa em exigir o pleno cumprimento das obrigações contratuais avençadas e a reparação de qualquer dano.
                    <br/><br/>
                    7.2	 A partir deste termo, o CEDENTE compromete-se, expressamente, a enviar os dados de suas corridas, utilizando a Plataforma da UBER, à CESSIONÁRIA.
                    <br/><br/>
                    7.3	 A partir deste termo, o CEDENTE autoriza, expressamente, o envio pela UBER à CESSIONÁRIA de dados referente ao número de viagens que realizar na plataforma do aplicativo Uber, desde que para a finalidade única e exclusiva da verificação do numero de viagens realizadas no periodo.
                    <br/><br/>
                    7.4	 A partir deste termo, o CEDENTE autoriza, expressamente, à CESSIONÁRIA a entrar em contato via whatsapp, sms ou ligações para qualquer comunicação com o mesmo.
                    <br/><br/>
                    7.5	 O CEDENTE declara ter ciência que as viagens serão monitoradas conjuntamente pela CESSIONÁRIA e a UBER, para fins exclusivos de validação do numero de corridas realizadas, e monitoramento do correto uso do equipamento.
                    <br/><br/>
                    CLÁUSULA SEXTA – FORO
                    <br/><br/>
                    6.1	 Fica eleito o foro da cidade de Belo Horizonte/MG, com expressa exclusão de qualquer outro, por mais privilegiado que seja, para nele serem dirimidas quaisquer questões oriundas do presente instrumento ou sua execução.
                    As Partes concordam que o presente Contrato poderá ser assinado eletronicamente por meio de plataforma de assinatura eletrônica, nos termos do Art. 10, § 2º, da Medida Provisória nº 2200-2/2001, e do Art. 6º do Decreto nº 10.278/2020, sendo as assinaturas consideradas válidas e executáveis, desde que firmadas pelos representantes legais das partes. Caso este Contrato venha a ser assinado eletronicamente, as Partes renunciam à possibilidade de exigir a troca, envio ou entrega das vias originais (não-eletrônicas), assim como renunciam ao direito de recusar ou contestar a validade das assinaturas eletrônicas, na medida máxima permitida pela legislação aplicável.
                    <br/><br/>
                    <br/><br/>
                    E por estarem justos e contratados, assinam o presente contrato, para um só fim de direito.
                    <br/><br/>
                    <br/><br/>
                    <b>Central de Atendimento: Rua Professor Antônio Aleixo, 756 - Pilotis - Lourdes – Seg a Sex – 9:00 as 18:00 
                    (31) 3879-5688 / Whatsapp (31)99727-1799 / www.zanzar.com.br</b>
                </div>

        <div className="termos-title margin-22">Clicando no botão abaixo, eu aceito os termos acima.</div>

        <div className="space-16"></div>
        <div className="div-center">
          <button className="bt-aceito margin-10" onClick={() => this._aceitar()}>ACEITO</button>
        </div>
        <div className="space-50"></div>
      </div>
    );
  }
}

export default withRouter(Termo);
