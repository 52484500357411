import React, { Component } from "react";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import { Image } from "react-native";
import $ from "jquery";

import config from '../../../config/config';
import * as Logger from "../../../helpers/Logger";
import * as ClientUtil from "../../../helpers/ClientUtil";
import * as MotoristaAplicativoActions from "../../../actions/MotoristaAplicativoActions";

class Passo14x2 extends Component {
  constructor(props) {
    super();
    if (props.Menu !== 1) {
      props.setItemState(1);
    }
    this.state = {
      arquivo: null,
      cd_motorista: props.location.state.cd_motorista,
      cd_aplicativo: props.location.state.cd_aplicativo,
      descricao_aplicativo: props.location.state.descricao_aplicativo,
      redirect: false,
    };
    this.setSucessoUpload = props.setItemState;
  }

  componentWillMount = async () => {
    let deviceinfo = await ClientUtil.getDeviceInfo();
    Logger.info(
      "UPLOAD CADASTRO : INICIO : " +
        this.state.cd_motorista +
        " : " +
        this.state.cd_aplicativo
    );
    Logger.info("UPLOAD CADASTRO : APARELHO : " + deviceinfo);
  };

  _upload = async () => {
    let cd_motorista = this.state.cd_motorista;
    let cd_aplicativo = this.state.cd_aplicativo;
    let arquivo = this.state.arquivo;
    if (arquivo) {
      let cadastro_meus_uploads_falhas = localStorage.getItem(
        "cadastro_meus_uploads_falhas"
      );
      let cadastro_meus_uploads = localStorage.getItem("cadastro_meus_uploads");
      if (
        typeof cadastro_meus_uploads_falhas !== "undefined" &&
        cadastro_meus_uploads_falhas !== null
      ) {
        let falhas = JSON.parse(cadastro_meus_uploads_falhas);
        falhas = falhas.filter((item) => {
          return item !== cd_aplicativo;
        });
        localStorage.setItem(
          "cadastro_meus_uploads_falhas",
          JSON.stringify(falhas)
        );
      }
      if (
        typeof cadastro_meus_uploads !== "undefined" &&
        cadastro_meus_uploads !== null
      ) {
        let meus_uploads = JSON.parse(cadastro_meus_uploads);
        meus_uploads = meus_uploads.filter((item) => {
          return item !== cd_aplicativo;
        });
        localStorage.setItem(
          "cadastro_meus_uploads",
          JSON.stringify(meus_uploads)
        );
      }
      $(".loader-main").removeClass("loader-inactive");
      MotoristaAplicativoActions.uploadImagemPerfil({
        cd_motorista,
        cd_aplicativo,
        arquivo,
      })
        .then((res) => {
          Logger.info("UPLOAD CADASTRO : SUCESSO 1 : " + JSON.stringify(res));
          let meus_uploads = [];
          let cadastro_meus_uploads = localStorage.getItem(
            "cadastro_meus_uploads"
          );
          if (
            typeof cadastro_meus_uploads !== "undefined" &&
            cadastro_meus_uploads !== null
          ) {
            meus_uploads = JSON.parse(cadastro_meus_uploads);
          }
          meus_uploads.push(cd_aplicativo);
          localStorage.setItem(
            "cadastro_meus_uploads",
            JSON.stringify(meus_uploads)
          );
          // this.setSucessoUpload(1);
          $(".loader-main").addClass("loader-inactive");
          this.setState({ redirect: true });
        })
        .catch((error) => {
          let errorMsg =
            error.response && error.response.data
              ? error.response.data.error
              : error.message;
          Logger.error("UPLOAD CADASTRO : ERRO 1 : " + errorMsg);
          $("#formUpload").submit();
        });
    } else {
      let dados = this.state;
      dados.error = "Selecione a imagem para enviar.";
      this.setState(dados);
    }
  };

  _selectImages = (event) => {
    Logger.info(
      "UPLOAD CADASTRO : _selectImages : " + event.target.files.length
    );
    if (event.target.files.length > 0) {
      let arquivo = event.target.files.item(0);
      if (arquivo.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        if (arquivo.size <= 1024 * 1024 * 5) {
          let binArquivo = URL.createObjectURL(arquivo);
          this.setState({
            error: null,
            arquivo: arquivo,
            binArquivo: binArquivo,
          });
          $("html,body").animate(
            { scrollTop: document.body.scrollHeight },
            "fast"
          );
        } else {
          this.setState({
            error: "Tamanho máximo da imagem é de 5 MB.",
            arquivo: null,
            binArquivo: null,
          });
        }
      } else {
        this.setState({
          error: "Somente imagens JPG, PNG e GIF são permitidas.",
          arquivo: null,
          binArquivo: null,
        });
      }
    }
  };

  _clickFile = (event) => {
    Logger.info("UPLOAD CADASTRO : _clickFile");
  };

  _removerImagem = () => {
    this.setState({ arquivo: null, binArquivo: null });
  };

  _imageLoad = () => {
    $(".uploadPictureContainer img")
      .parent()
      .find("div")
      .css("background-size", "contain");
  };

  _loadUpload = (e) => {
    let cd_motorista = this.state.cd_motorista;
    let cd_aplicativo = this.state.cd_aplicativo;
    MotoristaAplicativoActions.obterStatusCadastro(cd_motorista, cd_aplicativo)
      .then((res) => {
        if (res.idc_cadastro_completo) {
          let meus_uploads = [];
          let cadastro_meus_uploads = localStorage.getItem(
            "cadastro_meus_uploads"
          );
          if (
            typeof cadastro_meus_uploads !== "undefined" &&
            cadastro_meus_uploads !== null
          ) {
            meus_uploads = JSON.parse(cadastro_meus_uploads);
          }
          meus_uploads.push(cd_aplicativo);
          localStorage.setItem(
            "cadastro_meus_uploads",
            JSON.stringify(meus_uploads)
          );
          // this.setSucessoUpload(1);
          $(".loader-main").addClass("loader-inactive");
          this.setState({ redirect: true });
        } else {
          let falhas = [];
          let cadastro_meus_uploads_falhas = localStorage.getItem(
            "cadastro_meus_uploads_falhas"
          );
          if (
            typeof cadastro_meus_uploads_falhas !== "undefined" &&
            cadastro_meus_uploads_falhas !== null
          ) {
            falhas = JSON.parse(cadastro_meus_uploads_falhas);
          }
          falhas.push(cd_aplicativo);
          localStorage.setItem(
            "cadastro_meus_uploads_falhas",
            JSON.stringify(falhas)
          );
          // this.setSucessoUpload(1);
          $(".loader-main").addClass("loader-inactive");
          this.setState({ redirect: true });
        }
      })
      .catch(() => {
        let falhas = [];
        let cadastro_meus_uploads_falhas = localStorage.getItem(
          "cadastro_meus_uploads_falhas"
        );
        if (
          typeof cadastro_meus_uploads_falhas !== "undefined" &&
          cadastro_meus_uploads_falhas !== null
        ) {
          falhas = JSON.parse(cadastro_meus_uploads_falhas);
        }
        falhas.push(cd_aplicativo);
        localStorage.setItem(
          "cadastro_meus_uploads_falhas",
          JSON.stringify(falhas)
        );
        // this.setSucessoUpload(1);
        $(".loader-main").addClass("loader-inactive");
        this.setState({ redirect: true });
      });
  };

  render() {
    if (this.state.redirect) return <Redirect to="/cadastro/passo-14" />;
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Perfil de Motorista</div>
        <div className="cf-texto div-body margin-30 space-26">
          Para finalizar, capture a tela de Perfil{" "}
          {this.state.descricao_aplicativo}:
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "81%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <img
          src={require("../../../resources/imgs/perfil-motorista.png")}
          className="center"
          style={{ width: "200px" }}
          alt=""
        />
        <div
          id="div-help"
          className="help-inline"
          style={{
            display: this.state.error ? "block" : "none",
            textAlign: "center",
          }}
        >
          {this.state.error}
        </div>
        <br style={{ display: this.state.error ? "block" : "none" }} />
        <form
          id="formUpload"
          target="frameUpload"
          method="post"
          action={
            config.URL_API_BASE + "/motorista-aplicativo/upload"
          }
          encType="multipart/form-data"
        >
          <input
            type="hidden"
            name="cd_motorista"
            value={this.state.cd_motorista}
          />
          <input
            type="hidden"
            name="cd_aplicativo"
            value={this.state.cd_aplicativo}
          />
          <div className="upload-btn-wrapper">
            <button className="box-input-file input-label margin-25 space-16">
              Carregar imagem
            </button>
            <input
              type="file"
              name="file"
              onChange={this._selectImages}
              onClick={this._clickFile}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
          {this.state.binArquivo && (
            <div className="uploadPicturesWrapper">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <div className="uploadPictureContainer">
                  <div className="deleteImage" onClick={this._removerImagem}>
                    X
                  </div>
                  <Image
                    source={{ uri: this.state.binArquivo }}
                    style={{ height: 450, flex: 1 }}
                    onLoadEnd={this._imageLoad}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
        <div className="space-16"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._upload()}>
            ENVIAR <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-50"></div>
        <iframe
          id="frameUpload"
          name="frameUpload"
          title="frameUpload"
          height="0"
          width="0"
          onLoad={this._loadUpload}
        />
      </div>
    );
  }
}

export default withRouter(Passo14x2);
