import $ from "jquery";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";
import * as AtivarEquipamentoActions from "../../../actions/AtivarEquipamentoActions";

class AtivarEquipamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cd_motorista: null,
      error: null,
      faleConoscoAtivo: false,
    };
  }

  componentWillMount = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");
    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);
      if (motorista_logado.idc_equipamento !== 1) {
        this.setState({ cd_motorista: motorista_logado.cd_motorista });
      } else {
        this.props.history.push("/home");
      }
    } else {
      this.props.history.push("/login");
    }
  };

  _handleError = (error, faleConoscoAtivo = false) => {
    let dados = this.state;
    dados.error = error;
    dados.faleConoscoAtivo = faleConoscoAtivo;
    this.setState(dados);
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    dados[campo] = valor;
    await this.setState(dados);
  };

  _maskDelete = (e) => {
    const cursorPos = Number(e.target.selectionStart);
    const keyCode = Number(e.keyCode);
    if (keyCode === 8) {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = e.target.value.substring(0, cursorPos - 1);
      e.target.setSelectionRange(cursorPos, cursorPos);
      this._updateField(e);
    }
  };

  _goFaleConosco = () => {
    this.props.history.push("/fale-conosco/formulario");
  };

  _confirmar = () => {
    $(".loader-main").removeClass("loader-inactive");
    if (this.state.cd_tv_zanzar) {
      let regex = /[0-9].[0-9][0-9][0-9][0-9][0-9]/gim;
      if (regex.test(this.state.cd_tv_zanzar)) {
        this._handleError(null);
        AtivarEquipamentoActions.ativarComCodigo({
          cd_motorista: this.state.cd_motorista,
          cd_tv_zanzar: this.state.cd_tv_zanzar,
        })
          .then((response) => {
            if (response.data && response.data.result) {
              let motorista_logado = localStorage.getItem("motorista_logado");
              if (
                typeof motorista_logado !== "undefined" &&
                motorista_logado !== null
              ) {
                motorista_logado = JSON.parse(motorista_logado);
                motorista_logado.idc_equipamento = 1;
                localStorage.setItem(
                  "motorista_logado",
                  JSON.stringify(motorista_logado)
                );
              }
              $(".loader-main").addClass("loader-inactive");
              this.props.history.push("/");
            } else {
              this._handleError(response.data.message);
              $(".loader-main").addClass("loader-inactive");
            }
          })
          .catch((error) => {
            this._handleError(
              error.response.data.message,
              error.response.data.code === 2
            );
            $(".loader-main").addClass("loader-inactive");
          });
      } else {
        this._handleError("Código informado inválido");
        $(".loader-main").addClass("loader-inactive");
      }
    } else {
      this._handleError("Informe o código de sua TV Zanzar");
      $(".loader-main").addClass("loader-inactive");
    }
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-34"></div>
        <img
          src={require("../../../resources/imgs/svg/code-icon.svg")}
          className="center"
          style={{ width: "215px" }}
          alt=""
        />
        <div className="space-16"></div>
        <div className="title title-28 text-center text-destaque">
          Ative a TV Zanzar
        </div>
        <div className="space-16"></div>
        <div className="cf-texto div-body margin-30 text-center">
          Insira o código que consta na parte <br /> inferior de sua TV Zanzar.
        </div>
        <div className="space-16"></div>
        <div className="div-center">
          <MaskedInput
            mask={[/\d/, ".", /\d/, /\d/, /\d/, /\d/, /\d/]}
            className="box-input-only input margin-25 space-16 input-cd-tv-zanzar"
            guide={false}
            id="cd_tv_zanzar"
            value={this.state.cd_tv_zanzar}
            placeholder="0.00000"
            onChange={(e) => {
              this._updateField(e);
            }}
            onKeyDown={(e) => {
              this._maskDelete(e);
            }}
          />
        </div>
        <div
          className="div-center"
          style={
            this.state.error
              ? { color: "red", padding: "6px" }
              : { display: "none" }
          }
        >
          {this.state.error}
        </div>
        <div
          className="div-body text-center"
          style={this.state.faleConoscoAtivo ? {} : { display: "none" }}
        >
          <div className="space-8"></div>
          <button
            className="cf-bt-cadastre margin-10"
            onClick={() => this._goFaleConosco()}
          >
            FALE CONOSCO
          </button>
        </div>
        <div className="space-16"></div>
        <div className="div-center">
          <button
            className="cf-bt-cadastre margin-10"
            onClick={() => this._confirmar()}
          >
            ATIVAR TV ZANZAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-50"></div>
      </div>
    );
  }
}

export default withRouter(AtivarEquipamento);
