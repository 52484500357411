import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;

export const listarAplicativos = async () => {
  let response = await axios.get(urlAPI + '/aplicativo/listar-ativos-com-categoria/1', headers());

  return response.data;
}

export const listarAplicativoCategorias = async () => {
  let response = await axios.get(urlAPI + '/aplicativo-categoria/listar-ativos/1', headers());

  return response.data;
}
