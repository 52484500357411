import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";
import $ from "jquery";

import * as AplicativoActions from '../../../actions/AplicativoActions';
import * as BoxHelper from '../../../helpers/BoxHelper';
import * as formHelp from '../../../helpers/FormHelpMsg';
import * as validations from '../validations';

class Passo12 extends Component {
  constructor(props) {
    super();
    this.state = {
      email: null,
      meus_aplicativos: [],
      minhas_notas: [],
      aplicativos: [],
    };
    this.campos = [];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();
    let dados = this.state;
    let zanzar_aplicativos = localStorage.getItem("zanzar_aplicativos");
    if (
      typeof zanzar_aplicativos !== "undefined" &&
      zanzar_aplicativos !== null
    ) {
      dados.aplicativos = JSON.parse(zanzar_aplicativos);
    } else {
      AplicativoActions.listarAplicativos().then((res) => {
        dados.aplicativos = res;
        localStorage.setItem("zanzar_aplicativos", JSON.stringify(res));
        this.setState(dados, this._setState);
      });
    }
    let cadastro_minhas_notas = localStorage.getItem("cadastro_minhas_notas");
    if (
      typeof cadastro_minhas_notas !== "undefined" &&
      cadastro_minhas_notas !== null
    ) {
      dados.minhas_notas = JSON.parse(cadastro_minhas_notas);
      dados.minhas_notas.forEach((item) => {
        dados["nota_" + item.cd_aplicativo] = item.nota;
      });
    }
    let cadastro_meus_aplicativos = localStorage.getItem(
      "cadastro_meus_aplicativos"
    );
    if (
      typeof cadastro_meus_aplicativos !== "undefined" &&
      cadastro_meus_aplicativos !== null
    ) {
      dados.meus_aplicativos = JSON.parse(cadastro_meus_aplicativos);
    }
    this.setState(dados, this._setState);
  }

  componentWillUnmount() {
    localStorage.setItem(
      "cadastro_minhas_notas",
      JSON.stringify(this.state.minhas_notas)
    );
  }

  _setState = () => {
    BoxHelper.boxFormSetup();
  };

  _gravar = async () => {
    let formOk = true;

    BoxHelper.boxFormSetup();

    this.campos.forEach((item) => {
      $("#" + item)
        .parent()
        .trigger("click");
      $("#" + item).trigger("click");

      if ($("#" + item).val() === "") {
        formOk = false;
      }

      validations.validarCampoPreenchido($("#" + item).val(), item);
    });
    if (formOk) {
      localStorage.setItem(
        "cadastro_minhas_notas",
        JSON.stringify(this.state.minhas_notas)
      );
      this.props.history.push("/cadastro/passo-13");
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    formHelp.hideHelp(campo, "box-input-required");
    validations.validarCampoPreenchido(valor, campo);
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    let cd_aplicativo = campo.slice(5);
    dados.minhas_notas = dados.minhas_notas.filter((item) => {
      return item.cd_aplicativo !== cd_aplicativo;
    });
    if (valor !== "") {
      dados.minhas_notas.push({ cd_aplicativo: cd_aplicativo, nota: valor });
    }
    dados[campo] = valor;
    await this.setState(dados);
  };

  _maskDelete = (e) => {
    const cursorPos = Number(e.target.selectionStart);
    const keyCode = Number(e.keyCode);
    if (keyCode === 8) {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = e.target.value.substring(0, cursorPos - 1);
      e.target.setSelectionRange(cursorPos, cursorPos);
      this._updateField(e);
    }
  };

  _getLabelClass = (campo) => {
    return this.state[campo] === undefined || this.state[campo] === ""
      ? "input-label2"
      : "input-label-sm2";
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Notas</div>
        <div className="cf-texto div-body margin-30 space-26">
          Qual a sua nota atual?
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "72%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div className="texto-20 margin-25 space-16">Digite sua nota:</div>
        {this.state.aplicativos.map((item, k) => {
          if (this.state.meus_aplicativos.includes(item.cd_aplicativo)) {
            let id_campo = "nota_" + item.cd_aplicativo;
            let id_container = id_campo + "InputContainer";
            this.campos.push(id_campo);
            return (
              <div
                className="box-input-only2 margin-25 space-12"
                id={id_container}
                key={k}
              >
                <MaskedInput
                  mask={[/\d/, ".", /\d/, /\d/]}
                  className="box-input-only input2"
                  guide={false}
                  keepCharPositions={false}
                  id={id_campo}
                  value={this.state[id_campo]}
                  onChange={(e) => {
                    this._updateField(e);
                  }}
                  onBlur={(e) => {
                    this._validarCampo(e);
                  }}
                  onKeyDown={(e) => {
                    this._maskDelete(e);
                  }}
                />
                <label
                  className={this._getLabelClass(id_campo)}
                  htmlFor={id_campo}
                >
                  Nota {item.descricao}
                </label>
                <span className="help-inline"></span>
              </div>
            );
          } else {
            return "";
          }
        })}
        <div className="space-16"></div>
        <div
          className="alert alert-danger text-center margin-25"
          style={this.state.error ? { padding: 6 } : { display: "none" }}
        >
          {this.state.error}
        </div>
        <div className="space-34"></div>
        <div className="div-center div-bottom">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(Passo12);
