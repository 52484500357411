import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import TopoSemMenu from "../../components/TopoSemMenu";
import Formulario from "./components/Formulario";
import Sucesso from "./components/Sucesso";

export default class MinhaSenha extends Component {
  render() {
    return (
      <div>
        <TopoSemMenu />
        <Switch>
          <Route path="/minha-senha/formulario" component={Formulario} />
          <Route path="/minha-senha/sucesso" component={Sucesso} />
        </Switch>
      </div>
    );
  }
}
