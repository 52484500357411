import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';

import * as AgendamentoActions from '../../../actions/AgendamentoActions';

class EscolherData extends Component {
  constructor(props){
    super(props);

    this.reagendar = props.reagendar;

    this.state = {
      error: '',
      dataEscolhida: null,
      horarioEscolhido: null,
      pontoEscolhido: null,
      datas: null,
      consultaExecutada: false,
    };
  }

  componentDidMount = () => {
    $('.loader-main').removeClass('loader-inactive');

    let motorista_logado = localStorage.getItem('motorista_logado');

    if (typeof motorista_logado !== 'undefined' && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);

      let ponto_escolhido = localStorage.getItem('zanzar_ponto_escolhido');

      if (typeof ponto_escolhido === 'undefined' || ponto_escolhido === null) {
        this.props.history.push('/agendamento/escolher-ponto' + (this.reagendar ? '/reagendar' : ''));
      } else {
        ponto_escolhido = JSON.parse(ponto_escolhido);

        this.setState({ pontoEscolhido: ponto_escolhido, cd_motorista: motorista_logado.cd_motorista });

        let agendamento_datas_disponiveis = localStorage.getItem('agendamento_datas_disponiveis');

        if (typeof agendamento_datas_disponiveis !== 'undefined' && agendamento_datas_disponiveis !== null) {
          agendamento_datas_disponiveis = JSON.parse(agendamento_datas_disponiveis);

          if (agendamento_datas_disponiveis.dt_validade && moment.utc(agendamento_datas_disponiveis.dt_validade) > moment.utc()) {
            this.storeState(agendamento_datas_disponiveis.datas);
          } else {
            this.obterDatasDisponiveis(motorista_logado.cd_motorista, ponto_escolhido);
          }
        } else {
          this.obterDatasDisponiveis(motorista_logado.cd_motorista, ponto_escolhido);
        }
      }
    } else {
      this.props.history.push('/login');
    }
  }

  obterDatasDisponiveis = (cd_motorista, ponto_escolhido) => {
    AgendamentoActions.obterDatasDisponiveis(cd_motorista, ponto_escolhido.cd_ponto_apoio)
      .then(datas => {
        let agendamento_datas_disponiveis = {
          datas,
          dt_validade: moment.utc().add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        };

        localStorage.setItem('agendamento_datas_disponiveis', JSON.stringify(agendamento_datas_disponiveis));

        this.storeState(datas);
      })
      .catch((error) => {
        this.storeState(null);
      });
  }

  storeState = (datas) => {
    if (datas) {
      this.setState({
        datas,
        consultaExecutada: true,
      });
    } else {
      this.setState({
        datas: [],
        dataEscolhida: null,
        horarioEscolhido: null,
        consultaExecutada: true,
        error: 'Nenhuma data de instalação disponível.',
      });
    }

    $('.loader-main').addClass('loader-inactive');
  }

  _selecionar = (data) => {
    if (data.disponivel) {
      this.setState({
        dataSelecionada: data,
        dataEscolhida: data.data,
        horarioEscolhido: null,
        error: null,
      });
    } else {
      this.setState({
        error: 'Esta data não está mais disponível.',
      });
    }
  }

  _selecionarHorario = (horario, disponivel) => {
    if (disponivel) {
      this.setState({
        horarioEscolhido: horario,
        error: null,
      });
    } else {
      this.setState({
        error: 'Este horário não está mais disponível.',
      });
    }
  }

  _confirmar = () => {
    if (this.state.dataEscolhida === null) {
      this.setState({ error: 'Selecione uma data.' });
    } else if (this.state.pontoEscolhido.idc_agenda_hora && this.state.horarioEscolhido === null) {
      this.setState({ error: 'Selecione uma horário.' });
    } else {
      if (this.state.pontoEscolhido !== null) {
        $('.loader-main').removeClass('loader-inactive');

        let cd_motorista = this.state.cd_motorista;
        let cd_ponto_apoio = this.state.pontoEscolhido.cd_ponto_apoio;
        let dt_agendada = this.state.dataEscolhida;
        let hr_agendada = this.state.pontoEscolhido.idc_agenda_hora
          ? this.state.horarioEscolhido
          : '08:00';

        AgendamentoActions.gravarAgendamento(cd_motorista, cd_ponto_apoio, dt_agendada, hr_agendada)
          .then(response => {
            $('.loader-main').addClass('loader-inactive');

            localStorage.removeItem('zanzar_ponto_escolhido');
            localStorage.removeItem('agendamento_datas_disponiveis');
            localStorage.removeItem('agendamento_pontos_disponiveis');

            this.props.history.push('/agendamento/confirmar');
          })
          .catch((error) => {
            console.log(error.message);
            if (error.response && error.response.data) {
              this.setState({ error: error.response.data.error });
            } else {
              this.setState({ error: error.message });
            }

            $('.loader-main').addClass('loader-inactive');
          });
      } else {
        this.props.history.push('/agendamento/escolher-ponto' + (this.reagendar ? '/reagendar' : ''));
      }
    }
  }

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-50"></div>
        <img src={require('../../../resources/imgs/agendamento-icon.png')} className="center" style={{ width: '200px' }} alt="" />

        <div className="space-50"></div>
        <div className="title title-32 text-center text-destaque">Agendamento</div>

        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30 text-center">Preparamos algumas datas, escolha a que melhor se encaixa para você</div>

        <div className="space-32"></div>
        <div className="row margin-22 div-div-center">
          {
            this.state.consultaExecutada ?
              this.state.datas ? this.state.datas.map((i, k) => {
                return <div key={ k }
                      onClick={() => this._selecionar(i)}
                      className={ 'col-xs-3 box-date' + (i.disponivel ? '' : ' box-date-indisponivel') + (this.state.dataEscolhida === i.data ? ' box-date-selected' : '') }
                    >{ i.data }</div>
              }) : <div style={{ color: 'red' }}>Nenhuma data disponível</div>
            : ''
          }
        </div>

        <div className="space-26"></div>
        <div className="row margin-22 div-div-center">
          {
            this.state.consultaExecutada ?
              this.state.pontoEscolhido.idc_agenda_hora && this.state.dataSelecionada ?
                this.state.dataSelecionada.horarios ? this.state.dataSelecionada.horarios.map((i, k) => {
                return <div key={ k } className="col-6 col-xs-6">
                    <div onClick={() => this._selecionarHorario(i.horario, i.disponivel)}
                      style={ k % 2 === 0 ? { float: 'right' } : {}}
                      className={ 'box-hour' + (i.disponivel ? '' : ' box-date-indisponivel') + (this.state.horarioEscolhido === i.horario ? ' box-date-selected' : '') }
                    >{ i.horario }</div>
                  </div>
                }) : <div style={{ color: 'red' }}>Nenhuma horário disponível</div>
              : ''
            : ''
          }
        </div>

        <div className="space-8"></div>
        <div className="div-div-center" style={ this.state.error ? { color: 'red', padding: '6px' } : { display: 'none' } }>
          { this.state.error }
        </div>
        <div className="space-7" style={ this.state.error ? {} : { display: 'none' } }></div>
        <div className="space-50" style={ !this.state.error ? {} : { display: 'none' } }></div>

        <div className="div-center" style={ this.state.datas ? {} : { display: 'none' } }>
          <button className="cf-bt-cadastre margin-40" onClick={() => this._confirmar()} style={{ width: '278px' }}>Confirmar agendamento</button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(EscolherData);
