import axios from 'axios';
import config from '../config/config';

const urlAPI = config.URL_API_BASE;

export const verificarSeNecessitaAtualizarVersaoPwa = async () => {
  let response = await axios.get(urlAPI + '/pwa/driver/version', {});

  let pwa_driver_version = localStorage.getItem('pwa_driver_version');

  localStorage.setItem('pwa_driver_version', response.data.version);

  if (
    typeof pwa_driver_version !== 'undefined' &&
    pwa_driver_version !== null
  ) {
    return pwa_driver_version !== response.data.version;
  }

  return true;
};
