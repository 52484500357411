import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as MotoristaActions from '../../../actions/MotoristaActions';
import * as VeiculoActions from '../../../actions/VeiculoActions';
import * as ParametroSistemaActions from '../../../actions/ParametroSistemaActions';
import * as AplicativoActions from '../../../actions/AplicativoActions';

class Passo2 extends Component {
  componentDidMount() {
    localStorage.removeItem("cadastro_meus_dados");
    localStorage.removeItem("cadastro_meu_endereco");
    localStorage.removeItem("cadastro_minha_senha");
    localStorage.removeItem("cadastro_minhas_viagens");
    localStorage.removeItem("cadastro_meu_tempo_app");
    localStorage.removeItem("cadastro_meu_veiculo");
    localStorage.removeItem("cadastro_minhas_categorias");
    localStorage.removeItem("cadastro_meus_segmentos");
    localStorage.removeItem("cadastro_minhas_notas");
    localStorage.removeItem("cadastro_meus_aplicativos");
    localStorage.removeItem("cadastro_meus_uploads");
    localStorage.removeItem("cadastro_cd_motorista");
    localStorage.removeItem("cadastro_meus_uploads_falhas");

    VeiculoActions.listarFabricantes().then((res) => {
      localStorage.setItem("lista_veiculo_fabricante", JSON.stringify(res));
    });

    ParametroSistemaActions.obter("VEICULO_IDADE_MAXIMA").then((param) => {
      let idadeMaxima = 15;
      if (param && param.valor) {
        idadeMaxima = parseInt(param.valor);
      }

      localStorage.setItem("VEICULO_IDADE_MAXIMA", idadeMaxima);
    });

    AplicativoActions.listarAplicativos().then((res) => {
      localStorage.setItem("zanzar_aplicativos", JSON.stringify(res));
    });

    MotoristaActions.listarIndicacoes().then((res) => {
      localStorage.setItem("lista_cd_indicacao", JSON.stringify(res));
    });
  }

  _abrirPasso3 = () => {
    this.props.history.push("/cadastro/passo-3");
  };

  render() {
    return (
      <div className="page-content header-clear">
        <div className="title title-28 title-bem-vindo">Bem vindo</div>
        <div className="title title-32 title-bem-vindo">Motorista!</div>
        <div className="space-16"></div>
        <div className="cf-texto div-body margin-22">
          Estamos felizes com o seu interesse!
        </div>
        <div className="div-separator"></div>
        <div className="cf-texto cf-title margin-22">Como funciona</div>
        <div className="row bv-wizard margin-left-0 space-16">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 bv-item-max">
            <div className="cf-wizard-img-div">
              <img
                src={require("../../../resources/imgs/svg/circulo-purple.svg")}
                className="cf-wizard-img"
                alt=""
              />
              <div className="cf-texto num-circle">1</div>
            </div>
            <div className="cf-wizard-line-v"></div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="bv-item">Crie seu Login para se conectar;</div>
          </div>
        </div>
        <div className="row bv-wizard margin-left-0">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 bv-item-max">
            <div className="cf-wizard-img-div">
              <img
                src={require("../../../resources/imgs/svg/circulo-purple.svg")}
                className="cf-wizard-img"
                alt=""
              />
              <div className="cf-texto num-circle">2</div>
            </div>
            <div className="cf-wizard-line-v"></div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="bv-item">Preencha os dados complementares;</div>
          </div>
        </div>
        <div className="row bv-wizard margin-left-0">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 bv-item-max">
            <div className="cf-wizard-img-div">
              <img
                src={require("../../../resources/imgs/svg/circulo-purple.svg")}
                className="cf-wizard-img"
                alt=""
              />
              <div className="cf-texto num-circle">3</div>
            </div>
            <div className="cf-wizard-line-v"></div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="bv-item">Aguarde a análise do cadastro;</div>
          </div>
        </div>
        <div className="row bv-wizard margin-left-0">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 bv-item-max">
            <div className="cf-wizard-img-div">
              <img
                src={require("../../../resources/imgs/svg/circulo-purple.svg")}
                className="cf-wizard-img"
                alt=""
              />
              <div className="cf-texto num-circle">4</div>
            </div>
            <div className="cf-wizard-line-v"></div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="bv-item">Agende e realize sua instalação;</div>
          </div>
        </div>
        <div className="row bv-wizard margin-left-0">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 bv-item-max">
            <div className="cf-wizard-img-div">
              <img
                src={require("../../../resources/imgs/svg/circulo-purple.svg")}
                className="cf-wizard-img"
                alt=""
              />
              <div className="cf-texto num-circle">5</div>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="bv-item-destaque" style={{ fontSize: "17px" }}>
              Mais vantagens e benefícios para você!
            </div>
          </div>
        </div>
        <div className="space-8"></div>
        <div className="div-center">
          <button
            className="bt-login margin-30"
            onClick={() => this._abrirPasso3()}
          >
            CONECTAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}

export default withRouter(Passo2);
