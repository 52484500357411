import $ from "jquery";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";

import * as AplicativoActions from '../../../actions/AplicativoActions';
import * as BoxHelper from '../../../helpers/BoxHelper';
import * as formHelp from '../../../helpers/FormHelpMsg';
import * as validations from '../validations';

class Passo8 extends Component {
  constructor(props) {
    super();

    this.state = {
      meu_tempo_app: [],
      meus_aplicativos: [],
      aplicativos: [],
    };

    this.campos = [];
    this.urlProximoPasso = "/cadastro/passo-9";
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();
    let dados = this.state;
    let zanzar_aplicativos = localStorage.getItem("zanzar_aplicativos");

    if (
      typeof zanzar_aplicativos !== "undefined" &&
      zanzar_aplicativos !== null
    ) {
      dados.aplicativos = JSON.parse(zanzar_aplicativos);
    } else {
      AplicativoActions.listarAplicativos().then((res) => {
        dados.aplicativos = res;
        localStorage.setItem("zanzar_aplicativos", JSON.stringify(res));
        this.setState(dados, this._setState);
      });
    }

    let cadastro_meu_tempo_app = localStorage.getItem("cadastro_meu_tempo_app");

    if (
      typeof cadastro_meu_tempo_app !== "undefined" &&
      cadastro_meu_tempo_app !== null
    ) {
      dados.meu_tempo_app = JSON.parse(cadastro_meu_tempo_app);
      dados.meu_tempo_app.forEach((item) => {
        dados["tempo_" + item.cd_aplicativo] = item.tempo;
      });
    }

    let cadastro_meus_aplicativos = localStorage.getItem(
      "cadastro_meus_aplicativos"
    );

    if (
      typeof cadastro_meus_aplicativos !== "undefined" &&
      cadastro_meus_aplicativos !== null
    ) {
      dados.meus_aplicativos = JSON.parse(cadastro_meus_aplicativos);
    }

    this.setState(dados, this._setState);
  }

  _setState = () => {
    BoxHelper.boxFormSetup();

    let appsComNumeroViagens = this.state.aplicativos.filter((item) => {
      return (
        this.state.meus_aplicativos.includes(item.cd_aplicativo) &&
        item.idc_possui_numero_viagens === 1
      );
    });

    if (appsComNumeroViagens === null || appsComNumeroViagens.length === 0) {
      this.urlProximoPasso = "/cadastro/passo-10";
    }
  };

  _gravar = () => {
    let formOk = true;

    this.campos.forEach((item) => {
      $("#" + item)
        .parent()
        .trigger("click");
      $("#" + item).trigger("click");

      if (!validations.validarCampoPreenchido($("#" + item).val(), item)) {
        formOk = false;
      }
    });

    if (formOk) {
      localStorage.setItem(
        "cadastro_meu_tempo_app",
        JSON.stringify(this.state.meu_tempo_app)
      );

      this.props.history.push(this.urlProximoPasso);
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;

    formHelp.hideHelp(campo, "box-input-required");

    validations.validarCampoPreenchido(valor, campo);
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    let cd_aplicativo = campo.slice(6);

    dados.meu_tempo_app = dados.meu_tempo_app.filter((item) => {
      return item.cd_aplicativo !== cd_aplicativo;
    });

    if (valor !== "") {
      dados.meu_tempo_app.push({ cd_aplicativo: cd_aplicativo, tempo: valor });
    }

    dados[campo] = valor;

    await this.setState(dados);
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Tempo de APP</div>
        <div className="cf-texto div-body margin-30 space-26">
          Qual o seu tempo em cada APP?
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "45%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div className="texto-20 margin-25 space-16">
          Digite o tempo em meses:
        </div>
        {this.state.aplicativos.map((item, k) => {
          if (this.state.meus_aplicativos.includes(item.cd_aplicativo)) {
            let id_campo = "tempo_" + item.cd_aplicativo;
            let id_container = id_campo + "InputContainer";
            this.campos.push(id_campo);
            return (
              <div
                className="box-input-only2 margin-25 space-12"
                id={id_container}
                key={k}
              >
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/]}
                  className="box-input-only input2"
                  guide={false}
                  id={id_campo}
                  value={this.state[id_campo]}
                  placeholder="Meses"
                  onChange={(e) => {
                    this._updateField(e);
                  }}
                  onBlur={(e) => {
                    this._validarCampo(e);
                  }}
                />
                <label className="input-label-sm2" htmlFor={id_campo}>
                  Tempo {item.descricao}
                </label>
                <span className="help-inline"></span>
              </div>
            );
          } else {
            return "";
          }
        })}
        <div className="space-34"></div>
        <div className="div-center div-bottom">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(Passo8);
