import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;

export const gravarApuracao = ({ cd_motorista, cd_aplicativo, viagens_realizadas, arquivo }) => {
  const formData = new FormData();
  formData.append('cd_motorista', cd_motorista);
  formData.append('cd_aplicativo', cd_aplicativo);
  formData.append('viagens_realizadas', viagens_realizadas);
  formData.append('file', arquivo);

  return axios.post(urlAPI + '/apuracao-mensal/gravar-apuracao', formData, { headers: { 'content-type': 'multipart/form-data' }});
}

export const obterHistorico = async (cd_motorista) => {
  let response = await axios.get(urlAPI + '/apuracao-mensal/listar-por-motorista/'+ cd_motorista, headers());

  return response.data;
}

export const verificarSePendente = async (cd_motorista) => {
  let response = await axios.get(urlAPI + '/apuracao-mensal/verificar-se-pendente/'+ cd_motorista, headers());

  return response.data;
}

export const verificarSePendentePorAplicativo = async (cd_motorista, cd_aplicativo) => {
  let response = await axios.get(urlAPI + '/apuracao-mensal/verificar-se-pendente/'+ cd_motorista + '/' + cd_aplicativo, headers());

  return response.data;
}
