import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Icones extends Component {
  constructor(props){
    super();
    this.passo = props.passo;
  }

  render() {
    let passos = ['','','','',''];
    passos[this.passo] = '-sel';

    return (
      <div className="row cf-wizard">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div className="cf-wizard-img-div">
            <img onClick={ () => this.props.history.push('/como-funciona/1') } src={require('../../../resources/imgs/svg/como-funciona-ico-1'+ passos[1] +'.svg')} className="cf-wizard-img" alt="" />
          </div>
          <div className="cf-wizard-line"></div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div className="cf-wizard-img-div">
            <img onClick={ () => this.props.history.push('/como-funciona/2') } src={require('../../../resources/imgs/svg/como-funciona-ico-2'+ passos[2] +'.svg')} className="cf-wizard-img" alt="" />
          </div>
          <div className="cf-wizard-line"></div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div className="cf-wizard-img-div">
            <img onClick={ () => this.props.history.push('/como-funciona/3') } src={require('../../../resources/imgs/svg/como-funciona-ico-3'+ passos[3] +'.svg')} className="cf-wizard-img" alt="" />
          </div>
          <div className="cf-wizard-line"></div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div className="cf-wizard-img-div">
            <img onClick={ () => this.props.history.push('/como-funciona/4') } src={require('../../../resources/imgs/svg/como-funciona-ico-4'+ passos[4] +'.svg')} className="cf-wizard-img" alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Icones);
