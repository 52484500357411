import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import TopoSomenteLogo from "../../components/TopoSomenteLogo";
import AtivarComQRCode from "./components/AtivarComQRCode";
import AtivarComCodigo from "./components/AtivarComCodigo";

export default class AtivarEquipamento extends Component {
  render() {
    return (
      <div>
        <TopoSomenteLogo />
        <Switch>
          <Route
            path="/ativar-equipamento/ativar-qr-code"
            component={AtivarComQRCode}
          />
          <Route
            path="/ativar-equipamento/ativar-codigo"
            component={AtivarComCodigo}
          />
        </Switch>
      </div>
    );
  }
}
