import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";

import * as Logger from "../../../helpers/Logger";
import auth from "../../../auth/initAuth";
import * as ClientUtil from "../../../helpers/ClientUtil";
import * as AplicativoActions from '../../../actions/AplicativoActions';
import * as MotoristaActions from '../../../actions/MotoristaActions';
import * as MotoristaAplicativoActions from '../../../actions/MotoristaAplicativoActions';
import * as MotoristaComplementoActions from '../../../actions/MotoristaComplementoActions';
import * as BoxHelper from '../../../helpers/BoxHelper';

class Passo13 extends Component {
  constructor(props) {
    super();

    this.state = {
      aceita_receber_whatsapp: 0,
      email: null,
      meus_aplicativos: [],
      minhas_notas: [],
      aplicativos: [],
    };

    this.campos = [];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();

    let dados = this.state;
    let zanzar_aplicativos = localStorage.getItem("zanzar_aplicativos");

    if (
      typeof zanzar_aplicativos !== "undefined" &&
      zanzar_aplicativos !== null
    ) {
      dados.aplicativos = JSON.parse(zanzar_aplicativos);
    } else {
      AplicativoActions.listarAplicativos().then((res) => {
        dados.aplicativos = res;
        localStorage.setItem("zanzar_aplicativos", JSON.stringify(res));
        this.setState(dados, this._setState);
      });
    }

    let cadastro_minhas_notas = localStorage.getItem("cadastro_minhas_notas");
    if (
      typeof cadastro_minhas_notas !== "undefined" &&
      cadastro_minhas_notas !== null
    ) {
      dados.minhas_notas = JSON.parse(cadastro_minhas_notas);
      dados.minhas_notas.forEach((item) => {
        dados["nota_" + item.cd_aplicativo] = item.nota;
      });
    }

    let cadastro_meus_aplicativos = localStorage.getItem(
      "cadastro_meus_aplicativos"
    );

    if (
      typeof cadastro_meus_aplicativos !== "undefined" &&
      cadastro_meus_aplicativos !== null
    ) {
      dados.meus_aplicativos = JSON.parse(cadastro_meus_aplicativos);
    }

    this.setState(dados, this._setState);
  }

  _gravar = async () => {
    if (this.state.aceita_receber_whatsapp === 0) {
      let dados = this.state;
      dados.error = "Selecione uma das opções acima.";
      this.setState(dados);
    } else {
      $(".loader-main").removeClass("loader-inactive");

      let deviceinfo = await ClientUtil.getDeviceInfo();

      Logger.info("GRAVAR MOTORISTA : INICIO");
      Logger.info("GRAVAR MOTORISTA : APARELHO : " + deviceinfo);

      let meus_dados = null;
      let meu_endereco = null;
      let minha_senha = null;
      let meus_aplicativos = null;
      let minhas_viagens = null;
      let meu_tempo_app = null;
      let meu_veiculo = null;
      let minhas_categorias = null;
      let meus_segmentos = null;
      let minhas_notas = null;
      let cadastro_meus_dados = localStorage.getItem("cadastro_meus_dados");
      let cadastro_meu_endereco = localStorage.getItem("cadastro_meu_endereco");
      let cadastro_minha_senha = localStorage.getItem("cadastro_minha_senha");
      let cadastro_meus_aplicativos = localStorage.getItem("cadastro_meus_aplicativos");
      let cadastro_minhas_viagens = localStorage.getItem("cadastro_minhas_viagens");
      let cadastro_meu_tempo_app = localStorage.getItem("cadastro_meu_tempo_app");
      let cadastro_meu_veiculo = localStorage.getItem("cadastro_meu_veiculo");
      let cadastro_minhas_categorias = localStorage.getItem("cadastro_minhas_categorias");
      let cadastro_meus_segmentos = localStorage.getItem("cadastro_meus_segmentos");
      let cadastro_minhas_notas = localStorage.getItem("cadastro_minhas_notas");

      if (
        typeof cadastro_meus_dados !== "undefined" &&
        cadastro_meus_dados !== null
      ) {
        meus_dados = JSON.parse(cadastro_meus_dados);
      }
      
      if (
        typeof cadastro_meu_endereco !== "undefined" &&
        cadastro_meu_endereco !== null
      ) {
        meu_endereco = JSON.parse(cadastro_meu_endereco);
      }

      if (
        typeof cadastro_minha_senha !== "undefined" &&
        cadastro_minha_senha !== null
      ) {
        minha_senha = JSON.parse(cadastro_minha_senha);
      }

      if (
        typeof cadastro_meus_aplicativos !== "undefined" &&
        cadastro_meus_aplicativos !== null
      ) {
        meus_aplicativos = JSON.parse(cadastro_meus_aplicativos);
      }

      if (
        typeof cadastro_minhas_viagens !== "undefined" &&
        cadastro_minhas_viagens !== null
      ) {
        minhas_viagens = JSON.parse(cadastro_minhas_viagens);
      }

      if (
        typeof cadastro_meu_tempo_app !== "undefined" &&
        cadastro_meu_tempo_app !== null
      ) {
        meu_tempo_app = JSON.parse(cadastro_meu_tempo_app);
      }

      if (
        typeof cadastro_meu_veiculo !== "undefined" &&
        cadastro_meu_veiculo !== null
      ) {
        meu_veiculo = JSON.parse(cadastro_meu_veiculo);
      }

      if (
        typeof cadastro_minhas_categorias !== "undefined" &&
        cadastro_minhas_categorias !== null
      ) {
        minhas_categorias = JSON.parse(cadastro_minhas_categorias);
      }

      if (
        typeof cadastro_meus_segmentos !== "undefined" &&
        cadastro_meus_segmentos !== null
      ) {
        meus_segmentos = JSON.parse(cadastro_meus_segmentos);
      }

      if (
        typeof cadastro_minhas_notas !== "undefined" &&
        cadastro_minhas_notas !== null
      ) {
        minhas_notas = JSON.parse(cadastro_minhas_notas);
      }

      let { nome, email, cpf, telefone, cd_indicacao } = meus_dados;
      let { cep, logradouro, numero, complemento, bairro, cidade, uf } = meu_endereco;
      let { senha } = minha_senha;
      let pais;

      MotoristaActions
        .cadastrarMotorista({
          nome,
          email,
          cpf,
          telefone,
          cep,
          logradouro,
          numero,
          complemento,
          bairro,
          cidade,
          uf,
          pais,
          senha,
          aceita_receber_whatsapp: this.state.aceita_receber_whatsapp === 1,
          cd_indicacao,
        })
        .then((response) => {
          localStorage.removeItem("cadastro_meus_dados");
          localStorage.removeItem("cadastro_meu_endereco");
          localStorage.removeItem("cadastro_minha_senha");
          localStorage.removeItem("cadastro_meu_veiculo");
          localStorage.removeItem("cadastro_minhas_viagens");
          localStorage.removeItem("cadastro_meu_tempo_app");
          localStorage.removeItem("cadastro_minhas_categorias");
          localStorage.removeItem("cadastro_meus_segmentos");
          localStorage.removeItem("cadastro_minhas_notas");
          localStorage.removeItem("cadastro_meus_uploads_falhas");

          let cd_motorista = response.data.cd_motorista;
          localStorage.setItem("cadastro_cd_motorista", cd_motorista);

          let cd_veiculo_fabricante = meu_veiculo.veiculo_fabricante;
          let veiculo_modelo = meu_veiculo.veiculo_modelo;
          let veiculo_ano = meu_veiculo.veiculo_ano;
          let veiculo_placa = meu_veiculo.veiculo_placa;

          MotoristaComplementoActions
            .cadastrarMotoristaComplemento({
              cd_motorista,
              cd_veiculo_fabricante,
              veiculo_modelo,
              veiculo_ano,
              veiculo_placa,
            }).catch((error) => {
              console.log(error.message);
            });

          meus_aplicativos.forEach((cd_aplicativo) => {
            let tempo =
              meu_tempo_app === null
                ? []
                : meu_tempo_app.filter((item) => {
                    return (
                      parseInt(item.cd_aplicativo) === parseInt(cd_aplicativo)
                    );
                  });
            let viagens =
              minhas_viagens === null
                ? []
                : minhas_viagens.filter((item) => {
                    return (
                      parseInt(item.cd_aplicativo) === parseInt(cd_aplicativo)
                    );
                  });
            let categoria =
              minhas_categorias === null
                ? []
                : minhas_categorias.filter((item) => {
                    return (
                      parseInt(item.cd_aplicativo) === parseInt(cd_aplicativo)
                    );
                  });
            let segmento =
              meus_segmentos === null
                ? []
                : meus_segmentos.filter((item) => {
                    return (
                      parseInt(item.cd_aplicativo) === parseInt(cd_aplicativo)
                    );
                  });
            let nota =
              minhas_notas === null
                ? []
                : minhas_notas.filter((item) => {
                    return (
                      parseInt(item.cd_aplicativo) === parseInt(cd_aplicativo)
                    );
                  });

            let tempo_aplicativo = tempo.length > 0 ? tempo[0].tempo : null;
            let viagens_realizadas = viagens.length > 0 ? viagens[0].numero_viagens : null;
            let cd_aplicativo_categoria = categoria.length > 0 ? categoria[0].cd_categoria : null;
            let cd_aplicativo_segmento = segmento.length > 0 ? segmento[0].cd_segmento : null;
            let nota_motorista = nota.length > 0 ? nota[0].nota : null;

            MotoristaAplicativoActions.cadastrarMotoristaAplicativo({
              cd_motorista,
              cd_aplicativo,
              cd_aplicativo_categoria,
              cd_aplicativo_segmento,
              viagens_realizadas,
              tempo_aplicativo,
              nota_motorista,
            }).catch((error) => {
              console.log(error.message);
            });
          });
          
          auth.signup(email, senha, function (err) {
            if (err) {
              console.log(err.description);
            }
          });

          Logger.info(`GRAVAR MOTORISTA : FIM : cd_motorista ${cd_motorista}`);

          $(".loader-main").addClass("loader-inactive");

          this.props.history.push("/cadastro/sucesso");
        })
        .catch((error) => {
          let errorMsg =
            error.response && error.response.data
              ? error.response.data.error
              : error.message;

          this.setState({ error: errorMsg });

          Logger.error("GRAVAR MOTORISTA : " + errorMsg);

          $(".loader-main").addClass("loader-inactive");
        });
    }
  };

  _updateField = (event) => {
    let dados = this.state;
    let valor = parseInt(event.target.value);

    dados.aceita_receber_whatsapp = event.target.checked ? valor : (valor === 1 ? 2 : 1);

    this.setState(dados);
  }

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">WhatsApp</div>
        <div className="cf-texto div-body margin-30 space-26">
          Aceita receber mensagens no WhatsApp?
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "72%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>

        <div className="checkbox-nice margin-25 space-8">
          <input
            className="checkbox-nice"
            type="checkbox"
            name="aceita_receber_whatsapp1"
            id="aceita_receber_whatsapp1"
            value="1"
            onChange={(e) => {
              this._updateField(e);
            }}
            checked={ this.state.aceita_receber_whatsapp === 1 }
          />
          <label className="texto-20" htmlFor="aceita_receber_whatsapp1">
            SIM
          </label>
        </div>

        <div className="checkbox-nice margin-25 space-8">
          <input
            className="checkbox-nice"
            type="checkbox"
            name="aceita_receber_whatsapp2"
            id="aceita_receber_whatsapp2"
            value="2"
            onChange={(e) => {
              this._updateField(e);
            }}
            checked={ this.state.aceita_receber_whatsapp === 2 }
          />
          <label className="texto-20" htmlFor="aceita_receber_whatsapp2">
            NÃO
          </label>
        </div>

        <div className="space-16"></div>
        <div
          className="alert alert-danger text-center margin-25"
          style={this.state.error ? { padding: 6 } : { display: "none" }}
        >
          { this.state.error }
        </div>
        <div className="space-34"></div>
        <div className="div-center div-bottom">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            CONTINUAR 
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-71"></div>
      </div>
    );
  }
}

export default withRouter(Passo13);
