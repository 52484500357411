import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Passo1 extends Component {
    render() {
        return (
            <div>
                <div className="cf-texto cf-sub-title">Cadastre-se</div>
                <div className="cf-texto cf-body">Preencha seus dados para realizar seu cadastro.</div>
            </div>
        );
    }
}

export default withRouter(Passo1);
