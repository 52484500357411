import React, { Component } from "react";

export default class StatusReprovado extends Component {
  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-8"></div>
        <img
          src={require("../../../resources/imgs/svg/aprovado-icon.svg")}
          className="center"
          style={{ width: "156px" }}
          alt=""
        />
        <div className="space-50"></div>
        <div className="title title-32 text-center text-destaque">
          Banco de Dados
        </div>
        <div className="space-26"></div>
        <div className="div-separator-dt">
          <div
            className="div-progress div-progress-dt"
            style={{ width: "100%" }}
          ></div>
          <div className="div-progress-bullet div-progress-bullet-100"></div>
        </div>
        <div className="cf-texto div-body margin-30 text-center">
          Seu cadastro está em nosso banco de dados. Em breve entraremos em
          contato.
        </div>
      </div>
    );
  }
}
