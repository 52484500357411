import $ from "jquery";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";

import * as BoxHelper from '../../../helpers/BoxHelper';
import * as formHelp from '../../../helpers/FormHelpMsg';
import * as validations from '../validations';

class Passo3 extends Component {
  constructor(props) {
    super();

    this.state = {
      lista_cd_indicacao: null,
      meus_dados: {
        nome: "",
        email: "",
        cpf: "",
        telefone: "",
        cd_indicacao: "",
      },
    };

    this.campos = ["nome", "email", "cpf", "telefone"];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();
    let cadastro_meus_dados = localStorage.getItem("cadastro_meus_dados");
    let lista_cd_indicacao = localStorage.getItem("lista_cd_indicacao");

    if (
      typeof cadastro_meus_dados !== "undefined" &&
      cadastro_meus_dados !== null
    ) {
      this.setState({ meus_dados: JSON.parse(cadastro_meus_dados) });
    }

    if (
      typeof lista_cd_indicacao !== "undefined" &&
      lista_cd_indicacao !== null
    ) {
      this.setState({ lista_cd_indicacao: JSON.parse(lista_cd_indicacao) });
    }
  }

  _gravar = async () => {
    let formOk = true;

    this.campos.forEach((item) => {
      $("#" + item)
        .parent()
        .trigger("click");
      $("#" + item).trigger("click");
    });

    if (!validations.validarCampoNome(this.state.meus_dados.nome)) {
      formOk = false;
    }

    let cpfOK = await validations.validarCampoCPF(this.state.meus_dados.cpf);
    if (!cpfOK) {
      formOk = false;
    }

    if (!validations.validarCampoTelefone(this.state.meus_dados.telefone)) {
      formOk = false;
    }

    let emailOK = await validations.validarCampoEmail(this.state.meus_dados.email);
    if (!emailOK) {
      formOk = false;
    }

    if (!this.state.meus_dados.cd_indicacao) {
      formHelp.showHelp("cd_indicacao","Este campo deve ser preenchido.","box-input-required");
      formOk = false;
    }

    if (formOk) {
      let meus_dados = this.state.meus_dados;

      if (meus_dados.cd_indicacao === '-') {
        meus_dados.cd_indicacao = null;
      }

      localStorage.setItem(
        "cadastro_meus_dados",
        JSON.stringify(meus_dados)
      );

      this.props.history.push("/cadastro/passo-4");
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    formHelp.hideHelp(campo, "box-input-required");
    if (campo === "nome") {
      validations.validarCampoNome(valor);
    } else if (campo === "email") {
      validations.validarCampoEmail(valor);
    } else if (campo === "cpf") {
      validations.validarCampoCPF(valor);
    } else if (campo === "telefone") {
      validations.validarCampoTelefone(valor);
    }
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    dados.meus_dados[campo] = valor;
    await this.setState(dados);
  };

  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Meus Dados</div>
        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30">
          Confira seus dados para continuar
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "9%" }}>
            <div className="div-progress-bullet bg-purple"></div>
          </div>
          <div className="div-progress-bullet"></div>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="nomeInputContainer"
        >
          <input
            type="text"
            id="nome"
            value={this.state.meus_dados.nome}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
            className="box-input-only input2"
          />
          <label
            className={
              this.state.meus_dados.nome === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="nome"
          >
            Nome completo
          </label>
          <span className="help-inline"></span>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="emailInputContainer"
        >
          <input
            type="text"
            id="email"
            value={this.state.meus_dados.email}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
            className="box-input-only input2"
          />
          <label
            className={
              this.state.meus_dados.email === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="email"
          >
            E-mail
          </label>
          <span className="help-inline"></span>
        </div>
        <div
          className="box-input-only2 margin-25 space-12"
          id="cpfInputContainer"
        >
          <MaskedInput
            mask={[/\d/,/\d/,/\d/,".",/\d/,/\d/,/\d/,".",/\d/,/\d/,/\d/,"-",/\d/,/\d/,]}
            className="box-input-only input2"
            guide={false}
            id="cpf"
            value={this.state.meus_dados.cpf}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <label
            className={
              this.state.meus_dados.cpf === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="cpf"
          >
            CPF
          </label>
          <span className="help-inline"></span>
        </div>

        <div
          className="box-input-only2 margin-25 space-12"
          id="telefoneInputContainer"
        >
          <MaskedInput
            mask={["(",/[1-9]/,/\d/,")"," ",/\d/," ",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/,]}
            className="box-input-only input2"
            guide={false}
            id="telefone"
            value={this.state.meus_dados.telefone}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <label
            className={
              this.state.meus_dados.telefone === ""
                ? "input-label2"
                : "input-label-sm2"
            }
            htmlFor="telefone"
          >
            Telefone celular
          </label>
          <span className="help-inline"></span>
        </div>

        {
          !this.state.lista_cd_indicacao ? '' :
            <div
              className="box-input-only2 margin-25 space-16"
              id="cd_indicacaoInputContainer"
            >
              <select
                id="cd_indicacao"
                value={ this.state.meus_dados.cd_indicacao }
                onChange={(e) => this._updateField(e)}
                onBlur={(e) => this._validarCampo(e)}
                className="box-input-only input2"
              >
                <option value=""></option>
                <option value="-">Não possuo código de indicação</option>
                {
                  this.state.lista_cd_indicacao.map((i, k) => {
                    return (
                      <option key={ k } value={ i.cd_indicacao }>{ i.cd_indicacao }</option>
                    );
                  })
                }
              </select>
              <label
                className={
                  this.state.meus_dados.cd_indicacao === ""
                    ? "input-label2"
                    : "input-label-sm2"
                }
                htmlFor="cd_indicacao"
              >
                Você possui um código de indicação?
              </label>
              <span className="help-inline"></span>
            </div>
        }

        <div className="space-34"></div>
        <div className="div-center">
          <button
            id="btn"
            className="bt-login margin-30"
            onClick={() => this._gravar()}
          >
            CONTINUAR{" "}
            <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-300"></div>
      </div>
    );
  }
}

export default withRouter(Passo3);
