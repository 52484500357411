import React, { Component } from 'react';
import TopoSemMenu from '../../components/TopoSemMenu';
import Termo from './components/Termo';

export default class TermosServico extends Component {
    render() {
        return (
            <div>
                <TopoSemMenu/>
                <Termo/>
            </div>
        );
    }
}