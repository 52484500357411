import React, { Component } from 'react';

export default class MenuInferior extends Component {
  constructor(props){
    super();

    this.iconeCenter = props.iconeCenter;
    this.iconeSelected = props.iconeSelected;
  }

  render() {
    let iconesClass = ['','icon-menu hcenter','icon-menu hcenter','icon-menu hcenter'];
    let icones = ['','','',''];

    icones[this.iconeSelected] = '-sel';
    iconesClass[this.iconeSelected] += ' icon-sel';

    return (
      <div className="navigation three-icons">
        <a href="/meu-perfil" className="nav-icon">
          <div className={ iconesClass[1] }><i className="far fa-user vcenter"></i></div>
        </a>
        <a href="/" className="nav-icon">
          <div className={ iconesClass[2] }>
            <img src={require('../resources/imgs/menu-ico-'+ this.iconeCenter + icones[2] +'.png')} className="center vcenter" style={{ height: '50%', width: 'auto' }} alt="" />
          </div>
        </a>
        <a href="/fale-conosco/formulario" className="nav-icon">
          <div className={ iconesClass[3] }>
            <img src={require('../resources/imgs/menu-ico-2'+ icones[3] +'.png')} className="center vcenter" heigth="25" alt="" />
          </div>
        </a>
      </div>
    );
  }
}