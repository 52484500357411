import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AtivarEquipamentoQrCode extends Component {
  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-50"></div>
        <img
          src={require("../../../resources/imgs/svg/qr-code-icon.svg")}
          className="center"
          style={{ width: "215px" }}
          alt=""
        />
        <div className="space-16"></div>
        <div className="title title-32 text-center text-destaque">
          Ative a TV Zanzar
        </div>
        <div className="space-16"></div>
        <div className="cf-texto div-body margin-30 text-center">
          Ative o seu equipamento utilizando
          <br />o código apresentado na tela Zanzar
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <Link to={"/ativar-equipamento/ativar-qr-code"}>
            <button className="cf-bt-cadastre margin-30">
              ATIVAR TV ZANZAR{" "}
              <i className="fas fa-chevron-right icon-14 float-right"></i>
            </button>
          </Link>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
