import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class TopoSomenteLogo extends Component {
  _goHome = () => {
    this.props.history.push('/home');
  }

  render() {
    return (
      <div className="header header2 header-fixed header-logo-center">
        <div className="header-title">
          <img src={require('../resources/imgs/logo-zanzar.png')} className="logo-topo" alt="" onClick={ () => this._goHome() } />
        </div>
      </div>
    );
  }
}

export default withRouter(TopoSomenteLogo);
