import axios from 'axios';
import config from '../config/config';

const headers = () => {
  return {
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
    }
  };
};

const urlAPI = config.URL_API_BASE;

export const ativarComQrCode = ({ cd_motorista, cod_player, nome_player }) => {
  return axios.post(urlAPI + '/player/ativar-qr-code', {
    cd_motorista: cd_motorista,
    cod_player: cod_player,
    nome_player: nome_player
  }, headers());
}

export const ativarComCodigo = ({ cd_motorista, cd_tv_zanzar }) => {
  return axios.post(urlAPI + '/player/ativar-codigo-tv', {
    cd_motorista: cd_motorista,
    cd_tv_zanzar: cd_tv_zanzar
  }, headers());
}
