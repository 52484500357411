import $ from "jquery";
import React, { Component } from "react";
import { Redirect } from "react-router";
import MaskedInput from "react-text-mask";
import Formatters from "../../../helpers/Formatters";

const Actions = require("../../../actions/MotoristaActions");
const BoxHelper = require("../../../helpers/BoxHelper");
const formHelp = require("../../../helpers/FormHelpMsg");
const validations = require("../validations");

export default class Formulario extends Component {
  constructor(props) {
    super();
    this.state = {
      meus_dados: {
        nome: "",
        email: "",
        cpf: "",
        telefone: "",
      },
      cd_motorista: null,
      redirect: false,
    };
    this.redirect = true;
    this.campos = ["nome", "email", "cpf", "telefone"];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();
    let motorista_logado = localStorage.getItem("motorista_logado");
    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);
      Actions.obterMotorista(motorista_logado.cd_motorista).then((res) => {
        let meus_dados = {
          nome: res.nome,
          email: res.email,
          cpf: Formatters.formataCPF(res.cpf),
          telefone: Formatters.formataNumeroTelefone(res.tel_celular),
        };
        this.setState(
          {
            meus_dados: meus_dados,
            cd_motorista: motorista_logado.cd_motorista,
            redirect: false,
          },
          () => {
            this.campos.forEach(function (item) {
              if ($("#" + item).val() !== "")
                $("#" + item)
                  .parent()
                  .trigger("click");
            });
          }
        );
      });
    } else {
      window.location = "/login";
    }
  }

  _gravar = async () => {
    let formOk = true;

    this.campos.forEach(function (item) {
      $("#" + item)
        .parent()
        .trigger("click");
    });

    if (!validations.validarCampoNome($("#nome").val())) {
      formOk = false;
    }

    if (!validations.validarCampoCPF($("#cpf").val(), this.state.cd_motorista)) {
      formOk = false;
    }

    if (!validations.validarCampoTelefone($("#telefone").val())) {
      formOk = false;
    }

    let emailOK = await validations.validarCampoEmail(
      $("#email").val(),
      this.state.cd_motorista
    );

    if (!emailOK) {
      formOk = false;
    }

    if (formOk) {
      let cd_motorista = this.state.cd_motorista;
      let nome = this.state.meus_dados.nome;
      let email = this.state.meus_dados.email;
      let cpf = this.state.meus_dados.cpf;
      let tel_celular = this.state.meus_dados.telefone;

      try {
        await Actions.atualizarDadosMotorista({
          cd_motorista,
          nome,
          email,
          cpf,
          tel_celular,
        });

        this.setState({ redirect: true });
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    formHelp.hideHelp(campo, "box-input-required");
    if (campo === "nome") {
      validations.validarCampoNome(valor);
    } else if (campo === "email") {
      validations.validarCampoEmail(valor, this.state.cd_motorista);
    } else if (campo === "cpf") {
      validations.validarCampoCPF(valor, this.state.cd_motorista);
    } else if (campo === "telefone") {
      validations.validarCampoTelefone(valor);
    }
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    dados.meus_dados[campo] = valor;
    await this.setState(dados);
  };

  render() {
    if (this.state.redirect && this.redirect) {
      this.redirect = false;
      return <Redirect to="/dados-pessoais/sucesso" />;
    }
    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Meus Dados</div>
        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30">
          Confira seus dados para continuar
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "16.66%" }}></div>
          <div className="div-progress-bullet"></div>
        </div>
        <div className="box-input margin-25" id="nomeInputContainer">
          <label className="input-label" htmlFor="nome">
            Nome completo
          </label>
          <input
            type="text"
            id="nome"
            className="input input-hide"
            value={this.state.meus_dados.nome}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="box-input margin-25" id="emailInputContainer">
          <label className="input-label" htmlFor="email">
            E-mail
          </label>
          <input
            type="text"
            id="email"
            className="input input-hide"
            value={this.state.meus_dados.email}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="box-input margin-25" id="cpfInputContainer">
          <label className="input-label" htmlFor="cpf">
            CPF
          </label>
          <MaskedInput
            mask={[
              /\d/,
              /\d/,
              /\d/,
              ".",
              /\d/,
              /\d/,
              /\d/,
              ".",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
            ]}
            className="input input-hide"
            guide={false}
            id="cpf"
            value={this.state.meus_dados.cpf}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="box-input margin-25" id="telefoneInputContainer">
          <label className="input-label" htmlFor="telefone">
            Telefone celular
          </label>
          <MaskedInput
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              ")",
              " ",
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            className="input input-hide"
            guide={false}
            id="telefone"
            value={this.state.meus_dados.telefone}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            SALVAR DADOS
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
