import React, { Component } from "react";

export default class StatusPendente extends Component {
  render() {
    return (
      <div className="page-content header-clear cadastro">
        <div className="space-26"></div>
        <img
          src={require("../../../resources/imgs/svg/confirmacao-icon.svg")}
          className="center"
          style={{ width: "200px" }}
          alt=""
        />
        <div className="space-50"></div>
        <div className="title title-32 text-center text-destaque">
          Estamos quase lá!
        </div>
        <div className="space-26"></div>
        <div className="div-separator-dt">
          <div
            className="div-progress div-progress-dt"
            style={{ width: "83.33%" }}
          ></div>
          <div className="div-progress-bullet div-progress-bullet-dt"></div>
        </div>
        <div className="cf-texto div-body margin-30 text-center">
          Você será notificado quando sua solicitação for avaliada! Fique
          ligado.
        </div>
        <div className="space-34"></div>
        <div className="cf-texto div-body margin-30 text-center label-purple">
          Em breve você será notificado!
        </div>
      </div>
    );
  }
}
