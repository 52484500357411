import React, { Component } from "react";
import { obterStatusCadastro } from "../../actions/MotoristaActions";
import TopoSemMenuComLogoutEVoltar from "../../components/TopoSemMenuComLogoutEVoltar";
import StatusAprovado from "./components/StatusAprovado";
import StatusPendente from "./components/StatusPendente";
import StatusReprovado from "./components/StatusReprovado";

export default class CadastroAcompanhamento extends Component {
  constructor(props) {
    super();
    this.state = {
      Status: StatusPendente,
    };
  }

  componentDidMount = async () => {
    let motorista_logado = localStorage.getItem("motorista_logado");
    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);
      let status = null;
      let res_status = await obterStatusCadastro(motorista_logado.email);
      if ([3, 4, 6, 7, 13, 14].includes(res_status.idc_status)) {
        status = StatusPendente;
      } else if ([12].includes(res_status.idc_status)) {
      } else if ([2, 9, 10, 11].includes(res_status.idc_status)) {
        status = StatusReprovado;
      } else if ([1, 5].includes(res_status.idc_status))
        status = StatusAprovado;
      else if (res_status.idc_status === 8) {
        if (res_status.idc_termo_aceito === 1) {
          window.location = "/home";
        } else {
          status = StatusAprovado;
        }
      } else {
        window.location = "/login";
      }
      this.setState({ Status: status });
    } else {
      window.location = "/login";
    }
  };

  render() {
    return (
      <div>
        <TopoSemMenuComLogoutEVoltar />
        <this.state.Status />
      </div>
    );
  }
}
