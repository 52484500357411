import version from './version';

let config = Object.assign({
  TIPO_SISTEMA: window.config.TIPO_SISTEMA,
  URL_API_BASE: window.config.URL_API_BASE,
  RECAPTCHA: window.config.RECAPTCHA,
  
  LOGGLY_TOKEN: window.config.LOGGLY_TOKEN,
  LOGGLY_SUB_DOMAIN: window.config.LOGGLY_SUB_DOMAIN,
  LOGGLY_TAGS: window.config.LOGGLY_TAGS,

  AUTH0_API_CLIENT_ID: window.config.AUTH0_API_CLIENT_ID,
  AUTH0_API_DOMAIN: window.config.AUTH0_API_DOMAIN,
  AUTH0_MOTORISTA_DB: window.config.AUTH0_MOTORISTA_DB,

  NEWRELIC_LICENSE_KEY: window.config.NEWRELIC_LICENSE_KEY,
  NEWRELIC_ACCOUNT_ID: window.config.NEWRELIC_ACCOUNT_ID,
  NEWRELIC_APP_ID: window.config.NEWRELIC_APP_ID,
}, version);

export default config;
