import isValidCep from "@brazilian-utils/is-valid-cep";
import $ from "jquery";
import React, { Component } from "react";
import { Redirect } from "react-router";
import MaskedInput from "react-text-mask";
import Formatters from "../../../helpers/Formatters";

const MotoristaActions = require("../../../actions/MotoristaActions");
const BoxHelper = require("../../../helpers/BoxHelper");
const formHelp = require("../../../helpers/FormHelpMsg");
const validations = require("../validations");

export default class Formulario extends Component {
  constructor(props) {
    super();
    this.cepNaoEncontrado = false;
    this.ultimoCEP = null;
    this.state = {
      meu_endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
      },
      redirect: false,
    };
    this.redirect = true;
    this.campos = ["cep", "logradouro", "numero", "complemento"];
  }

  componentDidMount() {
    BoxHelper.boxFormSetup();
    let motorista_logado = localStorage.getItem("motorista_logado");
    if (typeof motorista_logado !== "undefined" && motorista_logado !== null) {
      motorista_logado = JSON.parse(motorista_logado);
      MotoristaActions.obterMotorista(motorista_logado.cd_motorista).then(
        (res) => {
          let meu_endereco = {
            cep: Formatters.formataCep(res.end_cep),
            logradouro: res.end_logradouro,
            numero: res.end_numero,
            complemento: res.end_complemento,
            bairro: res.end_bairro,
            cidade: res.end_cidade,
            uf: res.end_uf,
          };
          this.setState(
            {
              meu_endereco: meu_endereco,
              cd_motorista: motorista_logado.cd_motorista,
              redirect: false,
            },
            () => {
              this.campos.forEach(function (item) {
                if ($("#" + item).val() !== "")
                  $("#" + item)
                    .parent()
                    .trigger("click");
              });
            }
          );
        }
      );
    } else {
      window.location = "/login";
    }
  }

  _gravar = async () => {
    let formOk = true;
    this.campos.forEach(function (item) {
      $("#" + item)
        .parent()
        .trigger("click");
    });
    if (!validations.validarCampoCEP($("#cep").val(), this.cepNaoEncontrado)) {
      formOk = false;
    }
    if (!validations.validarCampoLogradouro($("#logradouro").val())) {
      formOk = false;
    }
    if (!validations.validarCampoNumero($("#numero").val())) {
      formOk = false;
    }
    if (formOk) {
      let cd_motorista = this.state.cd_motorista;
      let cep = this.state.meu_endereco.cep;
      let logradouro = this.state.meu_endereco.logradouro;
      let numero = this.state.meu_endereco.numero;
      let complemento = this.state.meu_endereco.complemento;
      let bairro = this.state.meu_endereco.bairro;
      let cidade = this.state.meu_endereco.cidade;
      let uf = this.state.meu_endereco.uf;
      try {
        await MotoristaActions.atualizarEndereco({
          cd_motorista,
          cep,
          logradouro,
          numero,
          complemento,
          bairro,
          cidade,
          uf,
        });
        this.setState({ redirect: true });
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  pesquisarCEP = (cep) => {
    if (isValidCep(cep)) {
      if (this.ultimoCEP !== cep) {
        this.ultimoCEP = cep;
        MotoristaActions.pesquisarCep(cep)
          .then((res) => {
            let dados = this.state;
            dados.meu_endereco.logradouro = res.logradouro
              ? res.logradouro
              : "";
            dados.meu_endereco.bairro = res.bairro ? res.bairro : "";
            dados.meu_endereco.cidade = res.localidade ? res.localidade : "";
            dados.meu_endereco.uf = res.uf ? res.uf : "";
            this.setState(dados);
            $("#logradouro").parent().trigger("click");
            $("#numero").parent().trigger("click");
            $("#numero").focus();
            this.cepNaoEncontrado = false;
          })
          .catch((erro) => {
            formHelp.showHelp(
              "cep",
              "CEP nao encontrado",
              "box-input-required"
            );
            this.cepNaoEncontrado = true;
            let dados = this.state;
            dados.meu_endereco.logradouro = "";
            dados.meu_endereco.bairro = "";
            dados.meu_endereco.cidade = "";
            dados.meu_endereco.uf = "";
            this.setState(dados);
          });
      }
    }
  };

  _validarCampo = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    formHelp.hideHelp(campo, "box-input-required");
    if (campo === "cep") {
      validations.validarCampoCEP(valor, this.cepNaoEncontrado);
    } else if (campo === "logradouro") {
      validations.validarCampoLogradouro(valor);
    } else if (campo === "numero") {
      validations.validarCampoNumero(valor);
    }
  };

  _updateField = async (event) => {
    let campo = event.target.id;
    let valor = event.target.value;
    let dados = this.state;
    if (campo === "cep") {
      this.pesquisarCEP(valor);
    }
    dados.meu_endereco[campo] = valor;
    await this.setState(dados);
  };

  render() {
    if (this.state.redirect && this.redirect) {
      this.redirect = false;
      return <Redirect to="/endereco/sucesso" />;
    }

    return (
      <div className="page-content header-clear cadastro">
        <div className="title title-36">Meu Endereço</div>
        <div className="space-26"></div>
        <div className="cf-texto div-body margin-30">
          Complete seus dados para continuar
        </div>
        <div className="div-separator">
          <div className="div-progress" style={{ width: "33.33%" }}></div>
          <div className="div-progress-bullet"></div>
        </div>
        <div className="box-input margin-25" id="cepInputContainer">
          <label className="input-label" htmlFor="cep">
            CEP
          </label>
          <MaskedInput
            mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            className="input input-hide"
            guide={false}
            id="cep"
            value={this.state.meu_endereco.cep}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="space-16"></div>
        <div className="box-input margin-25" id="logradouroInputContainer">
          <label className="input-label" htmlFor="logradouro">
            Logradouro
          </label>
          <input
            type="text"
            id="logradouro"
            className="input input-hide"
            value={this.state.meu_endereco.logradouro}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="space-16"></div>
        <div className="box-input margin-25" id="numeroInputContainer">
          <label className="input-label" htmlFor="numero">
            Número
          </label>
          <MaskedInput
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            className="input input-hide"
            guide={false}
            id="numero"
            value={this.state.meu_endereco.numero}
            onChange={(e) => {
              this._updateField(e);
            }}
            onBlur={(e) => {
              this._validarCampo(e);
            }}
          />
          <span className="help-inline"></span>
        </div>
        <div className="space-16"></div>
        <div className="box-input margin-25">
          <label className="input-label" htmlFor="complemento">
            Complemento
          </label>
          <input
            type="text"
            id="complemento"
            className="input input-hide"
            value={this.state.meu_endereco.complemento}
            onChange={(e) => {
              this._updateField(e);
            }}
          />
        </div>
        <div className="space-34"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._gravar()}>
            SALVAR DADOS
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
