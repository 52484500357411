import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

class Sucesso extends Component {
    constructor(props){
        super();

        this.state = {
            gerarAgendamento: props.location.state.gerarAgendamento
        };
    }

    render() {
        return (
            <div className="page-content header-clear cadastro">
                <div className="space-26"></div>
                <img src={require('../../../resources/imgs/svg/aprovado-icon.svg')} className="center" style={{ width: '156px' }} alt="" />

                <div className="space-34"></div>
                <div className="title title-32 text-center text-destaque">Mensagem enviada com sucesso!</div>
                <div className="space-26"></div>
                <div className="cf-texto div-body margin-30 text-center">Em breve entraremos em contato.</div>

                <div style={ this.state.gerarAgendamento === 1 ? {} : { display: 'none' } }>
                    <div className="cf-texto div-body margin-30 text-center">Clique no botão abaixo para agendar a execução do serviço.</div>
                    <div className="space-34"></div>
                    <div className="div-center">
                        <Link to={'/agendamento'}>
                            <button className="bt-login margin-30">AGENDAR</button>
                        </Link>
                    </div>
                </div>
                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Sucesso);
